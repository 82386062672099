<div class="tree-header-actions">
    <button mat-icon-button [matMenuTriggerFor]="treeActionMenu">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #treeActionMenu="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="treeAddMenu">
            <mat-icon>add</mat-icon>
            {{13 | langTag}}
        </button>
        <button mat-menu-item (click)="onEditClick()">
            <mat-icon>edit</mat-icon>
            {{297 | langTag}}
        </button>
        <button mat-menu-item (click)="onRemoveClick()">
            <mat-icon>remove</mat-icon>
            {{277 | langTag}}
        </button>
    </mat-menu>

    <mat-menu #treeAddMenu="matMenu">
        <button mat-menu-item (click)="onAddClick('organisation')" class="small-menu-button">{{165 | langTag}}</button>
        <button mat-menu-item (click)="onAddClick('asset')" class="small-menu-button">{{66 | langTag}}</button>
        <button mat-menu-item (click)="onAddClick('user')" class="small-menu-button">{{399 | langTag}}</button>
    </mat-menu>
</div>