import { Component, Inject, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList, MatListOption } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { UserService, JobService, TeamService } from '@fliq/service-library';

@Component({
    selector: 'staff-allocation-dialog',
    templateUrl: './staff-allocation-dialog-component.html',
    styleUrls: ['./staff-allocation-dialog-component.scss']
})
export class StaffAllocationDialogComponent implements OnInit, OnDestroy {

    availableUsers: any[] = [];
    filteredAvailalbeUsers: any[] = [];
    selectedUsers: any[] = [];
    domainId: number;
    type: string;
    jobId: number;
    supplierId: number;
    teamId: number;
    caller: string;
    filterTerms: Subject<string> = new Subject<string>();
    filterChangeSubscription: Subscription;

    @ViewChild('availableUserList', { static: true }) availableUserList: MatSelectionList;
    @ViewChild('selectedUserList', { static: true }) selectedUserList: MatSelectionList;
    @ViewChild('userSearch', { static: true }) userSearch: ElementRef;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<StaffAllocationDialogComponent>,
        private snackBar: MatSnackBar,
        private userService: UserService,
        private jobService: JobService,
        private teamService: TeamService
    ) {
        this.domainId = data.domainId;
        this.type = data.type;
        this.jobId = data.jobId;
        this.supplierId = data.supplierId;
        this.selectedUsers = data.selectedStaff || [];
        this.caller = data.caller;
        this.teamId = data.teamId;
        if (!data.type || (data.type !== 'new' && data.type !== 'edit')) {
            throw new Error(`StaffAllocationDialogComponents needs type parameter(as string value of "new" or "edit").
                When opening dialog pass type to dialog inside data object.`);
        }
    }

    ngOnInit(): void {
        if (this.caller === 'overtime-dialog') {
            this.getUsers();
        } else {
            if (this.type === 'edit') {
                this.getStaffAllocation();
            } else {
                this.getUsers();
            }
        }
        this.filterChangeSubscription = this.filterTerms.pipe(
            debounceTime(200)
        ).subscribe(
            res => {
                this.filteredAvailalbeUsers = this.availableUsers.filter(user => user.name.toLowerCase().includes(res.toLowerCase()));
            }
        );
    }

    getUsers(): void {
        let obs: Observable<any>;
        let userIdKey: string;

        if (this.teamId) {
            obs = this.teamService.getTeamMembers(this.teamId);
            userIdKey = 'users_id';
        } else {
            obs = this.userService.getUsersByOrgDeep(this.supplierId);
            userIdKey = 'id';
        }

        obs.subscribe(
            res => {
                this.availableUsers = [];
                if (Array.isArray(res) && res.length > 0) {
                    res.forEach(user => {
                        if (!this.isAllocated(user[userIdKey])) {
                            this.availableUsers.push({ id: user[userIdKey], name: `${user.first_name} ${user.last_name}` });
                        }
                    });
                } else {
                    this.availableUsers.push({ name: `no users exist.` });
                }
                this.filteredAvailalbeUsers = this.availableUsers.slice();
            },
            () => this.snackBar.open('Failed to load users', 'Ok', { duration: 5000 })
        );
    }

    isAllocated(id: number): boolean {
        for (const selectedUser of this.selectedUsers) {
            if (selectedUser.id == id) {
                return true;
            }
        }
        return false;
    }

    getStaffAllocation(): void {
        this.jobService.getStaffAllocation(this.jobId, 'so').subscribe(
            res => {
                this.selectedUsers = [];
                if (res) {
                    res.forEach(user => {
                        this.selectedUsers.push({ id: user.id, name: `${user.first_name} ${user.last_name}` });
                    });
                }
                this.getUsers();
            }
        );
    }

    selectUser(user: any): void {
        const index: number = this.availableUsers.findIndex(availableUser => user.id == availableUser.id);
        this.availableUsers.splice(index, 1);
        this.selectedUsers.push(user);
        this.triggerUserSearch();
    }

    selectUsers(): void {
        const optionsToSelect: MatListOption[] = this.availableUserList.selectedOptions.selected;
        optionsToSelect.forEach(option => this.selectUser(option.value));
        this.availableUserList.deselectAll();
    }

    deDelectUser(user: any): void {
        const index: number = this.selectedUsers.findIndex(availableUser => user.id == availableUser.id);
        this.selectedUsers.splice(index, 1);
        this.availableUsers.push(user);
        this.triggerUserSearch();
    }

    deSelectUsers(): void {
        const optionsToDeSelect: MatListOption[] = this.selectedUserList.selectedOptions.selected;
        optionsToDeSelect.forEach(option => this.deDelectUser(option.value));
        this.selectedUserList.deselectAll();
    }

    searchAvailableUser(text: string): void {
        text = text.trim();
        this.filterTerms.next(text);
    }

    triggerUserSearch(): void {
        const event = new KeyboardEvent('keyup', { bubbles: true });
        this.userSearch.nativeElement.dispatchEvent(event);
    }

    save(): void {
        let userIds: number[] = this.selectedUsers.map(user => user.id);
        // remove undefined values from array (no users exist option)
        userIds = userIds.filter((userId) => userId !== undefined);
        if (!userIds || !userIds.length) {
            this.dialogRef.close([]);
        } else {
            this.userService.getUserInitials(userIds).subscribe(
                res => {
                    this.dialogRef.close(res);
                },
                () => alert('Failed to get staff initials')
            );
        }
    }

    cancel(): void {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.filterChangeSubscription.unsubscribe();
    }

}
