import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { TreeModule } from 'angular-tree-component';

import { SharedModule } from '../../modules/shared-module';
import { BaseComponent } from './base-component';
import { NavigationComponent } from '../navigation/navigation-component';
import { TreeviewComponent } from '../treeview/treeview-component';
import { TreeviewNodeComponent } from '../treeview/treeview-node-icon/treeview-node-icon-component';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { MessagesPanelComponent } from '../messages-panel/messages-panel-component';
import { NoDataFoundComponentModule } from 'src/app/shared/no-data-found/no-data-found-component.module';
import { TreenodeMenuComponent } from '../treenode-menu/treenode-menu-component';
import { ModulesGuard } from 'src/app/guards/modules.guard';
import { UpdateAssetDialogComponentModule } from '../admin/admin-assets/update-asset-dialog/update-asset-dialog-component.module';
import { UpdateOrganisationDialogComponentModule } from '../admin/admin-organisation/update-organisation-dialog/update-organisation-dialog-component.module';
import { UpdateUserDialogComponentModule } from '../admin/admin-users/update-user-dialog/update-user-dialog-component.module';
import { TreeviewActionsComponent } from '../treeview/treeview-actions/treeview-actions.component';
import { AccessRequestDialogComponent } from '../access-request-dialog/access-request-dialog-component';
import { UserAccessComponentModule } from '../user-settings/user-access/user-access-component.module';

const appRoutes: Routes = [
    {
        path: 'main',
        component: BaseComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'asset-portal',
                pathMatch: 'full',
                loadChildren: () => import('../asset-portal/asset-portal-component.module').then(m => m.AssetPortalComponentModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'voyages',
                pathMatch: 'full',
                loadChildren: () => import('../voyages/voyage-component.module').then(m => m.VoyageComponentModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'sent-messages',
                pathMatch: 'full',
                loadChildren: () => import('../sent-messages/sent-messages.module').then(m => m.SentMessagesComponentModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'tasks',
                pathMatch: 'full',
                loadChildren: () => import('../task-queue/task-queue-component.module').then(m => m.TaskQueueComponentModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'apps',
                loadChildren: () => import('../apps/apps-component.module').then(m => m.AppsComponentModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'job-queue',
                loadChildren: () => import('../job-queue/job-queue-component.module').then(m => m.JobQueueComponentModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'calendar',
                pathMatch: 'full',
                loadChildren: () => import('../calendar/calendar-component.module').then(m => m.CalendarComponentModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'reports',
                loadChildren: () => import('../reports/reports-component.module').then(m => m.ReportsComponentModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'kaizen',
                pathMatch: 'full',
                loadChildren: () => import('../kaizen/kaizen-component.module').then(m => m.KaizenComponentModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'admin',
                loadChildren: () => import('../admin/admin-component.module').then(m => m.AdminComponentModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'weekly-planning',
                pathMatch: 'full',
                loadChildren: () => import('../weekly-planning/weekly-planning-component.module').then(m => m.WeeklyPlanningComponentModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'shift-planning',
                pathMatch: 'full',
                loadChildren: () => import('../shift-planning/shift-planning-component.module').then(m => m.ShiftPlanningComponentModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'preferences',
                loadChildren: () => import('../user-settings/user-settings-component.module').then(m => m.UserSettingComponentModule)
            },
            {
                path: 'kaizen-card',
                pathMatch: 'full',
                loadChildren: () => import('../kaizen/kaizen-card/kaizen-card-component.module').then(m => m.KaizenCardComponentModule)
            },
            {
                path: 'kaizen-form',
                pathMatch: 'full',
                loadChildren: () => import('../kaizen/kaizen-form/kaizen-form-component.module').then(m => m.KaizenFormComponentModule)
            },
            {
                path: 'task-form',
                pathMatch: 'full',
                loadChildren: () => import('../task-queue/task-form/task-form-component.module').then(m => m.TaskFormComponentModule)
            },
            {
                path: 'voyage-form',
                pathMatch: 'full',
                loadChildren: () => import('../voyages/voyage-form/voyage-form-component.module').then(m => m.VoyageFormComponentModule)
            },
            {
                path: 'job-form',
                pathMatch: 'full',
                loadChildren: () => import('../job-form/job-form-component.module').then(m => m.JobFormComponentModule)
            },
            {
                path: 'train-form',
                pathMatch: 'full',
                loadChildren: () => import('../trains/train-form/train-form-component.module').then(m => m.TrainFormComponentModule)
            },
            {
                path: 'main-menu',
                pathMatch: 'full',
                loadChildren: () => import('../menu/menu-component.module').then(m => m.MenuComponentModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'maintenance-plan',
                loadChildren: () => import('../maintenance-plan/maintenance-plan.module').then(m => m.MaintenancePlanModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'fault-notification',
                loadChildren: () => import('../fault-notification/fault-notification-component.module').then(m => m.FaultNotificationComponentModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'audits',
                pathMatch: 'full',
                loadChildren: () => import('../audit/audit-component.module').then(m => m.AuditComponentModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'audit-form',
                pathMatch: 'full',
                loadChildren: () => import('../audit/audit-form/audit-form-component.module').then(m => m.AuditFormComponentModule)
            },
            {
                path: 'trailer-park',
                pathMatch: 'full',
                loadChildren: () => import('../trailer-park/trailer-park-component.module').then(m => m.TrailerParkComponentModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'workflow',
                loadChildren: () => import('../workflow/workflow.module').then(m => m.WorkflowModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'digital-lean',
                loadChildren: () => import('../digital-lean/digital-lean.module').then(m => m.DigitalLeanModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'asset-registry',
                loadChildren: () => import('../asset-registry/asset-registry.module').then(m => m.AssetRegistryModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'buffer-board',
                loadChildren: () => import('../buffer-board/buffer-board-component.module').then(m => m.BufferBoardComponentModule),
                canLoad: [ModulesGuard],
                canActivate: [ModulesGuard]
            },
            {
                path: 'dashboard',
                loadChildren: () => import('../dashboard/dashboard-container/dashboard-container.module').then(m => m.DashboardContainerModule)
            }
        ]
    }
];

@NgModule({
    declarations: [
        BaseComponent,
        NavigationComponent,
        TreeviewComponent,
        TreeviewNodeComponent,
        TreeviewActionsComponent,
        MessagesPanelComponent,
        TreenodeMenuComponent,
        AccessRequestDialogComponent
    ],
    imports: [
        IonicModule,
        RouterModule.forChild(appRoutes),
        TreeModule.forRoot(),
        FormsModule,
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatInputModule,
        MatSidenavModule,
        MatTooltipModule,
        MatIconModule,
        MatBadgeModule,
        MatCheckboxModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatButtonToggleModule,
        SharedModule,
        NoDataFoundComponentModule,
        UpdateAssetDialogComponentModule,
        UpdateOrganisationDialogComponentModule,
        UpdateUserDialogComponentModule,
        UserAccessComponentModule
    ],
    exports: [
        BaseComponent
    ]
})
export class BaseComponentModule { }
