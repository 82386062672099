import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IonicModule } from '@ionic/angular';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { SharedModule } from '../../../modules/shared-module';
import { UserAccessComponent } from './user-access-component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        UserAccessComponent
    ],
    imports: [
        IonicModule,
        RouterModule.forChild([{ path: '', component: UserAccessComponent }]),
        SharedModule,
        MatListModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatIconModule,
        NgxMatSelectSearchModule
    ],
    exports: [
        UserAccessComponent
    ]
})
export class UserAccessComponentModule { }
