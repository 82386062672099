import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable, ReplaySubject, Subscription, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
    SessionModel,
    TypeService,
    FilterService,
    UserService,
    OrganisationService
} from '@fliq/service-library';

@Component({
    selector: 'user-access',
    templateUrl: './user-access-component.html',
    styleUrls: ['./user-access-component.scss']
})
export class UserAccessComponent implements OnInit {

    detailsForm: FormGroup;
    user: any;
    loading = false;
    organisations: any[] = [];
    filteredOrgs: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    roles: any[] = [];
    filteredRoles: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    subscriptions: Subscription[] = [];

    constructor(
        private fb: FormBuilder,
        private snackBar: MatSnackBar,
        private typeService: TypeService,
        private orgService: OrganisationService,
        private userService: UserService,
        private session: SessionModel,
        private filterService: FilterService
    ) { }

    ngOnInit(): void {
        this.loading = true;
        this.user = this.session.user;
        forkJoin([this.getOrganisations(), this.getRoles()]).subscribe(
            () => {
                this.initForm(this.user);
                this.loading = false;
            },
            () => {
                this.loading = false;
                this.snackBar.open('Failed to get data from server, please retry.', 'Ok', { duration: 5000 });
            }
        );
    }

    getRoles(): Observable<any> {
        return this.typeService.getEnums('role')
            .pipe(tap(data => {
                this.roles = data;
                if (this.roles) {
                    this.filteredRoles.next(this.roles.slice());
                } else {
                    this.filteredRoles.next([]);
                }
            }));
    }

    getOrganisations(): Observable<any> {
        return this.orgService.getEnums('usr_orgtype_names')
            .pipe(tap(data => {
                this.organisations = data;
                if (this.organisations) {
                    this.filteredOrgs.next(this.organisations.slice());
                } else {
                    this.filteredOrgs.next([]);
                }
            }));
    }

    initForm(data: any): void {
        this.detailsForm = this.fb.group({
            organisation: Number(data.organisationId),
            orgSearch: '',
            role: data.role,
            roleSearch: '',
            message: ''
        });
        this.subscriptions.push(this.detailsForm.controls.orgSearch.valueChanges.subscribe((value) => {
            this.filteredOrgs.next(this.filterService.filterArray(this.organisations, value, 'name'));
        }));
        this.subscriptions.push(this.detailsForm.controls.roleSearch.valueChanges.subscribe((value) => {
            this.filteredRoles.next(this.filterService.filterArray(this.roles, value, 'name'));
        }));
    }

    saveForm(value: any): void {
        if (this.detailsForm.invalid) { return; }
        this.userService.requestAccess(value.organisation as number, value.role, value.message).subscribe(
            () => this.snackBar.open('Request sent.', 'Ok', { duration: 5000 }),
            () => this.snackBar.open('Failed to send request.', 'Ok', { duration: 5000 })
        );
    }

}
