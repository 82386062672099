import { Directive, ElementRef, HostListener, HostBinding, EventEmitter, Output, Renderer2 } from '@angular/core';

/* tslint:disable:directive-selector */
@Directive({
  selector: '[ibFileDnd]'
})
export class FileDragAndDropDirective {

  @Output() private filesChangeEmitter: EventEmitter<FileList> = new EventEmitter();

  @HostBinding('style.position') position: any = 'absolute';
  @HostBinding('style.width') width: any = '100%';
  @HostBinding('style.height') height: any = '100%';

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.renderer.setStyle(this.el.nativeElement, 'background', 'rgba(0, 0, 0, 0.2)');
    this.renderer.setStyle(this.el.nativeElement, 'z-index', '100');
    this.renderer.setStyle(this.el.nativeElement, 'border', '3px dashed black');
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.removeDragOverStyles();
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    const files: FileList = evt.dataTransfer.files;
    if (files.length > 0) {
      this.removeDragOverStyles();
      this.filesChangeEmitter.emit(files);
    }
  }

  removeDragOverStyles(): void {
    this.renderer.setStyle(this.el.nativeElement, 'background', 'initial');
    this.renderer.setStyle(this.el.nativeElement, 'z-index', 0);
    this.renderer.setStyle(this.el.nativeElement, 'border', 'none');
  }

}
