<div class="table-toolbar" fxLayout="column">
    <div fxLayout="row wrap" fxLayoutAlign="start center" style="width: 100%;">
        <h3 class="table-title" *ngIf="title">{{title}}</h3>
        <div class="table-actions">
            <button *ngIf="update" mat-button (click)="onUpdateBtnClicked()">Update</button>
            <button *ngIf="switchView" mat-icon-button (click)="onViewChanged()">
                <mat-icon *ngIf="isTableView" matTooltip="{{932 | langTag}}">view_module</mat-icon>
                <mat-icon *ngIf="!isTableView" matTooltip="{{326 | langTag}}">view_list</mat-icon>
            </button>
            <button *ngIf="openDateRangeMenu" mat-icon-button matTooltip="{{2106 | langTag}}" [matMenuTriggerFor]="dateRangeMenu"
                (menuOpened)="onDateRangeMenuOpened()">
                <mat-icon>date_range</mat-icon>
            </button>
            <button *ngIf="add" mat-icon-button matTooltip="{{397 | langTag}}"
                (click)="addRow($event); $event.preventDefault();" color="primary" e2e-id="table_btn_add">
                <mat-icon>add</mat-icon>
            </button>
            <button *ngIf="delete" mat-icon-button matTooltip="{{277 | langTag}}"
                (click)="deleteRow(); $event.preventDefault();" color="warn" [disabled]="disableChange"
                e2e-id="table_btn_del">
                <mat-icon>delete</mat-icon>
            </button>
            <button *ngIf="refresh" mat-icon-button matTooltip="{{881 | langTag}}"
                (click)="refreshTable(); $event.preventDefault();" e2e-id="table_btn_refresh">
                <mat-icon>refresh</mat-icon>
            </button>
            <button *ngIf="edit" mat-icon-button matTooltip="{{297 | langTag}}" (click)="editRow()"
                [disabled]="disableChange" e2e-id="table_btn_edit">
                <mat-icon>edit</mat-icon>
            </button>
            <button *ngIf="exportExcel" mat-icon-button matTooltip="{{882 | langTag}}" (click)="exportTableExcel()"
                e2e-id="table_btn_excel">
                <mat-icon>arrow_downward</mat-icon>
            </button>
            <button *ngIf="exportPDF" mat-icon-button matTooltip="{{883 | langTag}}" (click)="exportTablePDF()"
                e2e-id="table_btn_pdf">
                <mat-icon>picture_as_pdf</mat-icon>
            </button>
            <button *ngIf="openPdfMenu" mat-icon-button matTooltip="{{883 | langTag}}" [matMenuTriggerFor]="pdfMenu"
                (menuOpened)="onMenuOpened()">
                <mat-icon>picture_as_pdf</mat-icon>
            </button>
            <button *ngIf="copy" mat-icon-button matTooltip="{{2057 | langTag}}" (click)="copyData()"
                e2e-id="table_btn_copy">
                <mat-icon>content_copy</mat-icon>
            </button>
            <button *ngIf="csvExportAsPDF" mat-icon-button matTooltip="{{898 | langTag}}"
                (click)="csvExportTableAsPDF()" e2e-id="table_btn_csvpdf">
                <mat-icon>print</mat-icon>
            </button>
            <button *ngIf="move" mat-icon-button matTooltip="{{390 | langTag}}" (click)="moveRow()"
                [disabled]="disableChange">
                <mat-icon>call_split</mat-icon>
            </button>
            <button *ngIf="columnOptions" mat-icon-button  matTooltip="{{2255 | langTag}}" (click)="onColOptionsClicked()">
                <mat-icon>build</mat-icon>
            </button>
            <table-filter #tableFilter *ngIf="filter" [filterableCols]="filterFields"
                (filtersChanged)="onFiltersChanged($event)" e2e-id="table_btn_filter">
            </table-filter>
        </div>
    </div>
    <div *ngIf="tableFilter" class="filter-list">
        <mat-chip-list #chipList>
            <mat-chip *ngFor="let filter of tableFilter.filterList"
                matTooltip="{{filter.colName + ' ' + filter.op + ' ' + (filter.text ? filter.text : filter.value)}}"
                (removed)="tableFilter.removeFilterListValue(filter)">
                {{filter.text ? filter.text : filter.value}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </div>
</div>