import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ReplaySubject, Subscription } from 'rxjs';

import {
    ModuleParamsModel,
    FilterService,
    SessionModel,
    LocationService,
    CostcentreService,
    AssetService,
    TypeService,
    OrganisationService,
    HeatmapService,
    TeamService
} from '@fliq/service-library';
import { LanguagePipe } from 'src/app/pipes/language-pipe';

@Component({
    templateUrl: './update-asset-dialog-component.html'
})
export class UpdateAssetDialogComponent implements OnInit {

    asset: any;
    type = '';
    assetForm: FormGroup;

    geofences: any[] = [];
    filteredGeos: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    organisations: any[] = [];
    filteredOrgs: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    filteredInvOrgs: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    costcentres: any[] = [];
    filteredCosts: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    eqtypes: any[] = [];
    filteredTypes: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    shapes: any[] = [];
    filteredShapes: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    locations: any[] = [];
    filteredLocations: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    teams: any[] = [];
    filteredTeams: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    filteredOwnerOrgs: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    engineTypes: any[] = [];
    filteredEngineTypes: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    tireSizes: any[] = [];
    filteredTireSizes: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    parentAssets: any[] = [];
    filteredParentAssets: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    filteredServiceOrgs: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    insuranceTypes: any[] = [];
    fuelTypes: any[] = [];

    loading: boolean;
    subscriptions: Subscription[] = [];

    adminModuleId = 21;
    showOtherFields = false;
    domainId: number;
    characteristicsInvalid = false;
    userRole: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<UpdateAssetDialogComponent>,
        private fb: FormBuilder,
        private snackBar: MatSnackBar,
        private session: SessionModel,
        private moduleParams: ModuleParamsModel,
        private locationService: LocationService,
        private costcentreService: CostcentreService,
        private assetService: AssetService,
        private typeService: TypeService,
        private orgService: OrganisationService,
        private heatmapService: HeatmapService,
        private teamService: TeamService,
        private filterService: FilterService,
        private langPipe: LanguagePipe
    ) {
        this.asset = data.asset;
        this.type = data.type === 'add' ? langPipe.transform(13) : langPipe.transform(297);
        this.domainId = data.domainId ? data.domainId : this.session.user.domainId;
    }

    ngOnInit(): void {
        this.dialogRef.updateSize('60%', '75vh');
        this.moduleParams.getModuleParams(this.adminModuleId)
            .subscribe(
                () => {
                    const modArr: any[] = this.moduleParams.getActiveModuleParamsByType(this.adminModuleId, 'admin_eq');
                    if (modArr.length > 0) { this.showOtherFields = true; }

                    this.getGeofences();
                    this.getOrganisations();
                    this.getCostcentres();
                    this.getTypes();
                    this.getInsuranceTypes();
                    this.getShapes();
                    this.getLocations();
                    this.getTeams();
                    this.getEngineTypes();
                    this.getTireSizes();
                    this.getParentAssets();
                    this.getFuelTypes();
                    this.initForm();
                },
                () => {
                    this.snackBar.open(`${this.langPipe.transform(570)}`, 'Ok', { duration: 3000 });
                    this.dialogRef.close();
                }
            );
    }

    initForm(): void {
        this.userRole = this.session.user.role;
        this.assetForm = this.fb.group({
            name: [this.asset ? this.asset.name : '', Validators.required],
            geofence: this.asset ? +this.asset.geo_fence_id : '',
            geoSearch: '',
            org: [this.asset ? +this.asset.organisation_id : '', Validators.required],
            orgSearch: '',
            invOrg: this.asset ? +this.asset.inv_organisation_id : '',
            invOrgSearch: '',
            costcentre: this.asset ? +this.asset.costcentre_id : '',
            costSearch: '',
            eqtype: [this.asset ? +this.asset.eq_type : '', Validators.required],
            typeSearch: '',
            date: this.asset && this.asset.install_date ? new Date(this.asset.install_date) : '',
            deleted: this.asset && this.asset.deleted !== '0' ? this.asset.deleted : '',
            showOnMap: this.asset && this.asset.show_on_map !== '0' ? this.asset.show_on_map : '',
            tag: this.asset ? this.asset.asset_tag : '',
            shape: this.asset ? +this.asset.shape_id : 264,    // 264=default shape
            shapeSearch: '',
            title: this.asset && this.asset.show_title !== '0' ? this.asset.show_title : '',
            faIcon: this.asset ? this.asset.fa_icon : '',
            brand: this.asset ? this.asset.brand : '',
            model: this.asset ? this.asset.model : '',
            year: this.asset ? this.asset.year : '',
            reg: this.asset ? this.asset.registration_number : '',
            manufacture: this.asset ? this.asset.manufacture_number : '',
            weight: [this.asset ? this.asset.weight : '', Validators.min(0)],
            height: [this.asset ? this.asset.height : '', Validators.min(0)],
            width: [this.asset ? this.asset.width : '', Validators.min(0)],
            location: this.asset ? +this.asset.location : '',
            locationSearch: '',
            radiophone: this.asset && this.asset.radiophone !== '0' ? this.asset.radiophone : '',
            valid: this.asset && this.asset.inspection_until ? new Date(this.asset.inspection_until) : '',
            team: this.asset ? +this.asset.resp_team_id : '',
            teamSearch: '',
            ownerOrg: this.asset ? +this.asset.owner_org_id : '',
            ownerOrgSearch: '',
            engineNum: this.asset ? this.asset.engine_number : '',
            engineType: this.asset ? +this.asset.engine_type : '',
            engineTypeSearch: '',
            gear: this.asset ? this.asset.gear_number : '',
            shaft: this.asset ? this.asset.shaft_number : '',
            beam: this.asset ? this.asset.beam_number : '',
            warranty: this.asset && this.asset.warranty_until ? new Date(this.asset.warranty_until) : '',
            tireSize: this.asset ? +this.asset.tire_size : '',
            tireSizeSearch: '',
            tireBrand: this.asset ? this.asset.tire_brand : '',
            tireInstallDate: this.asset && this.asset.tire_install_date ? new Date(this.asset.tire_install_date) : '',
            capacity: this.asset ? this.asset.capacity : '',
            parentEq: this.asset ? +this.asset.parent_eq_id : '',
            parentEqSearch: '',
            order: this.asset ? this.asset.sort_order : '',
            serviceOrg: this.asset ? +this.asset.service_organisation_id : '',
            serviceOrgSearch: '',
            fuelType: this.asset ? +this.asset.fuel_type_id : ''
        });

        if (this.userRole === 'admin' || this.userRole === 'supervisor') {
            this.assetForm.addControl('price', new FormControl(this.asset ? this.asset.purchase_price : ''));
            this.assetForm.addControl('depreciationTime', new FormControl(this.asset ? this.asset.depreciation_time : ''));
            this.assetForm.addControl('seller', new FormControl(this.asset ? this.asset.seller : ''));
            this.assetForm.addControl('insuranceType', new FormControl(this.asset ? +this.asset.insurance_type_id : ''));
        }
        this.subscriptions.push(this.assetForm.controls.geoSearch.valueChanges.subscribe((value) => {
            this.filteredGeos.next(this.filterService.filterArray(this.geofences, value, 'name'));
        }));
        this.subscriptions.push(this.assetForm.controls.typeSearch.valueChanges.subscribe((value) => {
            this.filteredTypes.next(this.filterService.filterArray(this.eqtypes, value, 'name'));
        }));
        this.subscriptions.push(this.assetForm.controls.orgSearch.valueChanges.subscribe((value) => {
            this.filteredOrgs.next(this.filterService.filterArray(this.organisations, value, 'name'));
        }));
        this.subscriptions.push(this.assetForm.controls.invOrgSearch.valueChanges.subscribe((value) => {
            this.filteredInvOrgs.next(this.filterService.filterArray(this.organisations, value, 'name'));
        }));
        this.subscriptions.push(this.assetForm.controls.costSearch.valueChanges.subscribe((value) => {
            this.filteredCosts.next(this.filterService.filterArray(this.costcentres, value, 'name'));
        }));
        this.subscriptions.push(this.assetForm.controls.shapeSearch.valueChanges.subscribe((value) => {
            this.filteredShapes.next(this.filterService.filterArray(this.shapes, value, 'name'));
        }));
        this.subscriptions.push(this.assetForm.controls.locationSearch.valueChanges.subscribe((value) => {
            this.filteredLocations.next(this.filterService.filterArray(this.locations, value, 'name'));
        }));
        this.subscriptions.push(this.assetForm.controls.teamSearch.valueChanges.subscribe((value) => {
            this.filteredTeams.next(this.filterService.filterArray(this.teams, value, 'name'));
        }));
        this.subscriptions.push(this.assetForm.controls.ownerOrgSearch.valueChanges.subscribe((value) => {
            this.filteredOwnerOrgs.next(this.filterService.filterArray(this.organisations, value, 'name'));
        }));
        this.subscriptions.push(this.assetForm.controls.engineTypeSearch.valueChanges.subscribe((value) => {
            this.filteredEngineTypes.next(this.filterService.filterArray(this.engineTypes, value, 'name'));
        }));
        this.subscriptions.push(this.assetForm.controls.tireSizeSearch.valueChanges.subscribe((value) => {
            this.filteredTireSizes.next(this.filterService.filterArray(this.tireSizes, value, 'name'));
        }));
        this.subscriptions.push(this.assetForm.controls.parentEqSearch.valueChanges.subscribe((value) => {
            this.filteredParentAssets.next(this.filterService.filterArray(this.parentAssets, value, 'name'));
        }));
        this.subscriptions.push(this.assetForm.controls.serviceOrgSearch.valueChanges.subscribe((value) => {
            this.filteredServiceOrgs.next(this.filterService.filterArray(this.organisations, value, 'name'));
        }));

        this.subscriptions.push(this.assetForm.valueChanges.subscribe(() => {
            this.checkCharacteristicsValidity();
        }));
    }

    getGeofences(): void {
        this.heatmapService.getEnums(this.domainId)
            .subscribe(data => {
                this.geofences = data;
                if (this.geofences) {
                    this.filteredGeos.next(this.geofences.slice());
                } else {
                    this.filteredGeos.next([]);
                }
            });
    }

    getOrganisations(): void {
        this.orgService.getEnums('eq_orgtype_names', this.domainId)
            .subscribe(data => {
                this.organisations = data;
                if (this.organisations) {
                    this.filteredOrgs.next(this.organisations.slice());
                    this.filteredInvOrgs.next(this.organisations.slice());
                    this.filteredOwnerOrgs.next(this.organisations.slice());
                    this.filteredServiceOrgs.next(this.organisations.slice());
                } else {
                    this.filteredOrgs.next([]);
                    this.filteredInvOrgs.next([]);
                    this.filteredOwnerOrgs.next([]);
                    this.filteredServiceOrgs.next([]);
                }
            });
    }

    getCostcentres(): void {
        this.costcentreService.getEnums(this.domainId)
            .subscribe(data => {
                this.costcentres = data;
                if (this.costcentres) {
                    this.filteredCosts.next(this.costcentres.slice());
                } else {
                    this.filteredCosts.next([]);
                }
            });
    }

    getTypes(): void {
        this.typeService.getEnums('eqtypes', this.domainId)
            .subscribe(data => {
                this.eqtypes = data;
                if (this.eqtypes) {
                    this.filteredTypes.next(this.eqtypes.slice());
                } else {
                    this.filteredTypes.next([]);
                }
            });
    }

    getShapes(): void {
        this.typeService.getEnums('asset_shapes', this.domainId)
            .subscribe(data => {
                this.shapes = data;
                if (this.shapes) {
                    this.filteredShapes.next(this.shapes.slice());
                } else {
                    this.filteredShapes.next([]);
                }
            });
    }

    getLocations(): void {
        this.locationService.getEnums(this.domainId)
            .subscribe(data => {
                this.locations = data;
                if (this.locations) {
                    this.filteredLocations.next(this.locations.slice());
                } else {
                    this.filteredLocations.next([]);
                }
            });
    }

    getTeams(): void {
        this.teamService.getEnums(this.domainId)
            .subscribe(data => {
                this.teams = data;
                if (this.teams) {
                    this.filteredTeams.next(this.teams.slice());
                } else {
                    this.filteredTeams.next([]);
                }
            });
    }

    getEngineTypes(): void {
        this.typeService.getEnums('engine_type', this.domainId)
            .subscribe(data => {
                this.engineTypes = data;
                if (this.engineTypes) {
                    this.filteredEngineTypes.next(this.engineTypes.slice());
                } else {
                    this.filteredEngineTypes.next([]);
                }
            });
    }

    getTireSizes(): void {
        this.typeService.getEnums('tire_size', this.domainId)
            .subscribe(data => {
                this.tireSizes = data;
                if (this.tireSizes) {
                    this.filteredTireSizes.next(this.tireSizes.slice());
                } else {
                    this.filteredTireSizes.next([]);
                }
            });
    }

    getParentAssets(): void {
        this.assetService.getEnums('eq_name', this.domainId)
            .subscribe(data => {
                this.parentAssets = data;
                if (this.parentAssets) {
                    this.filteredParentAssets.next(this.parentAssets.slice());
                } else {
                    this.filteredParentAssets.next([]);
                }
            });
    }

    getInsuranceTypes(): void {
        this.typeService.getEnums('insurance_type', this.domainId)
        .subscribe(data => {
            this.insuranceTypes = data;
        });
    }

    getFuelTypes(): void {
        this.typeService.getEnums('fuel_type', this.domainId)
        .subscribe(data => {
            this.fuelTypes = data;
        });
    }

    private checkCharacteristicsValidity(): void {
        this.characteristicsInvalid = this.assetForm.get('weight').invalid || this.assetForm.get('height').invalid || this.assetForm.get('width').invalid;
    }

    teamNameFromValue(id: any): string {
        for (const team of this.teams) {
            if (team.id == id) {
                return team.name;
            }
        }
    }

    submitForm(data: any): void {
        if (this.assetForm.invalid) { return; }
        this.dialogRef.close(data);
    }

}
