<h1 mat-dialog-title>{{2255 | langTag}}</h1>
<div mat-dialog-content class="column-options-content">
    <form *ngIf="form" [formGroup]="form">
        <div
            formArrayName="columns"
            fxLayout="column"
            cdkDropList
            (cdkDropListDropped)="drop($event)"
            [cdkDropListSortPredicate]="sortPredicate"
        >
            <div
                *ngFor="let group of form.get('columns').controls; let i = index"
                class="column-group-control"
            >
                <div
                    [formGroupName]="i"
                    fxLayout="row"
                    fxLayoutGap="16px"
                    fxLayoutAlign="start center"
                    cdkDrag
                    [cdkDragData]="form.get('columns').controls"
                    [cdkDragDisabled]="form.get('columns').controls[i].get('is_default').value == 1"
                >
                    <mat-icon class="drag-handle">drag_handle</mat-icon>
                    <mat-form-field>
                        <mat-select #columnSelect formControlName="column"
                            [disabled]="form.get('columns').controls[i].get('is_default').value == 1"
                            (selectionChange)="onColumnChange()"
                        >
                            <mat-option>
                                <ngx-mat-select-search formControlName="columnSearch"
                                    placeholderLabel="{{152 | langTag}}" noEntriesFoundLabel="{{578 | langTag}}">
                                </ngx-mat-select-search>
                            </mat-option>
                            <ng-container *ngIf="columnSelect.panelOpen">
                                <mat-option *ngFor="let column of filteredColumns | async" [value]="column.name">
                                    {{column.abbr_tooltip ? (column.abbr_tooltip | langTag) + ' - ' : ''}} {{column.lang_tag_id | langTag}}
                                </mat-option>
                            </ng-container>
                            <ng-container *ngIf="!columnSelect.panelOpen">
                                <mat-option *ngFor="let column of data.columns" [value]="column.name">
                                    {{column.abbr_tooltip ? (column.abbr_tooltip | langTag) + ' - ' : ''}} {{column.lang_tag_id | langTag}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-error *ngIf="form.get('columns').controls[i].get('column').hasError('required')">
                            {{2048 | langTag}}
                        </mat-error>
                    </mat-form-field>
                    <button mat-icon-button type="button" *ngIf="form.get('columns').controls[i].get('is_default').value == 0"
                        matTooltip="{{875 | langTag}}" (click)="onDeleteColumn(i)">
                        <mat-icon color="warn">clear</mat-icon>
                    </button>
                </div>
            </div>
            <mat-error *ngIf="form.get('columns').hasError('required')">
                Vähintään yksi kolumni oltava valittuna
            </mat-error>
            <div>
                <button mat-button type="button" (click)="onAddColumn()">
                    <mat-icon color="primary">add</mat-icon>
                    Add a column
                </button>
            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="accent" (click)="onSave()" [disabled]="form.invalid">Ok</button>
</div>