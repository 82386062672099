import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CdkDrag, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ReplaySubject, Subscription } from 'rxjs';

import { ColumnsService, FilterService } from '@fliq/service-library';
import { TableCellType } from '@fliq/datamodel-library';

interface ColumnOptionsData {
    table: string;
    columns: ColumnOption[];
}

export interface ColumnOption {
    active: '1' | '0';
    align: 'left' | 'center' | 'right';
    col_type: TableCellType;
    lang_tag_id: string;
    lang_tag_text: string;
    name: string;
    name_col: string;
    sort_order: string;
    width: string;
    dd_name: string;
    sortable: '1' | '0';
    editable: '1' | '0';
    field_type: 'input' | 'selectOne' | 'multiSelect' | 'date' | 'datetime';
    inline_edit_field: string;
    footer_cell: string;
    abbr_tooltip: string;
    is_default: '1' | '0';
}

@Component({
    selector: 'app-column-options',
    templateUrl: './column-options.component.html',
    styleUrls: ['./column-options.component.scss']
})
export class ColumnOptionsComponent implements OnInit, OnDestroy {

    form: FormGroup;
    filteredColumns = new ReplaySubject<any[]>(1);
    availableColumns: any[];
    subscriptions: Subscription[] = [];

    constructor(
        public dialogRef: MatDialogRef<ColumnOptionsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ColumnOptionsData,
        private fb: FormBuilder,
        private filterService: FilterService,
        private columnService: ColumnsService
    ) { }

    ngOnInit(): void {
        this.dialogRef.updateSize(null, '95vh');
        this.dialogRef.updatePosition({ right: '24px' });

        this.availableColumns = this.data.columns.filter(c => c.active !== '1');
        this.filteredColumns.next(this.availableColumns);

        this.form = this.fb.group({
            columns: this.fb.array([], Validators.required)
        });

        this.initColums();

    }

    private initColums(): void {
        for (const item of this.data.columns) {
            if (item.active === '1') {
                const columnsFormArray = (this.form.get('columns') as FormArray);

                const group = this.fb.group({
                    column: [item.name, Validators.required],
                    columnSearch: '',
                    sort_order: item.sort_order,
                    is_default: item.is_default
                });

                columnsFormArray.push(group);

                this.subscriptions.push(group.controls.columnSearch.valueChanges.subscribe((value) => {
                    this.filteredColumns.next(this.filterService.filterArray(this.availableColumns, value, 'lang_tag_text'));
                }));
            }
        }
    }

    onDeleteColumn(index: number): void {
        const columns = (this.form.get('columns') as FormArray);

        columns.removeAt(index);

        this.onColumnChange();
    }

    onAddColumn(): void {
        const colGroup = this.form.get('columns') as FormArray;

        const group = this.fb.group({
            column: ['', Validators.required],
            columnSearch: '',
            sort_order: colGroup.controls.length,
            is_default: 0
        });

        colGroup.push(group);

        this.onColumnChange();

        this.subscriptions.push(group.controls.columnSearch.valueChanges.subscribe((value) => {
            this.filteredColumns.next(this.filterService.filterArray(this.availableColumns, value, 'lang_tag_text'));
        }));
    }

    drop(event: CdkDragDrop<string[]>) {
        const columns = (this.form.get('columns') as FormArray).controls;

        moveItemInArray(columns, event.previousIndex, event.currentIndex);

        for (let i = 0; i < columns.length; i++) {
            columns[i].get('sort_order').setValue(i);
        }
    }

    onColumnChange(): void {
        const colGroup = this.form.get('columns') as FormArray;

        this.availableColumns = this.data.columns.filter(c => !colGroup.controls.some(cg => cg.value.column === c.name));
        this.filteredColumns.next(this.availableColumns.slice());
    }

    onSave(): void {
        const colGroup = this.form.get('columns') as FormArray;

        const cols = this.data.columns.map(c => {
            const columnControl = colGroup.controls.find(cg => cg.value.column === c.name);
            const active = columnControl ? '1' : '0';
            const sortOrdedr = columnControl?.value.sort_order ?? c.sort_order;
            return { ...c, active, sort_order: sortOrdedr };
        });

        this.columnService.updateColumns(this.data.table, cols).subscribe(() => {
            this.dialogRef.close(cols);
        });
    }

    // only allow sorting for optional columns
    sortPredicate(index: number, item: CdkDrag<FormArray>): boolean {
        return item.data[index].get('is_default').value == 0;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

}
