import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, AbstractControl } from '@angular/forms';

import { Subscription, Observable, ReplaySubject, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ModuleParam } from '@fliq/datamodel-library';
import { DropDownService } from '@fliq/service-library';

// TODO: add field types as needd (textare, datepicker etc.)
@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form-component.html'
})
export class DynamicFormComponent implements OnInit, OnDestroy {

  @Input() fields: ModuleParam[] = [];
  @Input() data: any;

  dynamicForm: FormGroup;
  subscriptions: Subscription[] = [];
  selectMap: Map<string, any[]> = new Map();
  filteredSelectMap: Map<string, ReplaySubject<any[]>> = new Map();

  @Output() formUpdated = new EventEmitter<FormGroup>();

  constructor(private fb: FormBuilder, private ddService: DropDownService) { }

  ngOnInit(): void {
    this.initForm();
    const getDdObservables = this.initSelects();
    forkJoin(getDdObservables).subscribe();
  }

  initForm(): void {
    this.dynamicForm = this.fb.group({});
    for (const field of this.fields) {
      let value: any = '';
      if (this.data) {
        value = this.data[field.name];
        if (field.fieldType === 'checkbox') {
          value = this.data[field.name] == 1 ? true : false;
        }
      }
      this.dynamicForm.addControl(field.name, new FormControl(value));
      if (field.fieldType === 'select') {
        this.dynamicForm.addControl(`${field.name}Search`, new FormControl(''));
      }
    }

    this.subscriptions.push(this.dynamicForm.valueChanges.subscribe(() => {
      this.formUpdated.emit(this.dynamicForm);
    }));
    this.formUpdated.emit(this.dynamicForm);
  }

  private initSelects(): Observable<any>[] {
    const selects: ModuleParam[] = [];
    const ddValueGetObs: Observable<any>[] = [];
    this.fields.forEach(field => {
      if (field.fieldType === 'select') {
        selects.push(field);
      }
    });
    selects.forEach(select => {
      this.selectMap.set(select.name, []);
      this.filteredSelectMap.set(select.name, new ReplaySubject<any[]>(1));
      const searchControl: AbstractControl = this.dynamicForm.get(`${select.name}Search`);
      this.subscriptions.push(searchControl.valueChanges.subscribe(() => {
        this.filterSelect(select.name, searchControl);
      }));
      ddValueGetObs.push(this.getDdValues(select.name, select.ddName));
    });
    return ddValueGetObs;
  }

  getDdValues(fieldName: string, ddName: string): Observable<any> {
    return this.ddService.getDropDownList(ddName)
      .pipe(tap(res => {
        let value: any = '';
        let selectValues: any[] = this.selectMap.get(fieldName);
        const filteredSelects: ReplaySubject<any[]> = this.filteredSelectMap.get(fieldName);

        if (res) {
          selectValues = res;
          if (this.data) { // edit form value
            value = this.data[fieldName];
          }
        } else {
          selectValues = [];
        }
        filteredSelects.next(selectValues.slice());
        this.selectMap.set(fieldName, selectValues);
        this.filteredSelectMap.set(fieldName, filteredSelects);
        this.dynamicForm.controls[fieldName].setValue(value);
      }));
  }

  filterSelect(selectName: string, searchControl: AbstractControl): void {
    const selectSubject: ReplaySubject<any[]> = this.filteredSelectMap.get(selectName);
    const selectValues: any[] = this.selectMap.get(selectName);
    let search: any = searchControl.value;
    if (!selectValues) {
      return;
    }
    if (!search) {
      selectSubject.next(selectValues.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    selectSubject.next(
      selectValues.filter(value => value.name.toLowerCase().indexOf(search) > -1)
    );
    this.filteredSelectMap.set(selectName, selectSubject);
  }

  submitForm(): void {

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
