import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { DynamicFormComponent } from './dynamic-form-component';
import { SharedModule } from 'src/app/modules/shared-module';

@NgModule({
    declarations: [
        DynamicFormComponent
    ],
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatRadioModule,
        MatButtonModule,
        NgxMatSelectSearchModule,
        SharedModule
    ],
    exports: [
        DynamicFormComponent
    ]
})
export class DynamicFormComponentModule { }
