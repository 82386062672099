import { Component, Input } from '@angular/core';
import { LanguagePipe } from 'src/app/pipes/language-pipe';

@Component({
    selector: 'no-data-found',
    templateUrl: 'no-data-found-component.html',
    styleUrls: ['./no-data-found-component.scss']
})
export class NoDataFoundComponent {
    // TODO: add input setters to check validity of items to prevent errors
    @Input() text = this.langPipe.transform(578);
    @Input() iconName = 'block';
    @Input() iconSize = '62px';

    constructor(private langPipe: LanguagePipe) { }

}
