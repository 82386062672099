<div fxLayout="row" fxLayoutGap="50px" class="module-content">
    <mat-spinner *ngIf="loading"></mat-spinner>
    <form *ngIf="detailsForm" [formGroup]="detailsForm" (ngSubmit)="saveForm(detailsForm.value)" style="width: 100%">
        <div fxLayout="column">
            <div fxLayout="row" fxLayoutGap="10px">
                <mat-form-field class="select">
                    <mat-select placeholder="{{848 | langTag}}" formControlName="role">
                        <mat-option>
                            <ngx-mat-select-search formControlName="roleSearch" placeholderLabel="{{152 | langTag}}"
                                noEntriesFoundLabel="{{578 | langTag}}">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let role of filteredRoles | async; let i=index" [value]="role.type_name">
                            {{role.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="10px">
                <mat-form-field class="select">
                    <mat-select placeholder="{{341 | langTag}}" formControlName="organisation">
                        <mat-option>
                            <ngx-mat-select-search formControlName="orgSearch" placeholderLabel="{{152 | langTag}}"
                                noEntriesFoundLabel="{{578 | langTag}}">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let org of filteredOrgs | async; let i=index" [value]="org.id">
                            {{org.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="10px">
                <mat-form-field class="message">
                    <textarea matInput placeholder="{{345 | langTag}}" formControlName="message" matTextareaAutosize
                        matAutosizeMinRows="5"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayoutGap="10px" class="row">
            <button mat-raised-button color="accent"
                [disabled]="user.role == detailsForm.value.role && user.organisationId == detailsForm.value.organisation">
                {{4162 | langTag}}
            </button>
            <mat-icon matTooltip="{{4168 | langTag}}">info_outline</mat-icon>
        </div>
    </form>
</div>