import { Injectable } from '@angular/core';

import { IMqttMessage, MqttService } from 'ngx-mqtt';

import { BehaviorSubject, Subscription } from 'rxjs';

import { SessionModel } from '@fliq/service-library';
import { FliqTreeNode } from './treeview-model';

@Injectable({
    providedIn: 'root'
})
export class TreeNodeService {

    private subscriptions: Subscription[] = [];

    equipmentNodeColors = {};

    private get useMqtt(): boolean {
        return this.session.domainParamsModel.getDomainParamByParamId(87).value === '1';
    }

    constructor(private mqttService: MqttService, private session: SessionModel) { }

    initAssetSubscriptions(nodes: FliqTreeNode[]) {
        this.teardown();

        if (this.useMqtt) {
            for (const node of nodes) {
                this.setAssetSub(node);
            }
        }
    }

    setAssetSub(node: FliqTreeNode): void {
        if (node.li_attr.type === 'eq') {
            this.equipmentNodeColors[node.li_attr.id] = new BehaviorSubject<string>(this.getColor(node, node.li_attr.status));

            // Status listener
            this.subscriptions.push(
                this.mqttService.observe(`/${node.li_attr.dkey}/${node.li_attr.type}/${node.li_attr.node_id}/state`)
                    .subscribe((message: IMqttMessage) => {
                        const content = JSON.parse(message.payload.toString());

                        node.li_attr.status = content;
                        this.equipmentNodeColors[node.li_attr.id].next(this.getColor(node, content));
                    })
            );
            // Andon listener
            this.subscriptions.push(
                this.mqttService.observe(`/${node.li_attr.dkey}/${node.li_attr.type}/${node.li_attr.node_id}/andon`)
                    .subscribe((message: IMqttMessage) => {
                        const content = JSON.parse(message.payload.toString());

                        this.equipmentNodeColors[node.li_attr.id].next(this.getColor(node, content));
                    })
            );
            // Logistics request listener
            this.subscriptions.push(
                this.mqttService.observe(`/${node.li_attr.dkey}/${node.li_attr.type}/${node.li_attr.node_id}/logreq`)
                    .subscribe((message: IMqttMessage) => {
                        const content = JSON.parse(message.payload.toString());

                        this.equipmentNodeColors[node.li_attr.id].next(this.getColor(node, content.logreq_color_code));
                    })
            );
        }
        if (node.children) {
            node.children.map(el => this.setAssetSub(el));
        }
    }

    getColor(node: FliqTreeNode, statusId: any): string {
        let color = 'rgba(0, 0, 0, 0.54)';
        if (node.li_attr.type === 'eq') {
            switch (parseInt(statusId, 10)) {
                case 0:
                    color = 'rgba(222,73,66,0.9)';
                    break;
                case 1:
                    color = 'rgba(77,167,77,0.9)';
                    break;
                case 2:
                    color = 'rgba(255,224,84,0.9)';
                    break;
                case 3:
                    color = 'rgba(0, 191, 255, 0.8)';
                    break;
                case 4:
                    color = 'rgba(255, 165, 0, 0.8)';
                    break;
                case 5:
                    color = 'rgba(128, 128, 128, 0.8)';
                    break;
                case 6:
                    color = 'rgba(176, 196, 222, 0.8)';
                    break;
                case 7:
                    color = 'rgba(119, 136, 153, 0.8)';
                    break;
                case 8:
                    color = 'rgba(85, 107, 47, 0.8)';
                    break;
            }
        }
        return color;
    }

    teardown(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.subscriptions = [];

        for (const subject in this.equipmentNodeColors) {
            if (this.equipmentNodeColors.hasOwnProperty(subject)) {
                this.equipmentNodeColors[subject].complete();
            }
        }
        this.equipmentNodeColors = {};
    }

}
