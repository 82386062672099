import { NgModule } from '@angular/core';

import { MqttModule, IMqttServiceOptions } from 'ngx-mqtt';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
    connectOnCreate: false
};

@NgModule({
    imports: [
        MqttModule.forRoot(MQTT_SERVICE_OPTIONS)
    ],
    exports: [ MqttModule ]
})
export class AppMqttModule { }
