import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { QualificationService } from '@fliq/service-library';
import { TableComponent } from '../../../../table/table-component';
import { UpdateUserQualificationDialogComponent } from '../update-user-qualification-dialog/update-user-qualification-dialog-component';
import { TableRefreshEvent, TableColumnOptions, TableCellType } from '@fliq/datamodel-library';
import { LanguagePipe } from 'src/app/pipes/language-pipe';

@Component({
    selector: 'qualification-list',
    templateUrl: './qualification-list-component.html'
})
export class QualificationListComponent {

    qualificationDataSource: any[] = [];
    pageSize = 200;
    pageSizeOptions: number[] = [50, 100, 200, 500, 1000];
    records: number;
    columns: TableColumnOptions[] = [
        { def: 'checkbox', name: '', type: TableCellType.CHECKBOX, sortable: true },
        { def: 'name', name: 'Qualification', langTag: 828, sortable: true },
        { def: 'date_attained', name: 'Date attained', langTag: 857, sortable: true },
        { def: 'grade', name: 'Proficiency', langTag: 829, sortable: true },
        { def: 'next_renewal_date', name: 'Next Renewal Date', langTag: 858, sortable: true }
    ];

    @ViewChild(TableComponent, { static: true }) table: TableComponent;
    @Input() userId: number;
    @Output() newQualification: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private qualificationService: QualificationService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private langPipe: LanguagePipe
    ) { }

    refreshTable(evt: TableRefreshEvent): void {
        this.table.loading = true;
        this.qualificationService.getQualificationsSam(this.userId, evt.pageSize, evt.pageIndex, evt.sortCol, evt.sortOrder, evt.filters)
            .subscribe(
                res => {
                    this.table.loading = false;
                    this.qualificationDataSource = res.rows;
                    this.records = res.records;
                },
                () => this.table.loading = false
            );
    }

    editQualification(qualification: any): void {
        const dialogRef: MatDialogRef<UpdateUserQualificationDialogComponent> = this.dialog.open(UpdateUserQualificationDialogComponent, {
            data: {
                qualification,
                type: 'edit'
            }
        });
        dialogRef.afterClosed().subscribe(res => {
            if (!res) { return; }
            if (res.select === null || !res.select) { this.deleteQualification(qualification); }
            else if (qualification.id) { this.updateQualification(res, 'edit', qualification.types_id, qualification.id); }
            else { this.updateQualification(res, 'add', qualification.types_id); }
        });
    }

    updateQualification(data: any, type: string, qualificationId: any, rowId: any = ''): void {
        this.table.loading = true;
        this.qualificationService
            .updateQualification(rowId, this.userId, qualificationId, data.grade, type, data.attainDate, data.renewalDate)
            .subscribe(
                res => {
                    if (this.userId === -1) { this.newQualification.emit({ id: res, data, qualification: qualificationId }); }
                    this.table.refreshTable();
                },
                () => {
                    this.table.loading = false;
                    this.snackBar.open(`${this.langPipe.transform(2096)}`, 'Ok', { duration: 5000 });
                }
            );
    }

    deleteQualification(qualification: any): void {
        this.qualificationService.deleteQualification(qualification.id)
            .subscribe(() => this.table.refreshTable());
    }
}
