import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable, of } from 'rxjs';

import { SessionModel } from '@fliq/service-library';
import { Functionality } from '@fliq/datamodel-library';
import { LanguagePipe } from '../pipes/language-pipe';

@Injectable({
  providedIn: 'root'
})
export class ModulesGuard implements CanActivate, CanLoad {

  get modules(): Functionality[] {
    return this.session.modules?.filter(module => module.param_type === 'module') || [];
  }

  constructor(
    private snackBar: MatSnackBar,
    private session: SessionModel,
    private langPipe: LanguagePipe
  ) { }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const segments = state.url.split('/');
    if (this.isAllowed(segments[2])) {  // only take the part after /main
      return of(true);
    } else {
      // show notification that user is not allowed
      if (this.session?.user?.lang) {
        this.snackBar.open(`${this.langPipe.transform(949)}`, 'Ok', { duration: 3000 });
      }
      return of(false);
    }
  }

  canLoad(route: Route): Observable<boolean> {
    if (this.isAllowed(route.path)) {
      return of(true);
    } else {
      // show notification that user is not allowed
      if (this.session?.user?.lang) {
        this.snackBar.open(`${this.langPipe.transform(949)}`, 'Ok', { duration: 3000 });
      }
      return of(false);
    }
  }

  isAllowed(url: string): boolean {
    // find the module that has the same name as the url
    return this.modules.some(mod => mod.route === url);
  }

}
