import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Observable, of } from 'rxjs';

import { SessionModel, AuthService } from '@fliq/service-library';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private session: SessionModel, private authService: AuthService) { }

  canActivate(): Observable<boolean> {
    if (this.session.user) {
      return of(true);
    } else {
      return this.authService.login('', '');
    }
  }

}
