import {
    Component,
    Input,
    OnInit,
    OnDestroy,
    Output,
    EventEmitter,
    ViewChild,
    ViewChildren,
    QueryList,
    AfterViewInit,
    ContentChild,
    TemplateRef,
    OnChanges,
    SimpleChanges,
    ElementRef
} from '@angular/core';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatMenu } from '@angular/material/menu';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort, MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { MatSelect } from '@angular/material/select';
import { fromEvent, Subscription } from 'rxjs';
import { SelectContainerComponent } from 'ngx-drag-to-select';
import { ColumnsService, DropDownService, SessionModel } from '@fliq/service-library';
import { TableCellType, TableColumnOptions, TableRefreshEvent } from '@fliq/datamodel-library';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog-component';
import { LanguagePipe } from 'src/app/pipes/language-pipe';
import { TableToolbarComponent } from './table-toolbar/table-toolbar-component';
import { ColumnOption, ColumnOptionsComponent } from '../column-options/column-options.component';
import { TableFilters } from './table-toolbar/table-filter/table-filter-component';
import { EditableComponent } from './editable/editable.component';

@Component({
    selector: 'app-table',
    templateUrl: './table-component.html',
    styleUrls: ['./table-component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class TableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

    @Input() dataSource: MatTableDataSource<any> | any[] | DataSource<any>;
    @Input() records: number;
    @Input() pageSize: number;
    @Input() pageSizeOptions: number[] = [];
    @Input() add: boolean;
    @Input() edit: boolean;
    @Input() delete: boolean;
    @Input() exportExcel: boolean;
    @Input() exportPDF: boolean;
    @Input() copy: boolean;
    @Input() refresh: boolean;
    @Input() tableWidth: number;
    @Input() expandableRows: boolean;
    @Input() expandConditionCol: string;
    @Input() columns: TableColumnOptions[] = [];
    @Input() rows: any[] = [];
    @Input() sortCol = 'id';
    @Input() sortOrder: 'desc' | 'asc' = 'desc';
    @Input() detailCol: boolean;
    @Input() footerRow = false;
    @Input() extraFooter = false;
    @Input() extraFooterdataSource: MatTableDataSource<any> | any[] | DataSource<any>;
    @Input() approvableRows = false;
    @Input() approvableRowsCustomisation = false;
    @Input() csvExportAsPDF: boolean;
    @Input() switchView: boolean;
    @Input() isTableView: boolean;
    @Input() update: boolean;
    @Input() openPdfMenu: boolean;
    @Input() pdfMenu: MatMenu;
    @Input() title: string;
    @Input() selectCells: boolean;
    @Input() cellColor: boolean;
    @Input() move: boolean;
    @Input() columnOptions: boolean;
    @Input() tableName: string;
    @Input() selectCol = false;
    @Input() openDateRangeMenu: boolean;
    @Input() dateRangeMenu: MatMenu;
    @Input() cellBorders = false;
    @Input() e2e_id_name: string;

    @Output() onRowSelect: EventEmitter<any> = new EventEmitter<any>();
    @Output() onMarkCheck: EventEmitter<any> = new EventEmitter<any>();
    @Output() onCheckClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() onRowDoubleClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() onCellClick = new EventEmitter<{ cell: string, row: any }>();
    @Output() cellBtnClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() onAdd: EventEmitter<void> = new EventEmitter<void>();
    @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
    @Output() onRefresh: EventEmitter<TableRefreshEvent> = new EventEmitter<TableRefreshEvent>();
    @Output() onExportExcel: EventEmitter<TableRefreshEvent> = new EventEmitter<TableRefreshEvent>();
    @Output() onExportPDF: EventEmitter<TableRefreshEvent> = new EventEmitter<TableRefreshEvent>();
    @Output() onCopy: EventEmitter<void> = new EventEmitter<void>();
    @Output() onOpenDetailPanel: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowApprovedStatusChanged: EventEmitter<{ approved: boolean, row: any }> = new EventEmitter<{ approved: boolean, row: any }>();
    @Output() rowsApprovedStatusChanged: EventEmitter<{ approved: boolean, rows: any[] }> = new EventEmitter<{ approved: boolean, rows: any[] }>();
    @Output() unApprovedWorkItemsClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() onCsvExportAsPDF: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSwitchView: EventEmitter<void> = new EventEmitter<void>();
    @Output() onUpdate: EventEmitter<void> = new EventEmitter<void>();
    @Output() onMenuOpen: EventEmitter<TableRefreshEvent> = new EventEmitter<TableRefreshEvent>();
    @Output() onSelectCells: EventEmitter<any> = new EventEmitter<any>();
    @Output() onMove: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowDropped = new EventEmitter<CdkDragDrop<any[]>>();

    @Output() onRowInlineEdit = new EventEmitter<{ row: any, colDef: string, field: string, fieldType: string, value: any, ddOption: any }>();

    showFilterBar: boolean;
    loading: boolean;
    displayedColumns: any[] = [];
    extraFooterColumns: TableColumnOptions[] = [];
    selectedRow: any;
    pageIndex = 1;
    checkValue = true;
    isSelected = false;
    hasDate = false;
    expandedElement: any | null;
    sortChangeSubscription: Subscription;
    selection = new SelectionModel<any>(true, []);
    filterFields: any[] = [];
    colSpanColumns: any[] = [];
    rowSpanColumns: any[] = [];
    isSelectingCells = false;
    clearTableCellSubscription: Subscription;
    selectedCellType: any;
    private columOptionColumns: ColumnOption[];
    sortingBySortOrder = false;
    filteringActive = false;
    currentEditingColumn: string;
    dropdownList: any[] = [];
    dropDownNames: string[];
    newDateValue: string;
    selectsSubscription: Subscription;
    datePickersSubscription: Subscription;
    extraFilters: any;

    @ViewChild(MatSort, { static: true }) private sort: MatSort;
    @ViewChild(TableToolbarComponent) tableToolbar: TableToolbarComponent;
    @ViewChild('selectAll') private allCheckbox: MatCheckbox;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(SelectContainerComponent) selectContainer: SelectContainerComponent;
    @ViewChild(EditableComponent) editableCell: EditableComponent;

    @ViewChildren('dropdown') selects: QueryList<MatSelect>;
    select: MatSelect;
    @ViewChildren('dateTimeInput') datePickers: QueryList<ElementRef>;
    @ContentChild(TemplateRef) templateVariable: TemplateRef<any>;

    constructor(
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private langPipe: LanguagePipe,
        private columnService: ColumnsService,
        private ddService: DropDownService,
        private session: SessionModel
    ) { }

    ngOnInit(): void {
        if (!this.columns?.length && this.tableName) {
            this.getTableColumns();
        }
    }

    ngAfterViewInit(): void {
        this.updateSortHeader(this.sortCol, this.sortOrder);

        this.sortingBySortOrder = this.sort.active === 'sort_order';

        /* Use settimeout for viewchild to be initialized in parent component, so calling table.loadig wont cause error */
        /* TODO: Find some other way than this hack to fix above issue */
        setTimeout(() => {
            this.refreshTable();
        }, 0);

        this.sortChangeSubscription = this.sort.sortChange.subscribe((event: Sort) => {
            this.changeSort(event);
        });

        // reset cell selection when clicking outside container
        this.clearTableCellSubscription = fromEvent(document, 'mouseup').subscribe(() => {
            if (!this.isSelectingCells) {
                this.selectContainer.clearSelection();
            }
        });

        // open dropdown as soon as it is clicked
        this.selectsSubscription = this.selects.changes.subscribe(value => {
            const select = value.first;
            if (select && this.selects.length > 0 && this.currentEditingColumn) {
                this.getDropdownList(this.currentEditingColumn);
                select.open();
            }
        });

        // open datepicker as soon as it is clicked
        this.datePickersSubscription = this.datePickers.changes.subscribe(value => {
            const datePicker = value.first;
            if (datePicker) {
                const element = datePicker.nativeElement;
                element.click();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        for (const propName in changes) {
            if (propName === 'columns') {
                if (Array.isArray(changes[propName].currentValue)) {
                    this.getDisplayedColumns();
                }
            }
        }
    }

    // Emit event when value is edited
    updateField(row: any, col: any, value: any, cell: any): void {
        let field: string = col.def;

        // If field is specified in column_options, use that field instead
        if (col.inline_edit_field) {
            field = col.inline_edit_field;
        }

        this.onRowInlineEdit.emit({
            row,
            colDef: col.def,
            field,
            fieldType: col.field_type,
            value,
            ddOption: null
        });

        cell.toViewMode();
    }

    // For dropdown edits, also include the dropdown value data
    updateDropdownField(row: any, col: any, value: any, cell: any): void {
        let field: string = col.def;
        if (col.inline_edit_field) {
            field = col.inline_edit_field;
        }

        this.onRowInlineEdit.emit({
            row,
            colDef: col.def,
            field,
            fieldType: col.field_type,
            value: value.value.id,
            ddOption: value.value
        });

        cell.toViewMode();
    }

    updateDateField(row: any, col: any, value: any, _dateOnly: boolean, cell: any): void {
        // Create string with local time instead of UTC
        const z = value.getTimezoneOffset() * 60 * 1000;
        let localDate = new Date(value - z).toISOString();
        localDate = localDate.slice(0, 19) + 'Z';
        this.updateField(row, col, localDate, cell);
    }

    getDropdownList(colName: string) {
        const colIndex = this.columns.findIndex(item => item.def === colName);
        const currentColumn = this.columns[colIndex];

        currentColumn.filter.dataSub.subscribe(
            (res: any[]) => {
                this.dropdownList = res;
            }
        );
    }

    private getTableColumns(): void {
        this.columnService.getColumns(this.tableName).subscribe((columns: ColumnOption[]) => {
            this.columOptionColumns = columns;
            this.setTableColumns();
        });
    }

    private setTableColumns(): void {
        this.columns = [];
        this.columOptionColumns.sort((a, b) => Number(a.sort_order) - Number(b.sort_order));

        for (const col of this.columOptionColumns) {
            if (col.active === '0') {
                continue;
            }
            const tableCol: TableColumnOptions = {
                def: col.name,
                name: col.lang_tag_text,
                langTag: Number(col.lang_tag_id),
                type: col.col_type as TableCellType,
                sortable: col.sortable === '1',
                editable: col.editable === '1',
                width: col.width,
                align: col.align,
                field_type: col.field_type,
                footerCell: col.footer_cell,
                abbrTooltip: col.abbr_tooltip ? this.langPipe.transform(+col.abbr_tooltip) : ''
            };
            if (col.field_type) {
                const filter: any = { type: col.field_type };
                if (col.field_type === 'selectOne' || col.field_type === 'multiSelect') {
                    filter.dd_name = col.dd_name;
                    filter.dataSub = this.ddService.getDropDownList(col.dd_name, false, false, 'domain_in', this.session.user.domainId);
                }
                tableCol.filter = filter;
            }
            if (col.inline_edit_field) {
                tableCol.inline_edit_field = col.inline_edit_field;
            }
            this.columns.push(tableCol);
        }

        if (this.selectCol) {
            this.columns.unshift({ def: 'select', type: TableCellType.MULTISELECT, width: '5%' });
        }

        this.getDisplayedColumns();
    }

    getDisplayedColumns(): void {
        this.showFilterBar = this.columns.some(col => col.hasOwnProperty('filter'));
        this.displayedColumns = [];
        this.displayedColumns = this.columns.filter(col => !col.colSpan).map(col => col.def);
        this.filterFields = this.columns.filter(col => col.filter);

        if (this.expandableRows) {
            this.displayedColumns.unshift('expandRow');
        }

        if (this.detailCol) {
            this.displayedColumns.unshift('detailCol');
        }

        if (this.approvableRows) {
            this.displayedColumns.unshift('approveRow');
        }

        if (this.approvableRowsCustomisation) {
            this.displayedColumns.unshift('approveRowCustom');
        }

        this.colSpanColumns = this.columns.filter(col => col.colSpan).map(col => col.def);
        this.rowSpanColumns = this.columns.filter(col => col.rowSpan);

        // get first and last positions of columns that take more than one column width
        const colSpanValues = this.columns.map(col => col.colSpan);
        const firstIndex = colSpanValues.indexOf(true);
        const lastIndex = colSpanValues.lastIndexOf(true);

        // add remaining columns at right position
        this.columns.forEach((col, index) => {
            if (col.rowSpan) {
                if (index < firstIndex) {
                    this.colSpanColumns.unshift(col.def + '_header');
                } else if (index > lastIndex) {
                    this.colSpanColumns.push(col.def + '_header');
                } else if (index > firstIndex && index < lastIndex) {
                    this.colSpanColumns.splice(index - firstIndex, 0, col.def + '_header');
                }
            }
        });

        if (this.extraFooter && this.displayedColumns.length > 0) {
            this.extraFooterColumns = [];
            this.displayedColumns.forEach(col => {
                col = `ex_${col}`;
                this.extraFooterColumns.push(col);
            });
        }
    }

    selectRow(row: any): void {
        // disable row selection when selecting multiple cells
        if (!this.selectCells && this.selectedCellType !== 'link' && this.selectedCellType !== 'detailCol') {
            this.selectedRow = row;
            this.onRowSelect.emit(row);
            this.markCheck({ checked: !this.selection.isSelected(row) }, row);
        }
    }

    markCheck(evt: any, row: any): void {
        this.selectedRow = (!evt.checked && this.selectedRow === row) ? null : row;
        this.selection.toggle(row);

        this.onMarkCheck.emit({ msgId: row.id, checkValue: evt.checked, row });
    }

    dblClickRow(row: any): void {
        this.onRowDoubleClick.emit(row);
    }

    cellClick(cell: string, row: any, cellType: string): void {
        this.selectedCellType = cellType;
        // prevent tooltip from displaying after navigation
        setTimeout(() => this.onCellClick.emit({ cell, row }), 10);
    }

    onCellBtnClick(cellName: string, row: any): void {
        // prevent tooltip from displaying after navigation
        setTimeout(() => this.cellBtnClick.emit({ cell: cellName, row }), 10);
    }

    pageChange(event: PageEvent): void {
        this.pageIndex = event.pageIndex + 1;
        this.pageSize = event.pageSize;
        this.refreshTable();
    }

    changeSort(sort: Sort): void {
        if ((!sort.active || sort.direction === '') && this.displayedColumns.every(col => col !== sort.active)) {
            this.sort.active = this.sortCol;
            this.sort.direction = this.sortOrder;
        }

        this.sortingBySortOrder = this.sort.active === 'sort_order';

        this.refreshTable();
    }

    addRow(evt: any): void {
        this.onAdd.emit(evt);
    }

    deleteRow(): void {
        if (this.selection.isEmpty()) { return; }
        const confirmDialog: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
            data: {
                header: `${this.langPipe.transform(522)}`,
                text: `${this.langPipe.transform(879)}?`,
                cancelText: `${this.langPipe.transform(275)}`,
                confirmText: `${this.langPipe.transform(276)}`
            }
        });
        confirmDialog.afterClosed().subscribe(res => {
            if (res) {
                this.onDelete.emit(this.selectedRow);
                this.selectedRow = null;
            }
        });
    }

    editRow(): void {
        if (!this.rowSelected()) { return; }
        this.onEdit.emit(this.selectedRow);
        this.selectedRow = null;
    }
    setExtraFilters(filters) {
        this.extraFilters = filters;
    }

    public refreshTable(resetPage?: boolean): void {
        this.selectedRow = null;
        this.selection.clear();
        // Prevent calls without pageSize property
        // i.e Calls before input property set
        if (!this.pageSize) { return; }

        if (resetPage) {
            this.pageIndex = 1;
        }
        const event: TableRefreshEvent = new TableRefreshEvent({
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sortOrder: this.sort.direction,
            sortCol: this.sort.active,
            filters: this.getFilters()
        });
        this.onRefresh.emit(event);
    }

    exportTableExcel(): void {
        const event: TableRefreshEvent = new TableRefreshEvent({
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sortOrder: this.sort.direction,
            sortCol: this.sort.active,
            filters: this.getFilters()
        });
        this.onExportExcel.emit(event);
    }

    exportTablePDF(): void {
        let filters: {
            groupOp: string,
            rules: { field: string, op: string, data: string }[]
        } = {
            groupOp: 'AND',
            rules: []
        };
        const tableFilters = this.tableToolbar.getFilters();
        filters = tableFilters ? tableFilters : filters;
        const event: TableRefreshEvent = new TableRefreshEvent({
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sortOrder: this.sort.direction,
            sortCol: this.sort.active,
            filters: JSON.stringify(filters)
        });
        this.onExportPDF.emit(event);
    }

    copyData(): void {
        this.onCopy.emit();
    }

    csvExportTableAsPDF(): void {
        this.onCsvExportAsPDF.emit();
    }

    rowSelected(): boolean {
        if (!this.selectedRow) {
            this.snackBar.open(`${this.langPipe.transform(880)}!`, 'Ok', { duration: 3000 });
            return false;
        }
        return true;
    }

    getStatusCellColor(row: any, col: any): void {
        let backroundColor: any;
        const typeId: any = row.type ? row.type : row.status;
        switch (typeId) {
            case '1':
                backroundColor = 'green';
                break;
            case '2':
                backroundColor = 'yellow';
                break;
            case '0':
                backroundColor = 'red';
                break;
            case '306':
                backroundColor = 'grey';
                break;
            case '304':
                backroundColor = 'red';
                break;
            case '293':
                backroundColor = 'darkblue';
                break;
            case '3':
                backroundColor = 'blue';
                break;
        }
        if (!typeId && row.icon_color && !backroundColor) {
            backroundColor = row.icon_color;
        } else if (row[col] && !backroundColor) {
            backroundColor = row[col];
        }
        return backroundColor;
    }

    /****************** Detail area / subtable functions  ****************/

    toggleRowExpand(evt: MouseEvent): void {
        // Prevent row selection
        evt.stopPropagation();
        const target = evt.target as HTMLElement;
        // Toggle expand class on clicked mat-cell to change icons
        const targetParent = target.parentElement;
        targetParent.classList.contains('expanded') ? targetParent.classList.remove('expanded') : targetParent.classList.add('expanded');
        // Get detail row from DOM
        const expandableRow = target.parentElement.parentElement.nextElementSibling;
        expandableRow.classList.contains('collapsed') ?
            expandableRow.classList.remove('collapsed') : expandableRow.classList.add('collapsed');
        // Loop trough expanded row children to find mat-cell which child element needs to be expanded
        for (const node of expandableRow.childNodes as any) {
            const childNode: any = node as HTMLElement;
            // Check if child element is mat-cell element
            if (childNode instanceof HTMLElement && childNode.classList.contains('mat-column-expandedDetail')) {
                // Expandable div is firstChild of mat-cell
                (childNode.firstChild as HTMLElement).classList.contains('expanded') ?
                    (childNode.firstChild as HTMLElement).classList.remove('expanded') :
                    (childNode.firstChild as HTMLElement).classList.add('expanded');
            }
        }
    }

    showCellTooltip(el: Element, tooltip: MatTooltip, colType: string): void {
        if (colType === 'checkbox' || this.cellColor) { return; }
        const contentDiv = el.getElementsByTagName('div')[0];
        if (contentDiv?.scrollHeight > contentDiv?.clientHeight) {
            tooltip.show();
        }
    }

    showHeaderTooltip(el, tooltip: MatTooltip, message?: string): void {
        if (message) {
            tooltip.message = message;
            tooltip.show();
            return;
        }
        if (el.scrollHeight > el.clientHeight) {
            tooltip.show();
        }
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.rows ? this.rows.length : 0;
        return numRows > 0 ? numSelected === numRows : false;
    }

    toggleAll(evt: any): void {
        if (this.isAllSelected() || this.allCheckbox.indeterminate) {
            if (this.allCheckbox.indeterminate) {
                this.allCheckbox.toggle();
                evt.checked = false;
            }
            this.selection.clear();
            this.selectedRow = null;
            this.onMarkCheck.emit({ checkValue: evt.checked });
        } else {
            this.rows.forEach(row => this.markCheck(evt, row));
        }
    }

    openDetailPanel(row: any): void {
        this.selectedCellType = 'detailCol';
        this.selection.clear();
        this.selectedRow = row;
        this.onRowSelect.emit(row);
        this.selection.select(row);
        this.onMarkCheck.emit({ msgId: row.id, checkValue: true, row });
        this.onOpenDetailPanel.emit(row);
    }

    getColSum(col): any {
        if (this.dataSource instanceof Array) {
            return +this.dataSource.map(row => {
                if (!row.isSubTotal && !row.isSubAvg) {
                    let colValue = row[col.def] || 0;
                    if (typeof colValue === 'string' && colValue.indexOf(' ') !== -1) {
                        colValue = colValue.substring(0, colValue.indexOf(' '));
                    }
                    return Number(colValue);
                }
                return 0;
            }).reduce((acc, value) => acc + value, 0).toFixed(2);
        } else if (this.dataSource instanceof MatTableDataSource) {
            return +this.dataSource.data.map(row => {
                if (!row.isSubTotal && !row.isSubAvg) {
                    let colValue = row[col.def] || 0;
                    if (typeof colValue === 'string' && colValue.indexOf(' ') !== -1) {
                        colValue = colValue.substring(0, colValue.indexOf(' '));
                    }
                    return Number(colValue);
                }
                return 0;
            }).reduce((acc, value) => acc + value, 0).toFixed(2);
        } else if (this.dataSource instanceof DataSource) {
            // TODO: figrue out how to implement or remove DataSource possibility from table
        }
    }

    getColAvg(col): any {
        const sum = this.getColSum(col);
        if (this.dataSource instanceof Array) {
            const count = this.dataSource.filter(row => {
                if (!row.isSubTotal && !row.isSubAvg) {
                    let colValue = row[col.def] || 0;
                    if (typeof colValue === 'string' && colValue.indexOf(' ') !== -1) {
                        colValue = colValue.substring(0, colValue.indexOf(' '));
                    }
                    return Number(colValue) > 0;
                }
                return false;
            }).length;
            return count > 0 ? +(sum / count).toFixed(2) : 0;
        } else if (this.dataSource instanceof MatTableDataSource) {
            const count = this.dataSource.data.filter(row => {
                if (!row.isSubTotal && !row.isSubAvg) {
                    let colValue = row[col.def] || 0;
                    if (typeof colValue === 'string' && colValue.indexOf(' ') !== -1) {
                        colValue = colValue.substring(0, colValue.indexOf(' '));
                    }
                    return Number(colValue) > 0;
                }
                return false;
            }).length;
            return count > 0 ? +(sum / count).toFixed(2) : 0;
        }
    }

    onAllApproveStatusChange() {
        let rowsToUpdate = [];
        let value: boolean;
        if (this.isAllApproved()) {
            // disapprove all
            value = false;
            if (this.dataSource instanceof Array) {
                rowsToUpdate = this.dataSource.slice();
            } else if (this.dataSource instanceof MatTableDataSource) {
                rowsToUpdate = this.dataSource.data.slice();
            }
        } else if (this.isSomeApproved()) {
            // approve non approved fields
            value = true;
            if (this.dataSource instanceof Array) {
                rowsToUpdate = this.dataSource.filter(row => row.approved == 0);
            } else if (this.dataSource instanceof MatTableDataSource) {
                rowsToUpdate = this.dataSource.data.filter(row => row.approved == 0);
            }
        } else {
            // approve all
            value = true;
            if (this.dataSource instanceof Array) {
                rowsToUpdate = this.dataSource.slice();
            } else if (this.dataSource instanceof MatTableDataSource) {
                rowsToUpdate = this.dataSource.data.slice();
            }
        }
        this.rowsApprovedStatusChanged.next({ approved: value, rows: rowsToUpdate });
        for (const row of rowsToUpdate) {
            row.approved = value ? 1 : 0;
        }
    }

    onRowApproveStatusChange(value: boolean, row: any) {
        this.rowApprovedStatusChanged.next({ approved: value, row });
        row.approved = value ? 1 : 0;
    }

    isAllApproved(): boolean {
        if (this.dataSource instanceof Array) {
            return this.dataSource.every(row => row.approved == 1);
        } else if (this.dataSource instanceof MatTableDataSource) {
            return this.dataSource.data.every(row => row.approved == 1);
        }
    }

    isSomeApproved(): boolean {
        if (this.dataSource instanceof Array) {
            return this.dataSource.some(row => row.approved == 1);
        } else if (this.dataSource instanceof MatTableDataSource) {
            return this.dataSource.data.some(row => row.approved == 1);
        }
    }

    allowAllApproval(): boolean {
        if (this.dataSource instanceof Array) {
            return this.dataSource.every(row => row.allowApproval);
        } else if (this.dataSource instanceof MatTableDataSource) {
            return this.dataSource.data.every(row => row.allowApproval);
        }
    }

    /*
    This using this custom function allows for the use of 'hidden' parameter which will not
    show checkboxes or care about their settings. Additionally, disabled sub-checkboxes will not
    change when trying to update all
    */
    onAllApproveStatusChangeCustom() {
        let rowsToUpdate = [];
        let value: boolean;
        if (this.isAllApproved()) {
            // disapprove all
            value = false;
            if (this.dataSource instanceof Array) {
                rowsToUpdate = this.dataSource.filter(row => row.allowApproval);
            } else if (this.dataSource instanceof MatTableDataSource) {
                rowsToUpdate = this.dataSource.data.filter(row => row.allowApproval);
            }
        } else if (this.isSomeApproved()) {
            // approve non approved fields
            value = true;
            if (this.dataSource instanceof Array) {
                rowsToUpdate = this.dataSource.filter(row => row.approved == 0 && row.allowApproval);
            } else if (this.dataSource instanceof MatTableDataSource) {
                rowsToUpdate = this.dataSource.data.filter(row => row.approved == 0 && row.allowApproval);
            }
        } else {
            // approve all
            value = true;
            if (this.dataSource instanceof Array) {
                rowsToUpdate = this.dataSource.filter(row => row.allowApproval);
            } else if (this.dataSource instanceof MatTableDataSource) {
                rowsToUpdate = this.dataSource.data.filter(row => row.allowApproval);
            }
        }
        this.rowsApprovedStatusChanged.next({ approved: value, rows: rowsToUpdate });
        for (const row of rowsToUpdate) {
            row.approved = value ? 1 : 0;
        }
    }

    isAllApprovedHidden(): boolean {
        if (this.dataSource instanceof Array) {
            return this.dataSource.every(row => row.approved == 1 || row.hidden);
        } else if (this.dataSource instanceof MatTableDataSource) {
            return this.dataSource.data.every(row => row.approved == 1 || row.hidden);
        }
    }

    isSomeApprovedHidden(): boolean {
        if (this.dataSource instanceof Array) {
            return this.dataSource.some(row => row.approved == 1 && !row.hidden);
        } else if (this.dataSource instanceof MatTableDataSource) {
            return this.dataSource.data.some(row => row.approved == 1 && !row.hidden);
        }
    }

    allowNoneApproval(): boolean {
        if (this.dataSource instanceof Array) {
            return this.dataSource.every(row => !row.allowApproval);
        } else if (this.dataSource instanceof MatTableDataSource) {
            return this.dataSource.data.every(row => !row.allowApproval);
        }
    }

    onUnapprovedWorkItemsClick(row: any): void {
        this.unApprovedWorkItemsClick.next(row);
    }

    onViewChanged(): void {
        this.onSwitchView.emit();
    }

    updateBtnClicked(): void {
        this.onUpdate.emit();
    }

    openMenu(): void {
        let filters: TableFilters = {
            groupOp: 'AND',
            rules: []
        };
        const tableFilters = this.tableToolbar.getFilters();
        filters = tableFilters ? tableFilters : filters;
        const event: TableRefreshEvent = new TableRefreshEvent({
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sortOrder: this.sort.direction,
            sortCol: this.sort.active,
            filters: JSON.stringify(filters)
        });
        this.onMenuOpen.emit(event);
    }

    selectMultipleCells(cells: any): void {
        if (!(cells instanceof Array)) {
            this.selectContainer.clearSelection();
        } else {
            const data = [];
            cells.forEach(cell => {
                const index = cell.split('-');
                data.push({ row: index[0], column: index[1] });
            });
            this.onSelectCells.emit(data);
        }
    }

    moveRow(): void {
        if (!this.rowSelected()) { return; }
        this.onMove.emit(this.selectedRow);
        this.selectedRow = null;
    }

    getCellTextColor(row: any, col: string): string {
        if (col.includes('avg_period') && row[col] > 40) {
            return 'red';
        } else if (row[col + '_text_color']) {
            return row[col + '_text_color'];
        }
        return '';
    }

    getCellBackgroundColor(row: any, col: any): string {
        if (col === 'averageSpeed') {
            return row.speedStatusColor;
        }
        if (row.rowColor) {
            return row.rowColor;
        }
        return '';
    }

    openTableColumOptions(): void {
        const dialog = this.dialog.open(ColumnOptionsComponent, {
            data: {
                table: this.tableName,
                columns: this.columOptionColumns
            }
        });

        dialog.afterClosed().subscribe((cols: ColumnOption[]) => {
            if (cols) {
                this.columOptionColumns = cols;
                this.setTableColumns();
            }
        });
    }

    private updateSortHeader(col: string, order: 'asc' | 'desc'): void {
        // hack to update sorting arrow direction. More info about issue https://github.com/angular/components/issues/10242
        this.sort.sort({ id: null, start: order, disableClear: false });
        this.sort.sort({ id: col, start: order, disableClear: false });

        const sortHeader = (this.sort.sortables.get(col) as MatSortHeader);
        if (sortHeader) { sortHeader._setAnimationTransitionState({ toState: 'active' }); }
    }

    updateSort(col: string, order: 'asc' | 'desc') {
        this.sort.active = null;
        this.sort.direction = '';
        this.sort.sort({ id: col, start: order, disableClear: false });
    }

    onRowDrop(event: CdkDragDrop<any[]>): void {
        this.rowDropped.next(event);
    }

    onFiltersChanged(): void {
        this.filteringActive = this.tableToolbar.getFilters()?.rules.length > 0;
        this.refreshTable(true);
    }
    getCompletedPercent(value: number): number {
        return Math.floor(value != null ? value * 100 : 0);
    }

    getTooltipText(data: any, cellType: string): string {
        if (data && cellType === 'date') {
            data = data.substring(0, 10) + ' ' + data.substring(11, 19);
        }
        return typeof data === 'object' || !data ? '' : data;
    }
    getColValue(col): any {
        if (col == null) { return; }
        const colName = col.replace('ex_', '');
        if (this.extraFooterdataSource?.hasOwnProperty(colName)) {
            if (colName === 'row_title') {
                return `${this.langPipe.transform(this.extraFooterdataSource[colName])}`;
            }
            else { return this.extraFooterdataSource[colName]; }
        }
        else {
            return 0;
        }
    }
    getColWidth(col: any) {
        if (col == null) { return null; }
        const colName = col.replace('ex_', '');
        const colData = this.columns.find(c => c.def === colName);
        if (colData?.width === undefined) { return null; }
        return colData.width;
    }

    getUrl(row: any, col: string): string {
        const urlCol = col.includes('name') ? col.replace('name', 'url') : 'url';
        if (row[urlCol]) {
            return row[urlCol];
        }
        return row[col];
    }

    getDownloadFileName(row: any, col: string): string {
        if (row.name) {
            return row.name;
        }
        return row[col];
    }

    private getFilters(): string {
        let filters: TableFilters = {
            groupOp: 'AND',
            rules: []
        };
        const tableFilters = this.tableToolbar.getFilters();
        filters = tableFilters ? tableFilters : filters;
        if (this.extraFilters != null) {
            this.extraFilters.forEach(filter => {
                if (filter.excludeTypeFromResults === true) {
                    if (filter.conditional != null && filter.conditional) {
                        filters.rules.push({
                            field: filter.fieldName,
                            op: filter.op,
                            data: filter.data,
                            conditional: filter.conditional,
                            condGroupOp: filter.condGroupOp,
                            condFieldName: filter.condFieldName,
                            condOp: filter.condOp,
                            condData: filter.condData
                        });
                    }
                    else {
                        filters.rules.push({
                            field: filter.fieldName,
                            op: filter.op,
                            data: filter.data
                        });
                    }
                }
            });
        }

        return JSON.stringify(filters);
    }

    ngOnDestroy(): void {
        this.sortChangeSubscription.unsubscribe();
        this.clearTableCellSubscription.unsubscribe();
        this.datePickersSubscription.unsubscribe();
        this.selectsSubscription.unsubscribe();
    }

}
