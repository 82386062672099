<mat-spinner *ngIf="loading"></mat-spinner>
<form *ngIf="assetForm" [formGroup]="assetForm" (ngSubmit)="submitForm(assetForm.value)">
    <h2 mat-dialog-title>{{type | titlecase}}</h2>
    <mat-dialog-content>
        <mat-tab-group style="height: 60vh">
            <mat-tab label="{{241 | langTag}}">
                <div fxLayout="column">
                    <!-- Asset name -->
                    <mat-form-field>
                        <input class="e2e-admin-asset-name" matInput placeholder="{{166 | langTag}}"
                            formControlName="name">
                    </mat-form-field>

                    <!-- Asset type -->
                    <mat-form-field>
                        <mat-select placeholder="{{391 | langTag}}" formControlName="eqtype">
                            <mat-option>
                                <ngx-mat-select-search formControlName="typeSearch" placeholderLabel="{{152 | langTag}}"
                                    noEntriesFoundLabel="{{578 | langTag}}">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let type of filteredTypes | async" [value]="type.id">{{type.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- Organisation -->
                    <mat-form-field>
                        <mat-select placeholder="{{341 | langTag}}" formControlName="org">
                            <mat-option>
                                <ngx-mat-select-search formControlName="orgSearch" placeholderLabel="{{152 | langTag}}"
                                    noEntriesFoundLabel="{{578 | langTag}}">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let org of filteredOrgs | async" [value]="org.id">{{org.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- Owner organisation -->
                    <mat-form-field>
                        <mat-select placeholder="{{946 | langTag}}" formControlName="ownerOrg">
                            <mat-option>
                                <ngx-mat-select-search formControlName="ownerOrgSearch"
                                    placeholderLabel="{{152 | langTag}}" noEntriesFoundLabel="{{578 | langTag}}">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let org of filteredOwnerOrgs | async" [value]="org.id">{{org.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- Invoiced organisation -->
                    <mat-form-field>
                        <mat-select placeholder="{{788 | langTag}}" formControlName="invOrg">
                            <mat-option>
                                <ngx-mat-select-search formControlName="invOrgSearch"
                                    placeholderLabel="{{152 | langTag}}" noEntriesFoundLabel="{{578 | langTag}}">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let org of filteredInvOrgs | async" [value]="org.id">{{org.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- Service organisation -->
                    <mat-form-field>
                        <mat-select placeholder="{{993 | langTag}}" formControlName="serviceOrg">
                            <mat-option>
                                <ngx-mat-select-search formControlName="serviceOrgSearch"
                                    placeholderLabel="{{152 | langTag}}" noEntriesFoundLabel="{{578 | langTag}}">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let org of filteredServiceOrgs | async" [value]="org.id">{{org.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- Cost centre -->
                    <mat-form-field>
                        <mat-select placeholder="{{2063 | langTag}}" formControlName="costcentre">
                            <mat-option>
                                <ngx-mat-select-search formControlName="costSearch" placeholderLabel="{{152 | langTag}}"
                                    noEntriesFoundLabel="{{578 | langTag}}">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option [value]="-1"></mat-option>
                            <mat-option *ngFor="let costcentre of filteredCosts | async" [value]="costcentre.id">
                                {{costcentre.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- Responsible team -->
                    <mat-form-field>
                        <mat-select placeholder="{{939 | langTag}}" formControlName="team">
                            <mat-option>
                                <ngx-mat-select-search formControlName="teamSearch" placeholderLabel="{{152 | langTag}}"
                                    noEntriesFoundLabel="{{578 | langTag}}">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-select-trigger>{{teamNameFromValue(assetForm.get('team').value)}}</mat-select-trigger>
                            <mat-option *ngFor="let team of filteredTeams | async" [value]="team.id">
                                <ng-container *ngIf="team.organisation_id != 0">
                                    <span>{{team.organisation_name}}</span>
                                    <span style="position: absolute; left: 290px;">{{team.name}}</span> 
                                </ng-container>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- Parent eq -->
                    <mat-form-field>
                        <mat-select placeholder="{{2085 | langTag}}" formControlName="parentEq">
                            <mat-option>
                                <ngx-mat-select-search formControlName="parentEqSearch" placeholderLabel="{{152 | langTag}}"
                                    noEntriesFoundLabel="{{578 | langTag}}">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let eq of filteredParentAssets | async" [value]="eq.id">{{eq.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- Sort order -->
                    <mat-form-field>
                        <input matInput placeholder="{{2081 | langTag}}" formControlName="order">
                    </mat-form-field>

                    <!-- Install date -->
                    <mat-form-field [owlDateTimeTrigger]="installDatepicker">
                        <input matInput placeholder="{{303 | langTag}}" [owlDateTime]="installDatepicker"
                            formControlName="date">
                        <owl-date-time #installDatepicker [pickerType]="'calendar'"></owl-date-time>
                        <mat-icon matSuffix class="datepicker-suffix-icon">today</mat-icon>
                    </mat-form-field>
                    <!-- Inspection valid date -->
                    <mat-form-field [owlDateTimeTrigger]="validDatepicker">
                        <input matInput placeholder="{{799 | langTag}}" [owlDateTime]="validDatepicker"
                            formControlName="valid">
                        <owl-date-time #validDatepicker [pickerType]="'calendar'"></owl-date-time>
                        <mat-icon matSuffix class="datepicker-suffix-icon">today</mat-icon>
                    </mat-form-field>
                    <!-- Warranty valid date -->
                    <mat-form-field [owlDateTimeTrigger]="warrantyDatepicker">
                        <input matInput placeholder="{{956 | langTag}}" [owlDateTime]="warrantyDatepicker"
                            formControlName="warranty">
                        <owl-date-time #warrantyDatepicker [pickerType]="'calendar'"></owl-date-time>
                        <mat-icon matSuffix class="datepicker-suffix-icon">today</mat-icon>
                    </mat-form-field>

                    <!-- Deleted -->
                    <mat-checkbox formControlName="deleted" style="margin-bottom: 10px">{{789 | langTag}}</mat-checkbox>

                    <!-- Asset tag -->
                    <mat-form-field>
                        <input matInput placeholder="{{790 | langTag}}" formControlName="tag">
                    </mat-form-field>

                    <ng-container *ngIf="userRole==='admin' || userRole==='supervisor'">
                        <!-- Purchase price -->
                        <mat-form-field>
                            <input matInput placeholder="{{957 | langTag}}" formControlName="price" type="number">
                            <span matSuffix>€</span>
                        </mat-form-field>

                        <!-- Depreciation time -->
                        <mat-form-field>
                            <input matInput placeholder="{{4138 | langTag}} ({{589 | langTag}})" formControlName="depreciationTime" 
                                type="number" autocomplete="off">
                        </mat-form-field>

                        <!-- Seller -->
                        <mat-form-field>
                            <input matInput placeholder="{{4139 | langTag}}" formControlName="seller" type="string" autocomplete="off">
                        </mat-form-field>

                        <!-- Insurance Type -->
                        <mat-form-field>
                            <mat-select placeholder="{{4154 | langTag}}" formControlName="insuranceType">
                                <mat-option *ngFor="let insuranceType of insuranceTypes" [value]="insuranceType.id">{{insuranceType.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>

                </div>
            </mat-tab>
            <mat-tab label="{{608 | langTag}}">
                <div fxLayout="column">
                    <!-- Geofence -->
                    <mat-form-field>
                        <mat-select placeholder="{{328 | langTag}}" formControlName="geofence">
                            <mat-option>
                                <ngx-mat-select-search formControlName="geoSearch" placeholderLabel="{{152 | langTag}}"
                                    noEntriesFoundLabel="{{578 | langTag}}">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let geo of filteredGeos | async" [value]="geo.id">{{geo.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- Location -->
                    <mat-form-field *ngIf="showOtherFields">
                        <mat-select placeholder="{{608 | langTag}}" formControlName="location">
                            <mat-option>
                                <ngx-mat-select-search formControlName="locationSearch"
                                    placeholderLabel="{{152 | langTag}}" noEntriesFoundLabel="{{578 | langTag}}">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let loc of filteredLocations | async" [value]="loc.id">{{loc.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-tab>
            <mat-tab label="UI">
                <div fxLayout="column">
                    <!-- Show title -->
                    <mat-checkbox formControlName="title" style="margin-bottom: 10px">{{269 | langTag}}</mat-checkbox>
                    <!-- Show on map -->
                    <mat-checkbox formControlName="showOnMap" style="margin-top: 10px; margin-bottom: 10px">
                        {{791 | langTag}}</mat-checkbox>

                    <!-- Asset shape -->
                    <mat-form-field>
                        <mat-select placeholder="{{268 | langTag}}" formControlName="shape">
                            <mat-option>
                                <ngx-mat-select-search formControlName="shapeSearch"
                                    placeholderLabel="{{152 | langTag}}" noEntriesFoundLabel="{{578 | langTag}}">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let shape of filteredShapes | async" [value]="shape.id">{{shape.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- FA icon -->
                    <mat-form-field>
                        <input matInput placeholder="{{270 | langTag}}" formControlName="faIcon">
                    </mat-form-field>
                </div>
            </mat-tab>
            <mat-tab label="{{378 | langTag}}" *ngIf="showOtherFields">
                <div fxLayout="column">
                    <!-- Brand -->
                    <mat-form-field>
                        <input matInput placeholder="{{792 | langTag}}" formControlName="brand">
                    </mat-form-field>
                    <!-- Model -->
                    <mat-form-field>
                        <input matInput placeholder="{{2026 | langTag}}" formControlName="model">
                    </mat-form-field>
                    <!-- Year -->
                    <mat-form-field>
                        <input matInput placeholder="{{140 | langTag}}" formControlName="year" type="number">
                    </mat-form-field>
                    <!-- Registration number -->
                    <mat-form-field>
                        <input matInput placeholder="{{793 | langTag}}" formControlName="reg">
                    </mat-form-field>
                    <!-- Manufacture number -->
                    <mat-form-field>
                        <input matInput placeholder="{{794 | langTag}}" formControlName="manufacture">
                    </mat-form-field>
                    <!-- Engine number -->
                    <mat-form-field>
                        <input matInput placeholder="{{952 | langTag}}" formControlName="engineNum">
                    </mat-form-field>
                    <!-- Engine type -->
                    <mat-form-field>
                        <mat-select placeholder="{{915 | langTag}}" formControlName="engineType">
                            <mat-option>
                                <ngx-mat-select-search formControlName="engineTypeSearch"
                                    placeholderLabel="{{152 | langTag}}" noEntriesFoundLabel="{{578 | langTag}}">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let type of filteredEngineTypes | async" [value]="type.id">{{type.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- Gear number -->
                    <mat-form-field>
                        <input matInput placeholder="{{953 | langTag}}" formControlName="gear">
                    </mat-form-field>
                    <!-- Shaft number -->
                    <mat-form-field>
                        <input matInput placeholder="{{954 | langTag}}" formControlName="shaft">
                    </mat-form-field>
                    <!-- Beam number -->
                    <mat-form-field>
                        <input matInput placeholder="{{955 | langTag}}" formControlName="beam">
                    </mat-form-field>

                    <!-- Radiophone -->
                    <mat-checkbox formControlName="radiophone" style="margin-bottom: 10px">{{798 | langTag}}
                    </mat-checkbox>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span>{{960 | langTag}}</span>
                    <mat-icon *ngIf="characteristicsInvalid" color="warn" style="margin-left: 10px;">
                        report_problem
                    </mat-icon>
                </ng-template>
                <div fxLayout="column">
                    <!-- Weight -->
                    <mat-form-field>
                        <input matInput placeholder="{{795 | langTag}}" formControlName="weight" type="number">
                        <span matSuffix>kg</span>
                        <mat-error *ngIf="assetForm.get('weight').hasError('min')">{{874 | langTag}}</mat-error>
                    </mat-form-field>
                    <!-- Height -->
                    <mat-form-field>
                        <input matInput placeholder="{{796 | langTag}}" formControlName="height" type="number">
                        <span matSuffix>mm</span>
                        <mat-error *ngIf="assetForm.get('height').hasError('min')">{{874 | langTag}}</mat-error>
                    </mat-form-field>
                    <!-- Width -->
                    <mat-form-field>
                        <input matInput placeholder="{{797 | langTag}}" formControlName="width" type="number">
                        <span matSuffix>mm</span>
                        <mat-error *ngIf="assetForm.get('width').hasError('min')">{{874 | langTag}}</mat-error>
                    </mat-form-field>
                    <!-- Tire size type -->
                    <mat-form-field>
                        <mat-select placeholder="{{959 | langTag}}" formControlName="tireSize">
                            <mat-option>
                                <ngx-mat-select-search formControlName="tireSizeSearch"
                                    placeholderLabel="{{152 | langTag}}" noEntriesFoundLabel="{{578 | langTag}}">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let size of filteredTireSizes | async" [value]="size.id">{{size.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- Tire brand -->
                    <mat-form-field>
                        <input matInput placeholder="{{961 | langTag}}" formControlName="tireBrand">
                    </mat-form-field>
                    <!-- Tire install date -->
                    <mat-form-field [owlDateTimeTrigger]="tireInstallDatepicker">
                        <input matInput placeholder="{{962 | langTag}}" [owlDateTime]="tireInstallDatepicker"
                            formControlName="tireInstallDate">
                        <owl-date-time #tireInstallDatepicker [pickerType]="'calendar'"></owl-date-time>
                        <mat-icon matSuffix class="datepicker-suffix-icon">today</mat-icon>
                    </mat-form-field>
                    <!-- Capacity -->
                    <mat-form-field>
                        <input matInput placeholder="{{763 | langTag}}" formControlName="capacity" type="number">
                    </mat-form-field>
                    <!-- Fuel type -->
                    <mat-form-field>
                        <mat-select placeholder="{{4169 | langTag}}" formControlName="fuelType">
                            <mat-option *ngFor="let fuelType of fuelTypes" [value]="fuelType.id">{{fuelType.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button type="button" mat-dialog-close>{{275 | langTag}}</button>
        <button mat-raised-button type="submit" color="accent">{{279 | langTag}}</button>
    </mat-dialog-actions>
</form>