import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

import { TableFilterComponent, TableFilters } from './table-filter/table-filter-component';

@Component({
    selector: 'table-toolbar',
    templateUrl: './table-toolbar-component.html',
    styleUrls: ['./table-toolbar-component.scss']
})
export class TableToolbarComponent {

    @Input() title: string;
    @Input() add: boolean;
    @Input() edit: boolean;
    @Input() delete: boolean;
    @Input() refresh: boolean;
    @Input() exportExcel: boolean;
    @Input() exportPDF: boolean;
    @Input() copy: boolean;
    @Input() csvExportAsPDF: boolean;
    @Input() filter: boolean;
    @Input() filterFields: any[] = [];
    @Input() selectedRow: any;
    @Input() switchView: boolean;
    @Input() isTableView: boolean;
    @Input() update: boolean;
    @Input() openPdfMenu: boolean;
    @Input() pdfMenu: MatMenu;
    @Input() move: boolean;
    @Input() columnOptions: boolean;
    @Input() openDateRangeMenu: boolean;
    @Input() dateRangeMenu: MatMenu;

    @Output() onAdd: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEdit: EventEmitter<void> = new EventEmitter<void>();
    @Output() onDelete: EventEmitter<void> = new EventEmitter<void>();
    @Output() onRefresh: EventEmitter<void> = new EventEmitter<void>();
    @Output() onExportExcel: EventEmitter<void> = new EventEmitter<void>();
    @Output() onExportPDF: EventEmitter<void> = new EventEmitter<void>();
    @Output() onCopy: EventEmitter<void> = new EventEmitter<void>();
    @Output() onCsvExportAsPDF: EventEmitter<void> = new EventEmitter<void>();
    @Output() filtersChanged: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSwitchView: EventEmitter<void> = new EventEmitter<void>();
    @Output() onUpdate: EventEmitter<void> = new EventEmitter<void>();
    @Output() onMenuOpen: EventEmitter<void> = new EventEmitter<void>();
    @Output() onMove: EventEmitter<void> = new EventEmitter<void>();
    @Output() openTableColumOptions = new EventEmitter<void>();
    @Output() onDateRangeMenuOpen: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(TableFilterComponent) tableFilter: TableFilterComponent;

    get disableChange(): boolean {
        return !this.selectedRow || this.selectedRow.length === 0;
    }

    constructor() { }

    addRow(evt: any): void {
        this.onAdd.emit(evt);
    }

    editRow(): void {
        this.onEdit.emit();
    }

    deleteRow(): void {
        this.onDelete.emit();
    }

    refreshTable(): void {
        this.onRefresh.emit();
    }

    exportTableExcel(): void {
        this.onExportExcel.emit();
    }

    exportTablePDF(): void {
        this.onExportPDF.emit();
    }

    copyData(): void {
        this.onCopy.emit();
    }

    csvExportTableAsPDF(): void {
        this.onCsvExportAsPDF.emit();
    }

    onFiltersChanged(evt: any): void {
        this.filtersChanged.next(evt);
        if (this.tableFilter.filters.rules.length === 0) {
            this.tableFilter.filterList = [];
        }
    }

    getFilters(): TableFilters {
        if (this.tableFilter) {
            return this.tableFilter.getFilters();
        }
    }

    clearFilters(): any {
        if (this.tableFilter) {
            return this.tableFilter.setFilterRules([]);
        }
    }

    onViewChanged(): void {
        this.isTableView = !this.isTableView;
        this.onSwitchView.emit();
    }

    onUpdateBtnClicked(): void {
        this.onUpdate.emit();
    }

    onMenuOpened(): void {
        this.onMenuOpen.emit();
    }

    moveRow(): void {
        this.onMove.emit();
    }

    onColOptionsClicked(): void {
        this.openTableColumOptions.emit();
    }

    onDateRangeMenuOpened(): void {
        this.onDateRangeMenuOpen.emit();
    }
}
