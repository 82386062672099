<div class="treeview-container">
    <div class="tree-header">
        <mat-form-field>
            <mat-label color="black">{{152 | langTag}}</mat-label>
            <input matInput #nodeSearch>
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <div fxLayout fxLayoutAlign="start center">
            <span *ngIf="treeviewTypes.length === 0">{{165 | langTag}}</span>
            <mat-button-toggle-group *ngIf="treeviewTypes.length > 0" [value]="selectedType"
                (change)="changeTreeviewType($event.value)">
                <mat-button-toggle *ngFor="let type of treeviewTypes" [value]="type.id" matTooltip="{{type.name}}">
                    <mat-icon [ngStyle]="{'transform': type.intparam ? 'rotate(' + type.intparam + 'deg)' : ''}">
                        {{type.strparam}}
                    </mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
            <span class="spacer"></span>
            <app-treeview-actions *ngIf="isRootUser && nodes"></app-treeview-actions>
        </div>
    </div>
    <tree-root #tree [nodes]="nodes">

        <ng-template #treeNodeWrapperTemplate let-node let-index="index">

            <div class="node-wrapper" draggable="true" (dragstart)="onDragStart($event, node)">
                <div class="node-content-wrapper" [class.node-content-wrapper-active]="node.isActive"
                    [class.node-content-wrapper-focused]="node.isFocused" (click)="selectNode(node)"
                    (dblclick)="node.toggleExpanded()" (contextmenu)="node.mouseAction('contextMenu', $event)"
                    (treeDrop)="node.onDrop($event)" [treeAllowDrop]="node.allowDrop" [treeDrag]="node"
                    [treeDragEnabled]="node.allowDrag()"
                    [ngStyle]="{'padding-left': node.data.level ? 8*(node.data.level) + 4 +'px' : '4px'}">

                    <app-treeview-node [nodeData]="node?.data"></app-treeview-node>
                    <tree-node-content [node]="node" [index]="index"></tree-node-content>
                    <div *ngIf="node.hasChildren" class="pull-right node-expander"
                        (click)="node.toggleExpanded(); $event.stopPropagation()">
                        <mat-icon *ngIf="node.isCollapsed">add</mat-icon>
                        <mat-icon *ngIf="node.isExpanded">remove</mat-icon>
                    </div>
                </div>
            </div>
        </ng-template>
    </tree-root>
</div>