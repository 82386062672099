import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';

import { IonicModule } from '@ionic/angular';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { SharedModule } from '../../../../modules/shared-module';
import { UpdateAssetDialogComponent } from './update-asset-dialog-component';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';

@NgModule({
    declarations: [
        UpdateAssetDialogComponent
    ],
    imports: [
        SharedModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatSnackBarModule,
        MatFormFieldModule,
        MatInputModule,
        NgxMatSelectSearchModule,
        IonicModule,
        MatButtonModule,
        MatTabsModule,
        FormsModule,
        OwlDateTimeModule,
        MatOptionModule,
        MatSelectModule,
        MatIconModule
    ],
    exports: [
        UpdateAssetDialogComponent
    ]
})
export class UpdateAssetDialogComponentModule { }
