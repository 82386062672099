import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { Subscription } from 'rxjs';

import { TreeNodeService } from '../tree-node.service';
import { FliqTreeNode } from '../treeview-model';

@Component({
    selector: 'app-treeview-node',
    templateUrl: './treeview-node-icon-component.html',
    styleUrls: ['./treeview-node-icon-component.scss']
})
export class TreeviewNodeComponent implements OnInit, OnDestroy {

    @Input() nodeData: FliqTreeNode;

    nodeColor = 'rgba(0, 0, 0, 0.54)';
    private subscription: Subscription;

    constructor(private cd: ChangeDetectorRef, private treeNodeService: TreeNodeService) { }

    ngOnInit(): void {
        if (!this.nodeData) {
            return;
        }

        if (this.nodeData.li_attr.type === 'eq') {
            this.subscription = this.treeNodeService.equipmentNodeColors[this.nodeData.li_attr.id]?.subscribe(color => {
                this.nodeColor = color;
                this.cd.detectChanges();
            });
        } else if (this.nodeData.li_attr.type === 'org') {  // set color for organisation node
            this.nodeColor = this.nodeData.li_attr.color ?? 'rgba(0, 0, 0, 0.54)';
        }
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

}
