import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datetime'
})
export class DateFormatPipe implements PipeTransform {
  transform(value: string): string {
    return value.substring(0, 10) + ' ' + value.substring(11, 19);
  }
}
