import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable, ReplaySubject, Subscription, merge, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { FilterService, OrganisationService, UserService, LocationService } from '@fliq/service-library';
import { LanguagePipe } from 'src/app/pipes/language-pipe';
import { ModuleParam } from '@fliq/datamodel-library';

@Component({
    templateUrl: './update-organisation-dialog-component.html'
})
export class UpdateOrganisationDialogComponent implements OnInit {

    org: any;
    type = '';
    orgForm: FormGroup;
    extraForm: FormGroup;
    organisations: any[] = [];
    locations: any[] = [];
    respPersons: any[] = [];
    extraFields: ModuleParam[] = [];

    filteredOrgs: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    filteredLocations: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    filteredRespPersons: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    filteredRespSalaryPersons: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    loading: boolean;
    subscriptions: Subscription[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<UpdateOrganisationDialogComponent>,
        private fb: FormBuilder,
        private snackBar: MatSnackBar,
        private filterService: FilterService,
        private orgService: OrganisationService,
        private userService: UserService,
        private locationService: LocationService,
        private langPipe: LanguagePipe
    ) {
        this.org = data.org;
        this.type = data.type === 'add' ? langPipe.transform(13) : langPipe.transform(297);
        this.extraFields = data.extraFields || [];
    }

    ngOnInit(): void {
        this.loading = true;
        this.dialogRef.updateSize('50%');
        forkJoin([merge(
            this.getOrganisations(),
            this.getLocations(),
            this.getRespPersons()
        )]).subscribe(
            () => {
                this.initForm();
                this.loading = false;
            },
            () => {
                this.loading = false;
                this.snackBar.open(`${this.langPipe.transform(570)}`, 'Ok', { duration: 5000 });
                this.dialogRef.close();
            }
        );
    }

    initForm(): void {
        this.orgForm = this.fb.group({
            name: [this.org ? this.org.name : '', Validators.required],
            parentOrg: this.org ? +this.org.parent_organisation_id : '',
            parentOrgSearch: '',
            location: this.org ? +this.org.location_id : '',
            locationSearch: '',
            respPerson: this.org ? +this.org.resp_person_id : '',
            respPersonSearch: '',
            active: !this.org || this.org.active !== '0' ? 1 : '',
            showInSupplierLists: this.org && this.org.show_in_supplier_dd !== '0' ? this.org.show_in_supplier_dd : '',
            respSalaryPerson: this.org ? +this.org.resp_salary_person : '',
            respSalaryPersonSearch: '',
            color: this.org && this.org.icon_color ? this.org.icon_color : '#ffffff',
            orgNr: this.org ? this.org.organisation_nr : ''
        });
        this.subscriptions.push(this.orgForm.controls.parentOrgSearch.valueChanges.subscribe((value) => {
            this.filteredOrgs.next(this.filterService.filterArray(this.organisations, value, 'name'));
        }));
        this.subscriptions.push(this.orgForm.controls.locationSearch.valueChanges.subscribe((value) => {
            this.filteredLocations.next(this.filterService.filterArray(this.locations, value, 'name'));
        }));
        this.subscriptions.push(this.orgForm.controls.respPersonSearch.valueChanges.subscribe((value) => {
            this.filteredRespPersons.next(this.filterService.filterArray(this.respPersons, value, 'name'));
        }));
        this.subscriptions.push(this.orgForm.controls.respSalaryPersonSearch.valueChanges.subscribe((value) => {
            this.filteredRespSalaryPersons.next(this.filterService.filterArray(this.respPersons, value, 'name'));
        }));
    }

    getOrganisations(): Observable<any> {
        return this.orgService.getEnums('intorg_names')
            .pipe(map(data => {
                this.organisations = data;
                if (this.organisations) {
                    this.filteredOrgs.next(this.organisations.slice());
                } else {
                    this.filteredOrgs.next([]);
                }
            }));
    }

    getLocations(): Observable<any> {
        return this.locationService.getEnums('', false, true)
            .pipe(map(data => {
                this.locations = data;
                if (this.locations) {
                    this.filteredLocations.next(this.locations.slice());
                } else {
                    this.filteredLocations.next([]);
                }
            }));
    }

    getRespPersons(): Observable<any> {
        return this.userService.getEnums('intorg_resp_pers_names')
            .pipe(map(data => {
                this.respPersons = data;
                if (this.respPersons) {
                    this.filteredRespPersons.next(this.respPersons.slice());
                    this.filteredRespSalaryPersons.next(this.respPersons.slice());
                } else {
                    this.filteredRespPersons.next([]);
                    this.filteredRespSalaryPersons.next([]);
                }
            }));
    }

    onExtraFormUpdated(form: FormGroup): void {
        this.extraForm = form;
    }

    submitForm(): void {
        const data = Object.assign({}, this.orgForm.value, this.extraForm?.value);
        if (this.orgForm.invalid) { return; }
        this.dialogRef.close(data);
    }

}
