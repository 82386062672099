<mat-spinner *ngIf="loading"></mat-spinner>
<form *ngIf="passwordForm" [formGroup]="passwordForm" (ngSubmit)="submitForm(passwordForm.value)">
    <h2 mat-dialog-title>{{4004 | langTag}}</h2>
    <mat-dialog-content>
        <div fxLayout="column">
            <mat-form-field hintLabel="{{306 | langTag}}">
                <input matInput placeholder="{{422 | langTag}}" formControlName="password" required [type]="'password'">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="{{4006 | langTag}}" formControlName="confirm" [type]="'password'">
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <!-- Cancel -->
        <button mat-button type="button" mat-dialog-close>{{275 | langTag}}</button>
        <button mat-raised-button type="submit" color="accent">{{279 | langTag}}</button>
    </mat-dialog-actions>
</form>