import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TeamService } from '@fliq/service-library';
import { LanguagePipe } from 'src/app/pipes/language-pipe';
import { TeamMembersDialogComponent } from '../../admin-teams/team-members-dialog/team-members-dialog-component';

@Component({
    selector: 'update-user-team',
    templateUrl: './update-user-team-component.html',
    styleUrls: ['./update-user-team-component.scss']
})
export class UpdateUserTeamComponent implements OnInit {
    @Input() userId: number;
    @Input() user: any;
    teams = [];
    teamMembers = [];
    teamsWithSelectedUser = [];
    teamsWithoutSelectedUser = [];

    constructor(
        private teamService: TeamService,
        private snackBar: MatSnackBar,
        private langPipe: LanguagePipe,
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.teamService.getAllTeams(200, 1, '', '', '')
            .subscribe(
                res => {
                    this.teams = res.rows;
                    this.getTeamMembers();
                });
    }

    getTeamMembers(): void {
        const teamIds = [];
        for (const team of this.teams) {
            team.userAddedToTeam = false;
            team.addRemUserBtn = `${this.langPipe.transform(4158)}`;
            teamIds.push(team.id);
        }
        this.teamService.getAllTeamMembers(teamIds).subscribe(
            res => {
                res.forEach(teamMember => {
                    if (teamMember.users_id == this.userId) {
                        const index = this.teams.findIndex(team => team.id == teamMember.team_id);
                        if (index !== -1) {
                            this.teams[index].addRemUserBtn = `${this.langPipe.transform(4159)}`;
                            this.teams[index].userAddedToTeam = true;
                            this.teamsWithSelectedUser.push(this.teams[index]);
                            this.teams.sort(this.sortTeamsByUserMembership);
                        }
                    }
                });
                for (const team of this.teams) {
                    if (!team.userAddedToTeam) {
                        this.teamsWithoutSelectedUser.push(team);
                    }
                }
            }
        );
    }

    addRemUserToTeam(team: any, evt: Event) {
        evt.preventDefault();
        if (!team.userAddedToTeam) {
            const userArray = [];
            userArray.push(this.user);
            this.teamService.addTeamMember(team.id, userArray, '').subscribe(
                res => {
                    if (res) {
                        const index = this.teamsWithoutSelectedUser.findIndex(teamObj => teamObj.id == team.id);
                        if (index !== -1) {
                            this.teamsWithoutSelectedUser.splice(index, 1);
                        }
                        this.teamsWithSelectedUser.push(team);
                        team.addRemUserBtn = `${this.langPipe.transform(4159)}`;
                        team.userAddedToTeam = true;
                        this.teams.sort(this.sortTeamsByUserMembership);
                        this.snackBar.open(`${this.langPipe.transform(4156)}`, 'Ok', { duration: 5000 });
                    }
                },
                () => this.snackBar.open(`${this.langPipe.transform(2096)}`, 'Ok', { duration: 5000 })
            );
        } else {
            this.teamService.removeTeamMember(team.id, this.userId).subscribe(
                (res) => {
                    if (res) {
                        const index = this.teamsWithSelectedUser.findIndex(teamObj => teamObj.id == team.id);
                        if (index !== -1) {
                            this.teamsWithSelectedUser.splice(index, 1);
                        }
                        this.teamsWithoutSelectedUser.push(team);
                        team.addRemUserBtn = `${this.langPipe.transform(4158)}`;
                        team.userAddedToTeam = false;
                        this.teams.sort(this.sortTeamsByUserMembership);
                        this.snackBar.open(`${this.langPipe.transform(4157)}`, 'Ok', { duration: 5000 });
                    }
                },
                () => this.snackBar.open(`${this.langPipe.transform(2096)}`, 'Ok', { duration: 5000 })
            );
        }
    }

    sortTeamsByUserMembership(a, b) {
        if (a.addRemUserBtn > b.addRemUserBtn) {
            return -1;
        }
        if (a.addRemUserBtn < b.addRemUserBtn) {
            return 1;
        }
        return 0;
    }

    openTeamMembersDialog(team: any): void {
        const dialogRef: MatDialogRef<TeamMembersDialogComponent> = this.dialog.open(TeamMembersDialogComponent, {
            data: {
                type: 'replace',
                team
            }
        });
        dialogRef.afterClosed().subscribe();
    }

}
