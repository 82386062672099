 <span class="node-icon-wrapper" [ngStyle]="{'color': nodeColor}">
    <!-- User defined node icon -->
    <mat-icon *ngIf="nodeData.li_attr.icon">
        {{nodeData.li_attr.icon}}
    </mat-icon>
    <!-- Default organisation icon -->
    <mat-icon *ngIf="!nodeData.li_attr.icon && nodeData.li_attr.type === 'org'">
        account_balance
    </mat-icon>
    <!-- Default asset icon -->
    <mat-icon *ngIf="!nodeData.li_attr.icon && nodeData.li_attr.type === 'eq'">
        settings_applications
    </mat-icon>
    <!-- Default user org icon -->
    <mat-icon *ngIf="!nodeData.li_attr.icon && nodeData.li_attr.type === 'user_org'">
        people
    </mat-icon>
    <!-- Default user icon -->
    <mat-icon *ngIf="!nodeData.li_attr.icon && nodeData.li_attr.parent_type === 'user_org'">
        person
    </mat-icon>
</span>