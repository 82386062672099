<div>
    <mat-list>
    <h3>{{4177 | langTag}}</h3>

        <mat-list-item *ngFor="let team of teamsWithSelectedUser; let i=index">
            <a class="link" (click)="openTeamMembersDialog(team)">{{team.name}}</a>
            <p class="orgName">{{team.organisation_name}}</p>
            <button mat-button color="primary" (click)="addRemUserToTeam(team, $event)">{{team.addRemUserBtn}}</button>
        </mat-list-item>
    </mat-list>
    <mat-list>
        <h3>{{4178 | langTag}}</h3>
        <mat-list-item *ngFor="let team of teamsWithoutSelectedUser; let i=index">
            <a class="link" (click)="openTeamMembersDialog(team)">{{team.name}}</a>
            <p class="orgName">{{team.organisation_name}}</p>
            <button mat-button color="primary" (click)="addRemUserToTeam(team, $event)">{{team.addRemUserBtn}}</button>
        </mat-list-item>
    </mat-list>
</div>
