<div class="table-container" fxLayout="column" fxFill>
  <app-loading-spinner *ngIf="loading"></app-loading-spinner>
  <div class="table-header" fxFlex="none">
    <table-toolbar
      [title]="title"
      [refresh]="refresh"
      [delete]="delete"
      [add]="add"
      [edit]="edit"
      [copy]="copy"
      [switchView]="switchView"
      [isTableView]="isTableView"
      [exportExcel]="exportExcel"
      [exportPDF]="exportPDF"
      [csvExportAsPDF]="csvExportAsPDF"
      [filter]="showFilterBar"
      [filterFields]="filterFields"
      [update]="update"
      [openPdfMenu]="openPdfMenu"
      [pdfMenu]="pdfMenu"
      [selectedRow]="selection.selected"
      [move]="move"
      [openDateRangeMenu]="openDateRangeMenu"
      [dateRangeMenu]="dateRangeMenu"
      [columnOptions]="columnOptions"
      (onRefresh)="refreshTable()"
      (onDelete)="deleteRow()"
      (onAdd)="addRow($event)"
      (onUpdate)="updateBtnClicked()"
      (onEdit)="editRow()"
      (onExportExcel)="exportTableExcel()"
      (onCopy)="copyData()"
      (onExportPDF)="exportTablePDF()"
      (onCsvExportAsPDF)="csvExportTableAsPDF()"
      (filtersChanged)="onFiltersChanged()"
      (onSwitchView)="onViewChanged()"
      (onMenuOpen)="openMenu()"
      (onMove)="moveRow()"
      (openTableColumOptions)="openTableColumOptions()"
    >
    </table-toolbar>
  </div>
  <!-- Table -->
  <div class="container" fxFlex="1 1 auto" (scroll)="dragSelectContainer.update()">
    <dts-select-container #dragSelectContainer [disabled]="!selectCells" (select)="selectMultipleCells($event)"
      (selectionStarted)="isSelectingCells = true" (selectionEnded)="isSelectingCells = false"
      [ngStyle]="{'min-width': tableWidth ? tableWidth : ''}">
      <mat-table 
        #dataTable
        [dataSource]="dataSource" 
        matSort 
        multiTemplateDataRows
        cdkDropList
        [cdkDropListDisabled]="!sortingBySortOrder || filteringActive"
        (cdkDropListDropped)="onRowDrop($event)"
        [ngStyle]="{'min-width': tableWidth ? tableWidth : ''}"
        [attr.e2e-id]="'mat_table_' + e2e_id_name"
      >
        <!-- Expand col for expandable rows  -->
        <ng-container *ngIf="expandableRows" matColumnDef="expandRow">
          <mat-header-cell *matHeaderCellDef class="icon-col"></mat-header-cell>
          <mat-cell #expandCell *matCellDef="let row" class="icon-col expand-icon"
            [style.visibility]="expandConditionCol && (!row[expandConditionCol] || !row[expandConditionCol].length) ? 'hidden' : 'initial'"
            (click)="toggleRowExpand($event)">
            <mat-icon *ngIf="expandCell.classList.contains('expanded')">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="!expandCell.classList.contains('expanded')">keyboard_arrow_right</mat-icon>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <!-- Detail col -->
        <ng-container *ngIf="detailCol" matColumnDef="detailCol">
          <mat-header-cell *matHeaderCellDef class="icon-col"></mat-header-cell>
          <mat-cell *matCellDef="let row" class="icon-col detail-icon" (click)="openDetailPanel(row)"
            matTooltip="{{241 | langTag}}">
            <mat-icon color="primary">tab</mat-icon>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef class="icon-col"></mat-footer-cell>
        </ng-container>

        <!-- Approve col -->
        <ng-container *ngIf="approvableRows" matColumnDef="approveRow">
          <mat-header-cell *matHeaderCellDef class="approve-col">
            <mat-checkbox [disabled]="!allowAllApproval()" [indeterminate]="isSomeApproved() && !isAllApproved()"
              [checked]="isAllApproved()" (change)="onAllApproveStatusChange()" matTooltip="{{2125 | langTag}}">
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="approve-col">
            <mat-checkbox [checked]="row.approved == 1" [disabled]="!row.allowApproval"
              (change)="onRowApproveStatusChange($event.checked, row)" matTooltip="{{2125 | langTag}}"
              (click)="$event.stopPropagation()">
            </mat-checkbox>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef class="approve-col"></mat-footer-cell>
        </ng-container>

        <!-- Approve col with custom modifications -->
        <ng-container *ngIf="approvableRowsCustomisation" matColumnDef="approveRowCustom">
          <mat-header-cell *matHeaderCellDef class="approve-col">
            <mat-checkbox [disabled]="allowNoneApproval()"
              [indeterminate]="isSomeApprovedHidden() && !isAllApprovedHidden()" [checked]="isAllApprovedHidden()"
              (change)="onAllApproveStatusChangeCustom()" matTooltip="{{2125 | langTag}}"></mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="approve-col">
            <mat-checkbox *ngIf="!row.hidden" [checked]="row.approved == 1" [disabled]="!row.allowApproval"
              (change)="onRowApproveStatusChange($event.checked, row)" matTooltip="{{2125 | langTag}}"
              (click)="$event.stopPropagation()">
            </mat-checkbox>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef class="approve-col"></mat-footer-cell>
        </ng-container>

        <!-- Definitions for columns in first header row that do not take more than one column width -->
        <ng-container *ngFor="let col of rowSpanColumns" [matColumnDef]="col.def + '_header'">
          <mat-header-cell *matHeaderCellDef [ngStyle]="{
            'text-align': col.align ? col.align : '',
            'max-width': col.width ? col.width : ''
          }">
            <div fxFill>
              <span #headerTooltip="matTooltip" matTooltip="{{(col.langTag) ? (col.langTag | langTag) : col.name}}"
                matTooltipClass="table-cell-hover-tooltip"></span>
              <div #headerContainer class="header-text"
                (mouseenter)="showHeaderTooltip(headerContainer, headerTooltip, col.abbrTooltip)"
                (mouseleave)=" headerTooltip.hide()">
                {{(col.langTag) ? (col.langTag | langTag) : col.name}}
              </div>
            </div>
          </mat-header-cell>
        </ng-container>

        <!-- Table columns passed via @input columns -->
        <ng-container *ngFor="let col of columns; let i = index;" matColumnDef="{{col.def}}">
          <!-- Table col headers -->
          <div *ngIf="!col.sortable && col.type !== 'multiselect'">
            <mat-header-cell *matHeaderCellDef [ngClass]="{
            'table-color-status-header': col.type === 'statusColor' && !cellColor,
            'icon-col': col.type === 'icon',
            'table-unapproved-work-items-cell icon-col': col.def === 'unapproved_work_items'
            }" [ngStyle]="{
              'text-align': col.align ? col.align : '',
              'max-width': col.width ? col.width : '',
              'background-color': col.highlight ? '#efefef' : ''
            }">
              <div fxFill *ngIf="!col.rowSpan">
                <span #headerTooltip="matTooltip" matTooltip="{{(col.langTag) ? (col.langTag | langTag) : col.name}}"
                  matTooltipClass="table-cell-hover-tooltip"></span>
                <div #headerContainer class="header-text"
                  (mouseenter)="showHeaderTooltip(headerContainer, headerTooltip, col.abbrTooltip)"
                  (mouseleave)=" headerTooltip.hide()">
                  {{(col.langTag) ? (col.langTag | langTag) : col.name}}
                </div>
              </div>
            </mat-header-cell>
          </div>
          <div *ngIf="col.sortable">
            <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{
              'table-status-color-cell': col.type === 'statusColor',
              'icon-col': col.type === 'icon',
              'table-unapproved-work-items-cell': col.def === 'unapproved_work_items'
              }" [ngStyle]="{
                'justify-content': col.align ? col.align : '',
                'max-width': col.width ? col.width : '',
                'background-color': col.highlight ? '#efefef' : ''
              }">

              <span #headerTooltip="matTooltip" matTooltip="{{(col.langTag) ? (col.langTag | langTag) : col.name}}"
                matTooltipClass="table-cell-hover-tooltip"></span>
              <div #headerContainer class="header-text"
                (mouseenter)="showHeaderTooltip(headerContainer, headerTooltip, col.abbrTooltip)"
                (mouseleave)=" headerTooltip.hide()">
                {{(col.langTag) ? (col.langTag | langTag) : col.name}}
              </div>

            </mat-header-cell>
          </div>
          <div *ngIf="col.type === 'multiselect'">
            <mat-header-cell *matHeaderCellDef [ngStyle]="{'max-width': col.width ? col.width : ''}">
              <mat-checkbox #selectAll (change)="toggleAll($event)" [checked]="isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </mat-header-cell>
          </div>

          <!-- Table col cell -->
          <mat-cell
            #cellContainer 
            *matCellDef="let row; let j = dataIndex"
            #cellTooltip="matTooltip" matTooltip="{{getTooltipText(row[col.def], col.type)}}"
            matTooltipClass="table-cell-hover-tooltip"

            [dtsSelectItem]="j + '-' + col.def"
            [ngSwitch]="col.type"
            [ngClass]="{
              'table-status-color-cell': col.type === 'statusColor' && !cellColor, 
              'table-status-color-cell-content': col.type === 'statusColor', 
              'table-unapproved-work-items-cell icon-col': col.def === 'unapproved_work_items',
              'table-link-cell': col.type === 'link' && !row.isSubTotal && !row.isSubAvg
            }"
            [ngStyle]="{
              'text-align': col.align ? col.align : '',
              'justify-content': col.align ? col.align : '',
              'max-width': col.width ? col.width : '',
              'background-color': col.highlight ? '#efefef' : '',
              'color': getCellTextColor(row, col.def),
              'background-color': getCellBackgroundColor(row, col.def),
              'border-right': cellBorders ? '1px solid rgb(204, 198, 198)' : ''
            }"
            (click)="cellClick(col.def, row, col.type); cellTooltip.hide();"
            (mouseenter)="showCellTooltip(cellContainer, cellTooltip, col.type)"
            (mouseleave)="cellTooltip.hide()"
            e2e-id="table_cell"
            >

            <span #cellTooltip="matTooltip" matTooltip="{{row[col.def]}}"
              matTooltipClass="table-cell-hover-tooltip"></span>
            <div *ngSwitchCase="'link'">
              <a class="link" *ngIf="!row.isSubTotal && !row.isSubAvg"
                [ngClass]="{'table-cell-highlight': row.highlight && col.def === 'time'}">{{row[col.def]}}</a>
              <span *ngIf="row.isSubTotal || row.isSubAvg" style="font-weight: 500;">{{row[col.def]}}</span>
            </div>
            <a *ngSwitchCase="'download'" style="cursor: pointer" [href]="getUrl(row, col.def)"
              [download]="getDownloadFileName(row, col.def)" target="_blank">{{row[col.def]}}</a>
            <button mat-button *ngSwitchCase="'button'" [disabled]="row.action_disabled"
              (click)="onCellBtnClick(col.def, row); $event.stopPropagation();">
              {{row[col.def]}}
            </button>
            <mat-checkbox *ngSwitchCase="'checkbox'" [disabled]="true" [checked]="row[col.def]"></mat-checkbox>
            <mat-checkbox *ngSwitchCase="'multiselect'" [disabled]="true" [checked]="selection.isSelected(row)"
              class="multiselect"></mat-checkbox>
            <p *ngSwitchCase="'statusname'">{{row[col.def]}}</p>
            <div *ngSwitchCase="'statusColor'" class="table-status-color-cell-content"
              [style.background-color]="getStatusCellColor(row, col.def)"></div>
            <div *ngSwitchCase="'checkmark'">
              <mat-icon *ngIf="row[col.def] && (row[col.def] != '' && row[col.def] != 0); else noCheck">
                check
              </mat-icon>
              <ng-template #noCheck>
                <mat-icon>check_box_outline_blank</mat-icon>
              </ng-template>
            </div>
            <div *ngSwitchCase="'multivalue'">
              <button
                *ngIf="!row[col.def] || (row[col.def] === '' || row[col.def] == -1 || row[col.def] == 0); else hasValue"
                mat-icon-button type="button">
                <mat-icon>
                  add
                </mat-icon>
              </button>
              <ng-template #hasValue>
                <span style="cursor: pointer">
                  {{row[col.def]}}
                </span>
              </ng-template>
            </div>
            <div *ngSwitchCase="'progressbar'" style="min-width: 100%;">
              <div>
                <mat-progress-bar style="min-height: 15px;" mode="determinate"
                  [value]="getCompletedPercent(row[col.def])">
                </mat-progress-bar>
              </div>
              <!-- {{row[col.def]}} -->

            </div>
            <div *ngSwitchCase="'select'">
              <span>{{row[col.selectName]}}</span>
            </div>
            <div *ngSwitchCase="'icon'">
              <div *ngIf="col.def === 'unapproved_work_items'">
                <mat-icon *ngIf="row[col.def].jobs.length || row[col.def].tasks.length" matTooltip="{{2127 |langTag}}"
                  class="unapproved-work-items-icon" matTooltipClass="table-cell-hover-tooltip"
                  (click)="onUnapprovedWorkItemsClick(row)">
                  warning
                </mat-icon>
              </div>
            </div>
            <div *ngSwitchCase="'date'">
              <span *ngIf="row[col.def]">{{row[col.def] | datetime}}</span>
            </div>

            <div *ngSwitchDefault class="default-edit">
              <div *ngIf="col.def === 'typeDescription'" fxLayout fxLayoutAlign="start center">
                <!-- Job type icon -->
                <mat-icon [style.color]="row['color']" style="margin-right: 5px;" *ngIf="row['icon']">
                  {{row['icon']}}</mat-icon>
                <span>{{row[col.def]}}</span>
              </div>

              <span *ngIf="col.def !== 'typeDescription'"
                [ngStyle]="{'font-weight': (row.isSubTotal || row.isSubAvg) ? 500 : ''}">

                <editable #editableCell class="editable-container" (click)="currentEditingColumn=col.def"
                  *ngIf="col.editable === true">
                  <!-- In view mode, display value as it is -->
                  <ng-template viewMode *ngIf=" (row[col.def] === null) || (row[col.def] === '' )">
                    <mat-form-field [style.pointer-events]="'none'">
                      <input #inputField matInput [value]="row[col.def]">
                    </mat-form-field>
                  </ng-template>
                  <ng-template viewMode *ngIf="(row[col.def] !== null) || (row[col.def] === '' )">
                    {{row[col.def]}}
                  </ng-template>

                  <!-- In edit mode, table cell turns into a form field -->
                  <ng-template editMode>
                    <ng-container appearance="standard" [ngSwitch]="col.field_type">

                      <mat-form-field *ngSwitchCase="'selectOne'">
                        <mat-label>{{col.def}}</mat-label>
                        <mat-select #dropdown [value]="row[col.def]"
                          (selectionChange)="updateDropdownField(row, col, $event, editableCell)">
                          <mat-option *ngFor="let option of dropdownList, let o = index" [value]="option">
                            {{option["name"]}}</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field *ngSwitchCase="'multiSelect'">
                        <mat-label>{{col.def}}</mat-label>
                        <mat-select #dropdown [value]="row[col.def]"
                          (selectionChange)="updateDropdownField(row, col, $event, editableCell)">
                          <mat-option *ngFor="let option of dropdownList, let o = index" [value]="option">
                            {{option["name"]}}</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field *ngSwitchCase="'datetime'">
                        <input matInput #dateTimeInput [owlDateTime]="datepicker" ([ngModel])="newDateValue"
                          [owlDateTimeTrigger]="datepicker"
                          (dateTimeChange)="updateDateField(row, col, $event.value, false, editableCell)">
                        <mat-icon matSuffix class="datepicker-suffix-icon" [owlDateTimeTrigger]="datepicker">today
                        </mat-icon>
                        <owl-date-time #datepicker [startAt]="row[col.def]"></owl-date-time>
                      </mat-form-field>

                      <mat-form-field *ngSwitchCase="'date'">
                        <input matInput #dateTimeInput [owlDateTime]="datepicker" ([ngModel])="newDateValue"
                          [owlDateTimeTrigger]="datepicker"
                          (dateTimeChange)="updateDateField(row, col, $event.value, true, editableCell)">
                        <mat-icon matSuffix class="datepicker-suffix-icon" [owlDateTimeTrigger]="datepicker">today
                        </mat-icon>
                        <owl-date-time #datepicker [pickerType]="'calendar'"></owl-date-time>
                      </mat-form-field>

                      <mat-form-field *ngSwitchDefault>
                        <input #inputField matInput [value]="row[col.def]"
                          (change)="updateField(row, col, $event.target.value, editableCell)">
                      </mat-form-field>

                    </ng-container>
                  </ng-template>
                </editable>
                <span *ngIf="!col.editable">{{row[col.def]}}</span>
              </span>
            </div>

          </mat-cell>

          <!-- Footer row -->
          <mat-footer-cell *matFooterCellDef [ngClass]="{
          'table-unapproved-work-items-cell icon-col': col.def === 'unapproved_work_items'
          }" [ngStyle]="{'max-width': col.width ? col.width : '', 'background-color': col.highlight ? '#efefef' : ''}">
            <ng-container *ngIf="col.footerCell">
              <ng-container [ngSwitch]="col.footerCell">
                <span *ngSwitchCase="'sum'" [ngStyle]="{'justify-content': col.align ? col.align : 'center'}" class="sum-footer-col">
                  {{getColSum(col)}}
                </span>
                <span *ngSwitchCase="'avg'" [ngStyle]="{'justify-content': col.align ? col.align : 'center'}" class="sum-footer-col">
                  {{getColAvg(col)}}
                </span>
                <span *ngSwitchDefault [ngStyle]="{'justify-content': col.align ? col.align : 'center'}">{{col.footerCell | langTag}}</span>
              </ng-container>
            </ng-container>
          </mat-footer-cell>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <mat-cell *matCellDef="let element" class="sub-table-cell">
            <div #subTableContainer class="element-detail"
              [@detailExpand]="subTableContainer.classList.contains('expanded') ? 'expanded' : 'collapsed'">
              <!-- Pass app-table component to render it as subrow -->
              <ng-template *ngIf="subTableContainer.classList.contains('expanded')"
                [ngTemplateOutlet]="templateVariable" [ngTemplateOutletContext]="{item: element}">
              </ng-template>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container *ngIf="colSpanColumns.length > 0">
          <mat-header-row *matHeaderRowDef="colSpanColumns; sticky: true"
            [ngStyle]="{'z-index': expandableRows ? 2 : ''}">
          </mat-header-row>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"
          [ngStyle]="{'z-index': expandableRows ? 2 : ''}">
        </mat-header-row>
        <mat-row 
          *matRowDef="let row; columns: displayedColumns;" 
          cdkDrag
          (click)="selectRow(row)"
          (dblclick)="dblClickRow(row)"
          [ngClass]="{
            'highlight': row.isHighlighted,
            'selected': row === selectedRow,
            'warning': row.warning,
            'disabled': row.disabled,
            'current-status': row.isCurrent || row.is_current,
            'past-status': row.isPast || row.is_past
          }"
          e2e-id="table_row">
        </mat-row>
        <ng-container *ngIf="footerRow">
          <mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"
            [ngStyle]="{'display': records === 0 || !records ? 'none' : ''}">
          </mat-footer-row>
        </ng-container>

        <ng-container *ngIf="extraFooter">
          <ng-container *ngFor="let col of extraFooterColumns" [matColumnDef]="col">
            <mat-footer-cell *matFooterCellDef="let row; extraFooterColumns" [ngStyle]="{'text-align': 'left',
                      'max-width': getColWidth(col) ? getColWidth(col): '', 
                      'background-color': '#efefef'}">
              <ng-container>
                <ng-container [ngSwitch]="col">
                  <span *ngSwitchCase="'ex_row_title'">
                    {{getColValue(col)}}
                  </span>
                  <span *ngSwitchDefault class="sum-footer-col">{{getColValue(col)}}</span>
                </ng-container>
              </ng-container>
            </mat-footer-cell>
          </ng-container>
          <ng-container *ngIf="extraFooter">
            <mat-footer-row *matFooterRowDef="extraFooterColumns; sticky: true"
              [ngStyle]="{'display': records === 0 || !records ? 'none' : ''}">
            </mat-footer-row>
          </ng-container>
        </ng-container>
        

        <ng-container *ngIf="expandableRows">
          <mat-row *matRowDef="let detail; columns: ['expandedDetail']" class="detail-row collapsed"></mat-row>
        </ng-container>
      </mat-table>
    </dts-select-container>
  </div>

  <div class="table-footer">
    <mat-paginator
      fxFlex #paginator
      [length]="records"
      [pageIndex]="pageIndex-1"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      (page)="pageChange($event)"
    >
    </mat-paginator>
  </div>
</div>