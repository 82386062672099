import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { LoadingSpinnerComponent } from './loading-spinner-component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    LoadingSpinnerComponent
  ],
  imports: [
    IonicModule,
    MatProgressSpinnerModule
  ],
  exports: [
    LoadingSpinnerComponent,
    MatProgressSpinnerModule
  ]
})
export class LoadingSpinnerComponentModule { }
