import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';

import { SharedModule } from 'src/app/modules/shared-module';
import { LoadingSpinnerComponentModule } from '../loading-spinner/loading-spinner-component.module';
import { TableComponent } from './table-component';
import { TableToolbarComponent } from './table-toolbar/table-toolbar-component';
import { TableFilterComponent } from './table-toolbar/table-filter/table-filter-component';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { ColumnOptionsComponentModule } from '../column-options/column-options.component.module';

import { EditableComponent } from './editable/editable.component';
import { EditModeDirective } from './editable/edit-mode.directive';
import { ViewModeDirective } from './editable/view-mode.directive';

@NgModule({
  declarations: [
    TableComponent,
    TableToolbarComponent,
    TableFilterComponent,
    EditableComponent,
    EditModeDirective,
    ViewModeDirective
  ],
  imports: [
    FormsModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatTooltipModule,
    MatInputModule,
    MatMenuModule,
    MatChipsModule,
    NgxMatSelectSearchModule,
    OwlDateTimeModule,
    DragToSelectModule,
    DragDropModule,
    SharedModule,
    LoadingSpinnerComponentModule,
    ColumnOptionsComponentModule,
    MatProgressBarModule
  ],
  exports: [
    TableComponent,
    TableToolbarComponent,
    TableFilterComponent
  ]
})
export class TableComponentModule { }
