import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TableComponentModule } from 'src/app/components/table/table-component-module';

import { SharedModule } from 'src/app/modules/shared-module';
import { EmpContractListComponent } from './emp-contract-list/emp-contract-list-component';
import { UpdateUserEmpContractDialogComponent } from './update-user-emp-contract-dialog/update-user-emp-contract-dialog-component';

@NgModule({
    declarations: [
        EmpContractListComponent,
        UpdateUserEmpContractDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
        NgxMatSelectSearchModule,
        OwlDateTimeModule,
        SharedModule,
        TableComponentModule
    ],
    exports: [
        EmpContractListComponent,
        UpdateUserEmpContractDialogComponent
    ]
})
export class UserEmploymentContractModule { }
