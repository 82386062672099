import { Injectable, Inject } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { HttpGateway } from '@fliq/service-library';

@Injectable()
export class PdfService {

    constructor(@Inject('BASE_URL') private BASE_URL: string, private httpGateway: HttpGateway) { }

    getJobTablePDF(
        nodeId: number,
        nodeType: string,
        startDate: string,
        endDate: string,
        page: number,
        limit: number,
        sortCol: string,
        sortOrder: string,
        filters: any,
        jobType: string,
        closedOnly: any,
        jobNumber: any,
        status: any,
        openOnly: any,
        description: string,
        ref: any,
        assignedTo: any,
        mode: any,
        includeNew: any,
        internalOrg: any,
        customerOrg: any,
        parentJobId: any
    ): Observable<any> {

        const searchParams: HttpParams = new HttpParams()
            .append('id', nodeId as any)
            .append('start_date', startDate)
            .append('end_date', endDate)
            .append('node_type', nodeType)
            .append('page', page as any)
            .append('limit', limit as any)
            .append('sidx', sortCol)
            .append('sord', sortOrder)
            .append('filters', filters)
            .append('_search', '')
            .append('so_type', jobType)
            .append('closed_only', closedOnly)
            .append('so_number', jobNumber)
            .append('status', status)
            .append('open_only', openOnly)
            .append('desc', description)
            .append('ref', ref)
            .append('assigned_to', assignedTo)
            .append('mode', mode)
            .append('incl_new', includeNew)
            .append('int_org', internalOrg)
            .append('cust_org', customerOrg)
            .append('parent_so_id', parentJobId);
        return this.httpGateway.get(`${this.BASE_URL}/pdf/pdf_rpt_so`, { params: searchParams });
    }

    getJobPDF(rows: any[], startDate = '', endDate = ''): Observable<any> {
        let params: HttpParams = new HttpParams()
            .append('start_date', startDate)
            .append('end_date', endDate);

        if (rows.length > 0) {
            for (const row of rows) {
                params = params.append('rows[]', row);
            }
        }
        return this.httpGateway.post(`${this.BASE_URL}/pdf/job_referral`, params);
    }

    getShipListPDF(
        harbourId: number,
        nodeId: number,
        nodeType: string,
        startDate: string,
        endDate: string,
        page: number,
        limit: number,
        sortCol: string,
        sortOrder: string,
        filters: any,
        jobType: string,
        closedOnly: any,
        jobNumber: any,
        status: any,
        openOnly: any,
        description: string,
        ref: any,
        assignedTo: any,
        mode: any,
        includeNew: any,
        internalOrg: any,
        customerOrg: any,
        parentJobId: any
    ): Observable<any> {
        const searchParams: HttpParams = new HttpParams()
            .append('harbour_id', harbourId as any)
            .append('id', nodeId as any)
            .append('start_date', startDate)
            .append('end_date', endDate)
            .append('node_type', nodeType)
            .append('page', page as any)
            .append('limit', limit as any)
            .append('sidx', sortCol)
            .append('sord', sortOrder)
            .append('filters', filters)
            .append('_search', '')
            .append('so_type', jobType)
            .append('closed_only', closedOnly)
            .append('so_number', jobNumber)
            .append('status', status)
            .append('open_only', openOnly)
            .append('desc', description)
            .append('ref', ref)
            .append('assigned_to', assignedTo)
            .append('mode', mode)
            .append('incl_new', includeNew)
            .append('int_org', internalOrg)
            .append('cust_org', customerOrg)
            .append('parent_so_id', parentJobId);
        return this.httpGateway.get(`${this.BASE_URL}/pdf/pdf_rpt_ship_list`, { params: searchParams });
    }

    getVoyageSinglePdf(voyageId: number): Observable<any> {
        const params: HttpParams = new HttpParams().append('voyage_id', voyageId as any);
        return this.httpGateway.get(`${this.BASE_URL}/pdf/pdf_rpt_single_voyage`, { params });
    }

    getShiftInfoPdf(orgId: any, date: any, shiftId: any, nodeOrgId: any): Observable<any> {
        const params: HttpParams = new HttpParams()
            .append('org_id', orgId)
            .append('date', date)
            .append('shift_id', shiftId)
            .append('node_org_id', nodeOrgId);
        return this.httpGateway.get(`${this.BASE_URL}/pdf/pdf_shift_day`, { params });
    }

    getShiftSummaryPdf(orgId: any, date: any, shiftId: any, nodeOrgId: any): Observable<any> {
        const params: HttpParams = new HttpParams()
            .append('org_id', orgId)
            .append('date', date)
            .append('shift_id', shiftId)
            .append('node_org_id', nodeOrgId);
        return this.httpGateway.get(`${this.BASE_URL}/pdf/pdf_shift_summary`, { params });
    }

    getShiftStaffList(orgId: any, date: any, shiftId: any, nodeOrgId: any, teams: any[]): Observable<any> {
        let params: HttpParams = new HttpParams()
            .append('org_id', orgId)
            .append('date', date)
            .append('shift_id', shiftId)
            .append('node_org_id', nodeOrgId);

        if (teams.length > 0) {
            for (const team of teams) {
                params = params.append('teams[]', team);
            }
        }
        return this.httpGateway.get(`${this.BASE_URL}/pdf/pdf_shift_staff_list`, { params });
    }

    getShiftSupervisorsPdf(orgId: any, date: any, shiftId: any, nodeOrgId: any): Observable<any> {
        const params: HttpParams = new HttpParams()
            .append('org_id', orgId)
            .append('date', date)
            .append('shift_id', shiftId)
            .append('node_org_id', nodeOrgId);
        return this.httpGateway.get(`${this.BASE_URL}/pdf/pdf_shift_supervisors`, { params });
    }

    getJobSummaryPDF(columns: any[], rows: any[]): Observable<any> {
        let params: HttpParams = new HttpParams();

        if (columns.length > 0) {
            for (const col of columns) {
                params = params.append('columns[]', JSON.stringify(col));
            }
        }
        if (rows.length > 0) {
            for (const row of rows) {
                params = params.append('rows[]', JSON.stringify(row));
            }
        }
        return this.httpGateway.post(`${this.BASE_URL}/pdf/pdf_job_queue_summary`, params);
    }
}
