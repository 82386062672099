import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LanguagePipe } from 'src/app/pipes/language-pipe';

@Component({
    templateUrl: './update-user-qualification-dialog-component.html'
})
export class UpdateUserQualificationDialogComponent implements OnInit {

    qualification: any;
    name: any;
    type = '';
    qualificationForm: FormGroup;
    grades: any[] = [];
    loading: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<UpdateUserQualificationDialogComponent>,
        langPipe: LanguagePipe
    ) {
        this.qualification = data.qualification;
        this.name = data.qualification.name;
        this.type = data.type === 'add' ? langPipe.transform(13) : langPipe.transform(297);
    }

    ngOnInit(): void {
        this.getGrades();
        this.initForm();
        this.loading = false;
    }

    initForm(): void {
        this.qualificationForm = this.fb.group({
            select: this.qualification.checkbox ? this.qualification.checkbox : '',
            attainDate: this.qualification.date_attained ? new Date(this.qualification.date_attained) : '',
            grade: this.qualification ? this.qualification.grade : '',
            renewalDate: this.qualification.next_renewal_date ? new Date(this.qualification.next_renewal_date) : ''
        });
        if (!this.qualificationForm.controls.select.value || this.qualificationForm.controls.select.value === '') {
            this.qualificationForm.controls.attainDate.disable();
            this.qualificationForm.controls.grade.disable();
            this.qualificationForm.controls.renewalDate.disable();
        }
    }

    getGrades(): void {
        for (let i = 1; i < 6; i++) {
            this.grades.push(i.toString());
        }
    }

    changeForm(event: any): void {
        // disable form when checkbox is false
        if (!event.checked) {
            this.qualificationForm.controls.attainDate.disable();
            this.qualificationForm.controls.grade.disable();
            this.qualificationForm.controls.renewalDate.disable();
            this.qualificationForm.reset();
        }
        else { this.qualificationForm.enable(); }
    }

    submitForm(data: any): void {
        if (this.qualificationForm.invalid) { return; }
        this.dialogRef.close(data);
    }

}
