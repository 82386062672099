import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
    selector: 'add-tag-dialog',
    templateUrl: './add-tag-dialog-component.html',
    styleUrls: ['./add-tag-dialog-component.scss']
})
export class AddTagDialogComponent implements OnInit {

    header: string;
    type: string;
    tagForm: FormGroup;

    constructor(private dialogRef: MatDialogRef<AddTagDialogComponent>, private fb: FormBuilder) {
        this.header = 'tag';
    }
    ngOnInit(): void {
        this.InitNewForm();
    }
    InitNewForm(): void {
        this.tagForm = this.fb.group({
            tagName: '',
            tagMac: ''
        });
    }
    cancel(): void {
        this.dialogRef.close();
    }
    submitForm(): void {
        this.dialogRef.close(this.tagForm.value);
    }
}
