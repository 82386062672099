import { Component, OnInit, OnDestroy, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { ViewDidEnter } from '@ionic/angular';

import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { IMqttServiceOptions, MqttService } from 'ngx-mqtt';

import { SessionModel, AuthService, LanguageService, AppStateModel, AccountService, ModuleParamsService } from '@fliq/service-library';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog-component';
import { AccessRequestDialogComponent } from '../access-request-dialog/access-request-dialog-component';
import { TreeviewModel } from '../treeview/treeview-model';

@Component({
    selector: 'app-base',
    templateUrl: './base-component.html',
    styleUrls: ['./base-component.scss']
})
export class BaseComponent implements OnInit, AfterViewInit, OnDestroy, ViewDidEnter {

    lang: string;
    subscriptions: Subscription[] = [];
    nodeButtons: any[] = [];
    logoUrl: string;
    showBreadcrumbNav = false;
    showOrgTree = true;
    loadingTreeview = true;

    @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer;

    public get orgTreeIconColor(): string {
        return this.showOrgTree ? 'primary' : '';
    }

    constructor(
        private router: Router,
        private dialog: MatDialog,
        @Inject('BASE_URL') private BASE_URL: string,
        private langService: LanguageService,
        public session: SessionModel,
        private authService: AuthService,
        private appState: AppStateModel,
        private treeView: TreeviewModel,
        private accountService: AccountService,
        private mqttService: MqttService,
        private moduleParams: ModuleParamsService
    ) { }

    ngOnInit(): void {
        this.lang = this.langService.lang;
        this.subscriptions.push(this.langService.langChange
            .subscribe(
                res => this.lang = res
            ));
        this.treeView.treeviewInitialized
            .pipe(first(init => init))
            .subscribe(() => {
                this.loadingTreeview = false;
            });

        this.setTheme();
        this.getAccountDetails();   // get logo image url
        this.initMqttConnection();
        this.requestAccess();

        this.showBreadcrumbNav = this.session.domainParamsModel.isActiveDomainParam('breadcrumb_nav');

        this.subscriptions.push(
            this.router.events
                .pipe(
                    filter(e => e instanceof NavigationStart)
                ).subscribe((e: NavigationStart) => {
                    if (e.url.endsWith('/admin')) {
                        this.hideTreeview();
                    }
                })
        );

    }

    ngAfterViewInit(): void {
        this.subscriptions.push(this.appState.selectedNodePathChange.subscribe((name: string) => {
            // separate nodes from node path
            const nodes = name.split(' | ');
            this.nodeButtons = [];
            for (const nodeName of nodes) {
                const foundNode = this.treeView.treeModel.getNodeBy(node => node.data.li_attr.name === nodeName)?.data;
                if (foundNode) {
                    this.nodeButtons.push(foundNode);
                }
            }
        }));
    }

    ionViewDidEnter(): void {
        this.sidenavContainer.updateContentMargins();
    }

    toggleTreeview(): void {
        this.showOrgTree = !this.showOrgTree;
        setTimeout(() => {
            this.appState.treeviewToggled();
        }, 100);
    }

    hideTreeview(): void {
        if (this.showOrgTree) {
            this.toggleTreeview();
        }
    }

    logout(): void {
        const confirmDialog: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
            data: {
                header: `Logout`,
                text: `Are you sure?`,
                cancelText: `Cancel`,
                confirmText: `Ok`
            }
        });
        confirmDialog.afterClosed().subscribe(confirm => {
            if (confirm) {
                this.authService.logout()
                    .subscribe(
                        () => {
                            this.treeView.teardown();
                            this.router.navigateByUrl(this.appState.getLoginPage());
                        }
                    );
            }
        });
    }

    requestAccess(): void {
        if (this.session.user.firstLogin) {
            this.moduleParams.getModuleParamValue(369).subscribe((value) => {
                if (value === '1') {
                    this.dialog.open(AccessRequestDialogComponent, {
                        width: '500px',
                        data: {
                            header: this.langService.getLangTextByTagId(4162)
                        }
                    });
                }
            }
            );
        }
    }

    setTheme(): void {
        const classList: any[] = Array.from(document.body.classList);
        classList.forEach(className => {
            if (className.startsWith('fliq-theme')) {
                document.body.classList.remove(className);
            }
        });
        const theme = this.session.user.theme ? this.session.user.theme : 'light';
        document.body.classList.add('fliq-theme-' + theme);
    }

    goToUserSetting(): void {
        this.router.navigateByUrl('/main/preferences');
    }

    goToHelp(): void {
        this.router.navigateByUrl('/main/preferences/version');
    }

    changeNode(node: any): void {
        this.treeView.selectNodeByLiAttr(node.li_attr.node_id, node.li_attr.type);
    }

    getAccountDetails(): void {
        this.accountService.getAccountDetails().subscribe(
            res => {
                this.logoUrl = this.BASE_URL.substring(0, this.BASE_URL.lastIndexOf('/')) + '/files/' + res.ffold + '/logo/toolbar.png';
            }
        );
    }

    onLogoError(): void {
        const el = document.getElementsByTagName('mat-toolbar')[0];
        const color = window.getComputedStyle(el).backgroundColor;
        const rgb = color.replace(/[^\d,]/g, '').split(',');
        const o = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) / 1000);

        if (o > 125) {
            this.logoUrl = `assets/icon/FLIQ-logo-grey.png`;
        } else {
            this.logoUrl = `assets/icon/FLIQ-logo-white.png`;
        }
    }

    initMqttConnection(): void {
        if (this.session.domainParamsModel.getDomainParamByParamId(87).value === '1') {
            const mqttOptions: IMqttServiceOptions = this.session.domainParamsModel.getDomainParamsByType('mqtt')
                ?.reduce((acc, curr) => Object.assign(acc, { [curr.name]: curr.value }), {});

            this.mqttService.connect({
                port: mqttOptions.port,
                hostname: mqttOptions.hostname,
                protocol: mqttOptions.protocol,
                username: mqttOptions.username,
                password: mqttOptions.password
            });
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

}
