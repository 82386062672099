<h2 mat-dialog-title>
    {{332 | langTag}} -
    <span *ngIf="!showAllUsers">{{team.name}}</span>
    <span *ngIf="showAllUsers">{{10 | langTag}}</span>
</h2>
<mat-dialog-content>
    <div class="checkbox-wrapper">
        <mat-checkbox [(ngModel)]="showAllUsers" (ngModelChange)="setAvailableUsers()">
            {{2318 | langTag}}
        </mat-checkbox>
    </div>
    <div fxLayout="row" fxLayoutGap="20px" style="height: 400px">
        <div fxLayout="column" fxFlex>
            <p>{{613 | langTag}}</p>
            <mat-form-field>
                <input #userSearch matInput placeholder="{{152 | langTag}}"
                    (keyup)="searchAvailableUser(userSearch.value)">
            </mat-form-field>
            <div class="user-list-container">
                <mat-selection-list #availableUserList>
                    <mat-list-option 
                        *ngFor="let user of filteredAvailableUsers"
                        [value]="user"
                        (dblclick)="selectUser(user)"
                    >
                        {{user.name}}
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </div>
        <div fxLayout="column" fxFlex="none" fxLayoutAlign="center">
            <button mat-icon-button (click)="selectUsers()">
                <mat-icon>arrow_forward</mat-icon>
            </button>
            <button mat-icon-button (click)="deSelectUsers()">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div fxLayout="column" fxFlex>
            <p>{{614 | langTag}}</p>
            <div class="user-list-container">
                <mat-selection-list #selectedUserList>
                    <mat-list-option
                        *ngFor="let user of selectedUsers" 
                        [value]="user" 
                        (dblclick)="deSelectUser(user)"
                    >
                        {{user.name}}
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <!-- Cancel -->
    <button mat-button (click)="cancel()">{{275 | langTag}}</button>
    <!-- Save -->
    <button mat-raised-button color="accent" [disabled]="saving" (click)="save()">{{279 | langTag}}</button>
</mat-dialog-actions>