<button mat-icon-button [matMenuTriggerFor]="msgMenu" #menuTrigger="matMenuTrigger">
    <mat-icon matBadge="{{unreadMsg}}" [matBadgeHidden]="unreadMsg == 0" matBadgeColor="primary" matBadgeSize="medium">
        mail_outline</mat-icon>
</button>
<mat-menu #msgMenu="matMenu" class="msg-panel">
    <ng-template matMenuContent>
        <div style="height: 100%;" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
            <mat-toolbar color="primary">
                <span>{{643 | langTag}}</span>
                <span class="spacer"></span>
                <button mat-icon-button *ngIf="selectMode" matTooltip="{{936 | langTag}}" (click)="markSelectedMsg()">
                    <mat-icon>check_box</mat-icon>
                </button>
                <button mat-icon-button matTooltip="{{!selectMode ? (937 | langTag) : (275 | langTag)}}"
                    (click)="selectMode = !selectMode">
                    <mat-icon>select_all</mat-icon>
                </button>
                <button mat-icon-button matTooltip="{{919 | langTag}}" [matMenuTriggerFor]="msgTypesMenu">
                    <mat-icon>settings</mat-icon>
                </button>
            </mat-toolbar>
            <mat-menu #msgTypesMenu="matMenu">
                <div fxLayout="column">
                    <mat-checkbox color="primary" [checked]="msgTypes.includes('MOB')" (click)="$event.stopPropagation()"
                        (keydown)="$event.stopPropagation()" (change)="updateMsgType($event, 'MOB')">{{950 | langTag}}
                    </mat-checkbox>
                    <mat-checkbox color="primary" [checked]="msgTypes.includes('SMS')" (click)="$event.stopPropagation()"
                        (keydown)="$event.stopPropagation()" (change)="updateMsgType($event, 'SMS')">SMS</mat-checkbox>
                    <mat-checkbox color="primary" [checked]="msgTypes.includes('email')" (click)="$event.stopPropagation()"
                        (keydown)="$event.stopPropagation()" (change)="updateMsgType($event, 'email')">{{849 | langTag}}
                    </mat-checkbox>
                </div>
            </mat-menu>
            <no-data-found *ngIf="data.length == 0 && !loading" text="{{454 | langTag}}" iconName="message" iconSize="50px"
                style="margin-top: 150px; display: flex; justify-content: center;"></no-data-found>
            <mat-spinner *ngIf="loading" style="margin-top: 150px; margin-left: 250px;"></mat-spinner>
            <mat-accordion *ngIf="data.length > 0" [togglePosition]="'before'">
                <div #panelWrapper *ngFor="let row of data">
                    <mat-expansion-panel [hideToggle]="selectMode" (afterExpand)="scrollToView(panelWrapper)">
                        <mat-expansion-panel-header>
                            <mat-checkbox *ngIf="selectMode" color="primary" [checked]="true"
                                (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"
                                style="margin-right: 5%;" (change)="toggleSelect($event, row.date)">
                            </mat-checkbox>
                            <span *ngIf="selectMode" class="mat-expansion-indicator"
                                style="transform: rotate(0deg); display: flex; align-items: center;"></span>
                            <span class="mat-expansion-panel-header-title" style="align-items: center;">{{row.date}}</span>
                            <span class="mat-expansion-panel-header-description" style="align-items: center;">
                                {{row.summary}} {{938 | langTag | lowercase}}
                            </span>
                        </mat-expansion-panel-header>

                        <mat-list-item *ngFor="let msg of row.messages; let i = index"
                            [ngClass]="{'read-msg': msg.read == 1}">
                            <div fxFill fxLayout="row" style="font-weight: 600;">
                                <span>{{msg.channel}}</span>
                                <span class="spacer"></span>
                                <span>{{msg.sent}}</span>
                            </div>
                            <div fxLayout="row">
                                <pre class="msg-text">{{msg.message}}</pre>

                                <span class="spacer"></span>
                                <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1rem">
                                    <mat-checkbox 
                                        color="primary" 
                                        style="margin-top: 10px;" 
                                        [checked]="msg.read == 1"
                                        (change)="updateReadStatus($event, msg, row)"
                                    >
                                    </mat-checkbox>
                                    <!-- Navigation to message context only available for calendar events atm -->
                                    <button 
                                        *ngIf="msg.source === '354' && msg.ref_id"
                                        mat-button 
                                        color="primary"
                                        (click)="navigateToMessageEvent(msg)"
                                    >
                                        {{533 | langTag}}
                                    </button>
                                </div>
                            </div>
                            <mat-divider *ngIf="i !== row.messages.length - 1"
                                style="margin-bottom: 5px; border-color: gray;">
                            </mat-divider>
                        </mat-list-item>
                    </mat-expansion-panel>
                </div>
            </mat-accordion>
        </div>
    </ng-template>
</mat-menu>