import { NgModule } from '@angular/core';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { ColumnOptionsComponent } from './column-options.component';
import { SharedModule } from 'src/app/modules/shared-module';

@NgModule({
    declarations: [
        ColumnOptionsComponent
    ],
    imports: [
        MatDialogModule,
        MatFormFieldModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
        DragDropModule,
        NgxMatSelectSearchModule,
        SharedModule
    ],
    exports: [
        MatDialogModule,
        ColumnOptionsComponent
    ]
})
export class ColumnOptionsComponentModule { }
