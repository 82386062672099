<div fxLayout="row">
    <div *ngFor="let node of nodes; let i = index">
        <div fxLayout="row">
            <!-- Add menu trigger except root node -->
            <div *ngIf="i > 0">
                <button mat-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="nodeMenu"
                    [matMenuTriggerData]="{node: node, index: i}"
                    [ngStyle]="{'color': node.color === 'rgba(0, 0, 0, 0.54)' ? 'white' : node.color}"
                    (click)="menuTrigger.openMenu(); getNodeData(nodes[i - 1].children)">
                    <mat-icon *ngIf="node.li_attr.type === 'org'" class="node-icon">account_balance</mat-icon>
                    <mat-icon *ngIf="node.li_attr.type === 'eq'" class="node-icon">settings_applications</mat-icon>
                    <mat-icon *ngIf="node.li_attr.type === 'user_org'" class="node-icon">people</mat-icon>
                    <mat-icon *ngIf="node.li_attr.parent_type === 'user_org'" class="node-icon">person
                    </mat-icon>
                    <span class="node-name">{{node.name}}</span>
                </button>
            </div>
            <!-- Root node without menu -->
            <div *ngIf="i === 0">
                <button mat-button [ngStyle]="{'color': node.color === 'rgba(0, 0, 0, 0.54)' ? 'white' : node.color}">
                    <mat-icon *ngIf="node.li_attr.type === 'org'" class="node-icon">account_balance</mat-icon>
                    <mat-icon *ngIf="node.li_attr.type === 'eq'" class="node-icon">settings_applications</mat-icon>
                    <mat-icon *ngIf="node.li_attr.type === 'user_org'" class="node-icon">people</mat-icon>
                    <span class="node-name">{{node.name}}</span>
                </button>
            </div>
            <!-- separator -->
            <span *ngIf="node.children?.length > 0" style="margin-top: 3px;">/</span>
            <!-- Children node menu for selected organisation -->
            <div *ngIf="i === nodes.length - 1 && node.children?.length > 0" class="more-icon">
                <button mat-icon-button [matMenuTriggerFor]="childNodeMenu"
                    (click)="getNodeData(node.children, 'child')">
                    <mat-icon>more_horiz</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
<!-- Menu to choose from sibling nodes -->
<mat-menu #nodeMenu="matMenu">
    <ng-template matMenuContent let-node="node" let-index="index">
        <div *ngIf="index > 0" class="menu-content">
            <!-- Search field -->
            <mat-form-field style="width: 100%; padding: 0 16px;">
                <input matInput (click)="$event.stopPropagation()" (input)="filterOptions($event.target.value);"
                    placeholder="{{152 | langTag}}">
            </mat-form-field>
            <div *ngFor="let siblingNode of filteredNodes | async" fxLayout="row">
                <!-- Sub menu if node has child nodes -->
                <button mat-menu-item *ngIf="node.children?.length > 0" [matMenuTriggerFor]="childNodeMenu"
                    (menuOpened)="getNodeData(siblingNode.children, 'child')" (click)="changeNode(siblingNode)"
                    [ngClass]="{'selected-node': isNodeSelected(siblingNode)}" class="menu-item-button">
                    <mat-icon *ngIf="siblingNode.li_attr.type === 'org'" [ngStyle]="{'color': siblingNode.color}"
                        class="node-icon menu-org-icon">
                        account_balance
                    </mat-icon>
                    <mat-icon *ngIf="siblingNode.li_attr.type === 'eq'" [ngStyle]="{'color': siblingNode.color}"
                        class="node-icon">settings_applications
                    </mat-icon>
                    <mat-icon *ngIf="siblingNode.li_attr.type === 'user_org'" [ngStyle]="{'color': siblingNode.color}"
                        class="node-icon">people
                    </mat-icon>
                    <mat-icon *ngIf="siblingNode.li_attr.parent_type === 'user_org'"
                        [ngStyle]="{'color': siblingNode.color}" class="node-icon">person
                    </mat-icon>
                    <span class="node-name">{{siblingNode.name}}</span>
                </button>
                <!-- Normal node buttons -->
                <button mat-button *ngIf="node.children?.length === 0" (click)="changeNode(siblingNode)"
                    [ngStyle]="{'color': siblingNode.color}" [ngClass]="{'selected-node': isNodeSelected(siblingNode)}"
                    class="menu-item-button">
                    <mat-icon *ngIf="siblingNode.li_attr.type === 'org'" class="node-icon menu-org-icon">
                        account_balance
                    </mat-icon>
                    <mat-icon *ngIf="siblingNode.li_attr.type === 'eq'" class="node-icon">settings_applications
                    </mat-icon>
                    <mat-icon *ngIf="siblingNode.li_attr.type === 'user_org'" class="node-icon">people
                    </mat-icon>
                    <mat-icon *ngIf="siblingNode.li_attr.parent_type === 'user_org'" class="node-icon">person
                    </mat-icon>
                    <span class="node-name">{{siblingNode.name}}</span>
                </button>
            </div>
        </div>
    </ng-template>
</mat-menu>
<!-- Menu to choose children nodes -->
<mat-menu #childNodeMenu="matMenu">
    <ng-template matMenuContent>
        <div class="menu-content">
            <!-- Search field -->
            <mat-form-field style="width: 100%; padding: 0 16px;">
                <input matInput (click)="$event.stopPropagation()"
                    (input)="filterOptions($event.target.value, 'child');" placeholder="{{152 | langTag}}">
            </mat-form-field>
            <div *ngFor="let childNode of filteredChildNodes | async">
                <button mat-button (click)="changeNode(childNode)" [ngStyle]="{'color': childNode.color}"
                    class="menu-item-button">
                    <mat-icon *ngIf="childNode.li_attr.type === 'org'" class="node-icon menu-org-icon">
                        account_balance
                    </mat-icon>
                    <mat-icon *ngIf="childNode.li_attr.type === 'eq'" class="node-icon">
                        settings_applications
                    </mat-icon>
                    <mat-icon *ngIf="childNode.li_attr.type === 'user_org'" class="node-icon">people
                    </mat-icon>
                    <mat-icon *ngIf="childNode.li_attr.parent_type === 'user_org'" class="node-icon">person
                    </mat-icon>
                    <span class="node-name">{{childNode.name}}</span>
                </button>
            </div>
        </div>
    </ng-template>
</mat-menu>