import { Component, Inject, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList, MatListOption } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, Subscription, zip } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { TeamService, UserService } from '@fliq/service-library';
import { LanguagePipe } from 'src/app/pipes/language-pipe';
import { TreeviewModel } from 'src/app/components/treeview/treeview-model';

@Component({
    selector: 'team-members-dialog',
    templateUrl: './team-members-dialog-component.html',
    styleUrls: ['./team-members-dialog-component.scss']
})
export class TeamMembersDialogComponent implements OnInit, OnDestroy {

    availableUsers: any[] = [];
    orgUsers: any[] = [];
    allUsers: any[] = [];
    filteredAvailableUsers: any[] = [];
    selectedUsers: any[] = [];
    type = '';
    team: any;
    showAllUsers = false;
    filterTerms: Subject<string> = new Subject<string>();
    filterChangeSubscription: Subscription;
    saving = false;

    @ViewChild('availableUserList', { static: true }) availableUserList: MatSelectionList;
    @ViewChild('selectedUserList', { static: true }) selectedUserList: MatSelectionList;
    @ViewChild('userSearch', { static: true }) userSearch: ElementRef;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<TeamMembersDialogComponent>,
        private snackBar: MatSnackBar,
        private teamService: TeamService,
        private userService: UserService,
        private langPipe: LanguagePipe,
        private treeService: TreeviewModel
    ) {
        this.type = data.type;
        this.team = data.team;
    }

    ngOnInit(): void {
        this.getTeamMembers();
        this.filterChangeSubscription = this.filterTerms.pipe(
            debounceTime(200)
        ).subscribe(
            res => {
                this.filteredAvailableUsers = this.availableUsers.filter(user => user.name.toLowerCase().includes(res.toLowerCase()));
            }
        );
    }

    getUsers(): void {
        this.availableUsers = [];

        const rootOrgId = this.treeService.getRootOrganisation()?.data?.li_attr?.node_id;

        zip(
            this.teamService.getUsersForTeam(this.team.organisation_id),
            this.userService.getUsersByOrgDeep(rootOrgId)
        ).subscribe(
            ([orgUsers, allUsers]) => {
                this.orgUsers = Array.isArray(orgUsers) ?
                    orgUsers.map(u => ({ id: u.id, name: `${u.first_name} ${u.last_name}`, username: u.username })) : [{ name: `no users exist.` }];
                this.allUsers = Array.isArray(allUsers) ?
                    allUsers.map(u => ({ id: u.id, name: `${u.first_name} ${u.last_name}`, username: u.username })) : [{ name: `no users exist.` }];

                this.setAvailableUsers();
            },
            () => {
                this.snackBar.open(`${this.langPipe.transform(570)}`, 'Ok', { duration: 5000 });
            }
        );
    }

    isAllocated(id: number): boolean {
        return this.selectedUsers.some(user => user.id == id);
    }

    getTeamMembers(): void {
        this.teamService.getTeamMembers(this.team.id).subscribe(res => {
            this.selectedUsers = [];

            const members = Array.isArray(res) ? res : [];

            this.selectedUsers = members.map(m => ({ id: m.users_id, name: `${m.first_name} ${m.last_name}`, username: m.username }));

            this.getUsers();
        });
    }

    selectUser(user: any): void {
        const index: number = this.availableUsers.findIndex(availableUser => user.id == availableUser.id);
        this.availableUsers.splice(index, 1);
        this.selectedUsers.push(user);
        this.triggerUserSearch();
    }

    selectUsers(): void {
        const optionsToSelect: MatListOption[] = this.availableUserList.selectedOptions.selected;
        optionsToSelect.forEach(option => this.selectUser(option.value));
        this.availableUserList.deselectAll();
    }

    deSelectUser(user: any): void {
        const index: number = this.selectedUsers.findIndex(availableUser => user.id == availableUser.id);
        this.selectedUsers.splice(index, 1);
        this.availableUsers.push(user);
        this.triggerUserSearch();
    }

    deSelectUsers(): void {
        const optionsToDeSelect: MatListOption[] = this.selectedUserList.selectedOptions.selected;
        optionsToDeSelect.forEach(option => this.deSelectUser(option.value));
        this.selectedUserList.deselectAll();
    }

    searchAvailableUser(text: string): void {
        text = text.trim();
        this.filterTerms.next(text);
    }

    triggerUserSearch(): void {
        const event: KeyboardEvent = new KeyboardEvent('keyup', { bubbles: true });
        this.userSearch.nativeElement.dispatchEvent(event);
    }

    setAvailableUsers(): void {
        if (this.showAllUsers) {
            this.availableUsers = this.allUsers.filter(u => !this.isAllocated(u.id));
        } else {
            this.availableUsers = this.orgUsers.filter(u => !this.isAllocated(u.id));
        }

        this.filteredAvailableUsers = this.availableUsers.slice();

        this.triggerUserSearch();
    }

    save(): void {
        this.saving = true;
        let usernames: string[] = this.selectedUsers.map(user => user.username);
        // remove undefined/null values from array (no users exist option)
        usernames = usernames.filter(username => username);
        if (!usernames || !usernames.length) {
            usernames = [];
        }
        this.teamService.addTeamMember(this.team.id, usernames, this.type).subscribe(
            res => {
                this.dialogRef.close(res);
            },
            () => this.snackBar.open(`${this.langPipe.transform(2096)}`, 'Ok', { duration: 5000 })
        );
    }

    cancel(): void {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.filterChangeSubscription.unsubscribe();
    }
}
