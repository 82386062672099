import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { EmploymentContractService } from '@fliq/service-library';
import { TableRefreshEvent, TableColumnOptions, EmploymentContract } from '@fliq/datamodel-library';
import { TableComponent } from '../../../../table/table-component';
import { UpdateUserEmpContractDialogComponent } from '../update-user-emp-contract-dialog/update-user-emp-contract-dialog-component';
import { LanguagePipe } from 'src/app/pipes/language-pipe';

@Component({
    selector: 'emp-contract-list',
    templateUrl: './emp-contract-list-component.html'
})
export class EmpContractListComponent {

    contractDataSource: EmploymentContract[] = [];
    pageSize = 200;
    pageSizeOptions = [50, 100, 200, 500, 1000];
    records: number;
    columns: TableColumnOptions[] = [
        { def: 'start_date', name: 'Employment Start Date', langTag: 334, sortable: true },
        { def: 'employment_type_name', name: 'Employment Type', langTag: 861, sortable: true },
        { def: 'current_contract_start_date', name: 'Current Contract Start Date', langTag: 854, sortable: true },
        { def: 'current_contract_end_date', name: 'Current Contract End Date', langTag: 855, sortable: true },
        { def: 'daily_work_time', name: 'Daily Work Time', langTag: 856, sortable: true }
    ];

    @ViewChild(TableComponent, { static: true }) table: TableComponent;

    @Input() userId: number;

    @Input() addFromUserDialog: boolean;

    @Output() newContract: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private empContractService: EmploymentContractService,
        private langPipe: LanguagePipe
    ) { }

    refreshTable(evt: TableRefreshEvent): void {
        if (!this.addFromUserDialog) {
            this.table.loading = true;
            this.empContractService.getEmploymentContractsSam(this.userId, evt.pageSize, evt.pageIndex, evt.sortCol, evt.sortOrder, evt.filters)
                .subscribe(res => {
                    this.contractDataSource = res.rows?.map(r => ({
                        ...r,
                        start_date: r.start_date.substring(0, 10),
                        current_contract_start_date: r.current_contract_start_date.substring(0, 10),
                        current_contract_end_date: r.current_contract_end_date.substring(0, 10)}));
                    this.records = res.records;
                }).add(() => {
                    this.table.loading = false;
                });
        }
    }

    addContract(): void {
        this.addFromUserDialog = false;
        const dialogRef = this.dialog.open(UpdateUserEmpContractDialogComponent, {
            data: {
                type: 'add',
                contracts: this.contractDataSource
            }
        });
        dialogRef.afterClosed().subscribe(data => {
            if (!data) { return; }
            this.empContractService.addEmploymentContract(
                this.userId,
                data.empType,
                data.empStartDate,
                data.currentStart,
                data.currentEnd,
                data.dailyWork,
                data.salaryModel
            ).subscribe(
                res => {
                    this.emitContract(res.data, data);
                    this.table.refreshTable();
                },
                () => {
                    this.table.loading = false;
                    this.snackBar.open(`${this.langPipe.transform(2096)}`, 'Ok', { duration: 3000 });
                }
            );
        });
    }

    editContract(contract: EmploymentContract): void {
        this.addFromUserDialog = false;
        const dialogRef = this.dialog.open(UpdateUserEmpContractDialogComponent, {
            data: {
                contract,
                type: 'edit',
                contracts: this.contractDataSource
            }
        });
        dialogRef.afterClosed().subscribe(res => {
            if (!res) { return; }
            this.updateContract(res, contract.id);
        });
    }

    updateContract(data: any, contractId: number): void {
        this.table.loading = true;
        this.empContractService.updateEmploymentContract(
            contractId,
            this.userId,
            data.empType,
            data.empStartDate,
            data.currentStart,
            data.currentEnd,
            data.dailyWork,
            data.salaryModel
        ).subscribe(
            () => {
                this.emitContract(contractId, data);
                this.table.refreshTable();
            },
            () => {
                this.table.loading = false;
                this.snackBar.open(`${this.langPipe.transform(2096)}`, 'Ok', { duration: 3000 });
            }
        );
    }

    private emitContract(id: number, data: any): void {
        if (this.userId === -1) {
            this.newContract.emit({ id, data });
        }
    }

    deleteContract(contract: any): void {
        this.empContractService.deleteEmploymentContract(this.userId, contract.id)
            .subscribe(
                () => this.table.refreshTable()
            );
    }
}
