import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable, ReplaySubject, Subscription, zip } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FilterService, ModuleParamsModel, TypeService } from '@fliq/service-library';
import { EmploymentContract } from '@fliq/datamodel-library';
import { LanguagePipe } from 'src/app/pipes/language-pipe';

@Component({
    templateUrl: './update-user-emp-contract-dialog-component.html'
})
export class UpdateUserEmpContractDialogComponent implements OnInit {

    contract: any;
    type = '';
    empForm: FormGroup;
    empTypes = [];
    filteredTypes = new ReplaySubject<any[]>(1);
    salaryModels = [];
    filteredSalaryModels = new ReplaySubject<any[]>(1);
    subscriptions: Subscription[] = [];
    loading: boolean;
    contracts: EmploymentContract[] = [];
    showSalaryModelField = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<UpdateUserEmpContractDialogComponent>,
        private snackBar: MatSnackBar,
        private typeService: TypeService,
        private filterService: FilterService,
        private langPipe: LanguagePipe,
        private moduleParams: ModuleParamsModel
    ) {
        this.contract = data.contract;
        this.type = data.type === 'add' ? langPipe.transform(13) : langPipe.transform(297);
        this.contracts = data.contracts;
    }

    ngOnInit(): void {
        this.loading = true;
        zip(
            this.moduleParams.getModuleParams(25),
            this.getSalaryModelTypes(),
            this.getEmploymentTypes()
        ).subscribe(
            () => {
                this.initForm();
                this.showSalaryModelField = this.moduleParams.getModuleParamByParamId(25, 280)?.value === '1';
                this.loading = false;
            },
            () => {
                this.loading = false;
                this.snackBar.open(`${this.langPipe.transform(570)}`, 'Ok', { duration: 5000 });
                this.dialogRef.close();
            }
        );
    }

    initForm(): void {
        this.empForm = this.fb.group({
            empStartDate: [this.contract ? new Date(this.contract.start_date) : '', Validators.required],
            empType: [this.contract ? +this.contract.employment_type_id : '', Validators.required],
            empTypeSearch: '',
            salaryModel: this.contract ? this.contract.salary_model_type : '',
            salaryModelSearch: '',
            currentStart: [this.contract ? new Date(this.contract.current_contract_start_date) : '', Validators.required],
            currentEnd: [this.contract ? new Date(this.contract.current_contract_end_date) : '', Validators.required],
            dailyWork: [this.contract ? this.contract.daily_work_time : '', [Validators.required, Validators.min(0)]]
        }, { validators: this.dateValidator('currentStart', 'currentEnd') });

        this.subscriptions.push(this.empForm.controls.empTypeSearch.valueChanges.subscribe((value) => {
            this.filteredTypes.next(this.filterService.filterArray(this.empTypes, value, 'name'));
        }));
    }

    getEmploymentTypes(): Observable<any> {
        return this.typeService.getEnums('employment_type')
            .pipe(tap(data => {
                this.empTypes = Array.isArray(data) ? data : [];
                this.filteredTypes.next(this.empTypes.slice());
            }));
    }

    getSalaryModelTypes(): Observable<any> {
        return this.typeService.getEnums('salary_model')
            .pipe(tap(data => {
                this.salaryModels = Array.isArray(data) ? data : [];
                this.filteredSalaryModels.next(this.salaryModels.slice());
            }));
    }

    dateValidator(startDateKey: string, endDateKey: string): ValidatorFn {
        return (group: FormGroup): { [key: string]: any } => {
            const startDateControl = group.controls[startDateKey];
            const endDateControl = group.controls[endDateKey];
            const startDate: Date = startDateControl.value;
            const endDate: Date = endDateControl.value;

            if (this.contracts?.length > 0) {
                for (const contract of this.contracts) {
                    if ((this.contract && contract.id != this.contract.id) || !this.contract) {
                        const contractStart = new Date(contract.current_contract_start_date);
                        const contractEnd = new Date(contract.current_contract_end_date);

                        if (
                            (contractStart <= startDate && contractEnd >= endDate) || (contractStart >= startDate && contractEnd <= endDate) ||
                            (startDate <= contractStart && endDate >= contractStart) || (startDate <= contractEnd && endDate >= contractEnd)
                        ) {
                            startDateControl.setErrors(startDateControl.errors ? { ...startDateControl.errors, invalidDate: true } : { invalidDate: true });
                            endDateControl.setErrors(endDateControl.errors ? { ...endDateControl.errors, invalidDate: true } : { invalidDate: true });
                            startDateControl.markAsTouched();
                            endDateControl.markAsTouched();
                            return {
                                invalidDate: true
                            };
                        }
                    }
                }
            }
            if (startDateControl.hasError('invalidDate')) {
                const errors = { ...startDateControl.errors };
                delete errors.invalidDate;
                startDateControl.setErrors(errors.length ? { ...errors } : null);
                startDateControl.markAsTouched();
            }
            if (endDateControl.hasError('invalidDate')) {
                const errors = { ...endDateControl.errors };
                delete errors.invalidDate;
                endDateControl.setErrors(errors.length ? { ...errors } : null);
                endDateControl.markAsTouched();
            }
            return null;
        };
    }

    submitForm(data: any): void {
        if (this.empForm.invalid) { return; }
        this.dialogRef.close(data);
    }

}
