import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { TreeComponent, TreeNode } from '@circlon/angular-tree-component';

import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { TreeviewService, SessionModel, AppStateModel, DropDownService, DomainParamsModel } from '@fliq/service-library';
import { TreeviewModel } from './treeview-model';
import { TreeNodeService } from './tree-node.service';

@Component({
    selector: 'app-tree',
    templateUrl: './treeview-component.html',
    styleUrls: ['./treeview-component.scss']
})
export class TreeviewComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('tree', { static: true }) tree: TreeComponent;
    @ViewChild('nodeSearch') nodeSearchInput: ElementRef;

    nodes: Array<any>;
    nodeSearchSubscription: Subscription;
    treeviewTypes: any[] = [];
    selectedType: number;
    initialOpenedLevel: number;

    public get isRootUser(): boolean {
        return this.session.user.role === 'admin';
    }

    constructor(
        private treeViewService: TreeviewService,
        private treeviewModel: TreeviewModel,
        private appState: AppStateModel,
        private domainParams: DomainParamsModel,
        private session: SessionModel,
        private ddService: DropDownService,
        private treeNodeService: TreeNodeService
    ) { }

    ngOnInit(): void {
        this.getTreeviewTypes();
        this.treeviewModel.treeModel = this.tree.treeModel;
        this.initialOpenedLevel = +this.domainParams.getDomainParamByParamName('open_level_int_org')?.value || 0;

        this.updateTreeview();

        this.treeViewService.treeviewUpdated$.subscribe(() => this.updateTreeview());
    }

    ngAfterViewInit(): void {
        this.nodeSearchSubscription = fromEvent(this.nodeSearchInput.nativeElement, 'keyup')
            .pipe(
                debounceTime(500),
                distinctUntilChanged()
            ).subscribe((event: KeyboardEvent) => {
                const value = (event.target as HTMLInputElement).value.trim().toLowerCase();

                if (value.length === 0) {
                    this.resetSearch();
                    return;
                }

                this.tree.treeModel.filterNodes((node) => {
                    const name: string = node.data.name.toLowerCase();
                    const regNo: string = node.data.li_attr.registration_number?.toLowerCase();

                    return name.includes(value) || regNo?.includes(value);
                });

            });
    }

    setNode(): void {
        let selectedTreeNode: TreeNode;
        if (this.appState.selectedNodeId) {
            selectedTreeNode = this.treeviewModel.treeModel.getNodeBy((node: TreeNode) => node.data.li_attr.node_id === this.appState.selectedNode.id);
            if (selectedTreeNode) {
                this.treeviewModel.selectNodeByLiAttr(this.appState.selectedNodeId, this.appState.selectedNodeType);
            }
        } else if (this.session.user.selectedNodeId) {
            selectedTreeNode = this.tree.treeModel.getNodeBy(node => node.data.li_attr.node_id === this.session.user.selectedNodeId);
            if (selectedTreeNode) {
                this.treeviewModel.selectNodeByLiAttr(this.session.user.selectedNodeId, this.session.user.selectedNodeType);
            }
        }
        // select root node if the last selected node is hidden after changing treeview type
        if (!selectedTreeNode) {
            this.treeviewModel.selectNode(this.tree.treeModel.getFirstRoot());
        }
    }

    selectNode(node: TreeNode): void {
        const selectedTreeNode = this.tree.treeModel.getNodeBy(rec => rec.data.li_attr.node_id === node.data.li_attr.node_id);
        if (!selectedTreeNode) {
            this.treeviewModel.selectNode(this.tree.treeModel.getFirstRoot());
        } else {
            this.treeviewModel.selectNode(node);
        }
    }

    resetSearch(): void {
        const activeNode: TreeNode = this.tree.treeModel.getFocusedNode();

        this.tree.treeModel.collapseAll();
        this.tree.treeModel.clearFilter();

        setTimeout(() => {
            activeNode.ensureVisible();
        }, 0);
    }

    modifyNode(node: TreeNode | any): void {
        node.id = node.li_attr.id;
        node.name = node.li_attr.name;
        node.level = node.li_attr.level;

        if (node.state) {
            if (node.state.opened || node.state.selected) {
                node.isExpanded = true;
            }
        }
        if (node.children) {
            node.children.map(el => this.modifyNode(el));
        }
    }

    onDragStart(evt: DragEvent, node: any): void {
        evt.dataTransfer.setData('data', JSON.stringify(node.data.li_attr));
    }

    updateTreeview(): void {
        this.treeviewModel.getTreeNodes(this.selectedType)
            .subscribe(
                data => {
                    // Call collapse all to prevent "Loading.." text to hang after deleting user
                    if (this.tree?.treeModel?.nodes) {
                        this.tree.treeModel.collapseAll();
                    }

                    this.treeNodeService.initAssetSubscriptions(data);
                    this.treeviewModel.treeNodes = data;
                    this.nodes = this.treeviewModel.treeNodes;
                    this.treeviewModel.treeNodes.map(node => this.modifyNode(node));
                    this.treeviewModel.selectedTreeviewType = this.selectedType;
                    // select the active node from tree
                    setTimeout(() => {
                        this.setNode();
                        const focusedNode = this.treeviewModel.treeModel.getFocusedNode();
                        if (focusedNode?.isRoot) {
                            focusedNode.expand();
                        }
                        // expand nodes that are in higher levels than value specified in corresponding domain param
                        this.tree.treeModel.doForAll((node: TreeNode) => {
                            if (node.level <= this.initialOpenedLevel) {
                                node.expand();
                            }
                        });

                        this.treeviewModel.treeviewInitialized.next(true);
                    }, 0);
                },
                error => alert(error)
            );
    }

    getTreeviewTypes(): void {
        this.ddService.getDropDownList('treeview_type')
            .subscribe(data => {
                if (data) {
                    data.forEach(type => type.id = +type.id);
                    this.treeviewTypes = data;
                    if (this.appState.selectedTreeviewType) {
                        this.selectedType = +this.appState.selectedTreeviewType;
                    } else {
                        this.selectedType = this.treeviewTypes[0].id;
                    }
                    this.updateTreeview();
                }
            });
    }

    changeTreeviewType(type: any): void {
        this.selectedType = type;
        this.treeViewService.setSelectedTreeviewType(type).subscribe();
        this.updateTreeview();
    }

    ngOnDestroy(): void {
        this.nodeSearchSubscription?.unsubscribe();
        this.treeNodeService.teardown();
    }

}
