<button mat-icon-button matTooltip="{{878 | langTag}}" [matMenuTriggerFor]="filterMenu">
    <mat-icon>filter_list</mat-icon>
</button>
<mat-menu #filterMenu="matMenu" backdropClass="filter-menu">
    <div *ngIf="!loading" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <div class="filter-menu-header">
            <h4 class="filter-menu-title">{{878 | langTag}}</h4>
            <button mat-button color="primary" (click)="resetFilters();">{{402 | langTag}}</button>
            <span class="spacer"></span>
            <button mat-icon-button type="button" matTooltip="{{374 | langTag}}" (click)="closeFilterPanel()"  e2e-id="close_filter_button">
                <mat-icon color="warn">clear</mat-icon>
            </button>
        </div>
        <form class="filter-form" [formGroup]="filterForm">
            <div formArrayName="filterFields" fxLayout="column">
                <div *ngFor="let filterGroup of filterForm.get('filterFields').controls; let i = index">
                    <div [formGroupName]="i" fxLayout="row" fxLayoutGap="16px"
                        fxLayoutAlign="start center">
                        <mat-form-field>
                            <mat-select placeholder="{{166 | langTag}}" formControlName="field" (selectionChange)="updateFilter(i, $event)">
                                <mat-option>
                                    <ngx-mat-select-search formControlName="fieldSearch"
                                        placeholderLabel="{{152 | langTag}}" noEntriesFoundLabel="{{578 | langTag}}">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let field of filteredColumns[i] | async" [value]="field.def">
                                    {{field.langTag | langTag}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="filterGroup.get('field').hasError('required')">
                                {{2048 | langTag}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-select placeholder="{{840 | langTag}}" formControlName="operator" (selectionChange)="updateFilter(i, $event)">
                                <mat-option>
                                    <ngx-mat-select-search formControlName="operatorSearch"
                                        placeholderLabel="{{152 | langTag}}" noEntriesFoundLabel="{{578 | langTag}}">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let oper of filteredOperators[i] | async" [value]="oper.strparam">
                                    {{oper.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="filterGroup.get('operator').hasError('required')">
                                {{2048 | langTag}}
                            </mat-error>
                        </mat-form-field>
                        <ng-container>
                            <mat-form-field *ngIf="filterGroup.get('valueType').value !== 'date' && filterGroup.get('valueType').value !== 'datetime'"
                                class="table-filter-field" [ngSwitch]="filterGroup.get('valueType').value">
                                <mat-label>{{1005 | langTag}}</mat-label>

                                <input autocomplete="off" #input [attr.name]="getGroupFieldName(i)"
                                    formControlName="value" *ngSwitchCase="'input'" matInput
                                    (input)="updateFilter(i, $event.target.value)"
                                    [attr.e2e-id]="'filter_input_'+getGroupFieldName(i)">

                                <mat-select #select formControlName="value" *ngSwitchCase="'multiSelect'" multiple
                                    (selectionChange)="updateFilter(i, $event)"
                                    [attr.e2e-id]="'filter_sel_'+getGroupFieldName(i)">
                                    <mat-option e2e-id="filter_search">
                                        <ngx-mat-select-search placeholderLabel="{{152 | langTag}}"
                                            formControlName="{{getGroupFieldName(i)}}Search"
                                            noEntriesFoundLabel="{{578 | langTag}}">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let data of colDdFilteredData[getGroupFieldName(i)] | async"
                                        [value]="data.name" [attr.e2e-id]="getGroupFieldName(i)+'_'+data.name">
                                        {{data.name}}
                                    </mat-option>
                                </mat-select>

                                <mat-select #select formControlName="value" *ngSwitchCase="'selectOne'"
                                    (selectionChange)="updateFilter(i, $event)">
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel="{{152 | langTag}}"
                                            formControlName="{{getGroupFieldName(i)}}Search"
                                            noEntriesFoundLabel="{{578 | langTag}}">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let data of colDdFilteredData[getGroupFieldName(i)] | async"
                                        [value]="data.id">
                                        {{data.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field *ngIf="filterGroup.get('valueType').value === 'date' || filterGroup.get('valueType').value === 'datetime'"
                                [owlDateTimeTrigger]="filterDatepicker" class="owl-datepicker-input-field">
                                <mat-label>{{1005 | langTag}}</mat-label>
                                <input #dateInput formControlName="value" matInput [owlDateTime]="filterDatepicker"
                                    (dateTimeChange)="updateFilter(i, $event)">
                                <owl-date-time #filterDatepicker [pickerType]="'calendar'"></owl-date-time>
                                <mat-icon matSuffix class="datepicker-suffix-icon">today</mat-icon>
                            </mat-form-field>
                        </ng-container>
                        <button mat-icon-button type="button" matTooltip="{{277 | langTag}}" (click)="removeFilter(i)">
                            <mat-icon color="warn">clear</mat-icon>
                        </button>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center">
                    <button mat-button color="primary" type="button" (click)="addFilter()">
                        {{2191 | langTag}}
                    </button>
                </div>
            </div>
        </form>
    </div>
</mat-menu>