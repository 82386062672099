import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
    selector: 'add-project-dialog',
    templateUrl: './add-project-dialog-component.html',
    styleUrls: ['./add-project-dialog-component.scss']
})
export class AddProjectDialogComponent implements OnInit {

    header: string;
    type: string;
    projectForm: FormGroup;

    constructor(private dialogRef: MatDialogRef<AddProjectDialogComponent>, private fb: FormBuilder) {
        this.header = 'project';
    }
    ngOnInit(): void {
        this.InitNewForm();
    }
    InitNewForm(): void {
        this.projectForm = this.fb.group({
            projectName: ''
        });
    }
    cancel(): void {
        this.dialogRef.close();
    }
    submitForm(): void {
        this.dialogRef.close(this.projectForm.value);
    }
}
