import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';

import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';

import { TableComponentModule } from 'src/app/components/table/table-component-module';
import { SharedModule } from 'src/app/modules/shared-module';
import { QualificationListComponent } from './qualification-list/qualification-list-component';
import { UpdateUserQualificationDialogComponent } from './update-user-qualification-dialog/update-user-qualification-dialog-component';

@NgModule({
    declarations: [
        QualificationListComponent,
        UpdateUserQualificationDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        OwlDateTimeModule,
        MatCheckboxModule,
        SharedModule,
        TableComponentModule
    ],
    exports: [
        QualificationListComponent,
        UpdateUserQualificationDialogComponent
    ]
})
export class UserQualificationsModule { }
