import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'treenode-menu',
    templateUrl: './treenode-menu-component.html',
    styleUrls: ['./treenode-menu-component.scss']
})
export class TreenodeMenuComponent implements OnInit, OnDestroy {

    filterChangeSubscription: Subscription;
    filterTerms: Subject<any> = new Subject<any>();
    nodeData: any[] = [];
    filteredNodes: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    filteredChildNodes: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    @Input() nodes: any;
    @Output() nodeSelected: EventEmitter<any> = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
        this.filterChangeSubscription = this.filterTerms.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(
            res => {
                if (res.type === 'parent') {
                    this.filteredNodes.next(this.nodeData.filter(node => node.name.toLowerCase().includes(res.text)));
                } else {
                    this.filteredChildNodes.next(this.nodeData.filter(node => node.name.toLowerCase().includes(res.text)));
                }
            }
        );
    }

    filterOptions(text: string, type = 'parent'): void {
        text = text.trim().toLowerCase();
        this.filterTerms.next({ text, type });
    }

    isNodeSelected(node: any): boolean {
        return this.nodes.find(rec => rec.id === node.id) ? true : false;
    }

    changeNode(node: any): void {
        this.nodeSelected.emit(node);
    }

    getNodeData(nodes: any[], type = 'parent'): void {
        this.nodeData = nodes;
        type === 'parent' ? this.filteredNodes.next(this.nodeData) : this.filteredChildNodes.next(this.nodeData);
    }

    ngOnDestroy(): void {
        this.filterChangeSubscription.unsubscribe();
    }
}
