import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatMenuTrigger } from '@angular/material/menu';

import { Subscription } from 'rxjs';

import { MqttService } from 'ngx-mqtt';

import { MessageService, SessionModel } from '@fliq/service-library';

interface Message {
    channel: string;
    email_id: string;
    message: string;
    read: string;
    ref_id: string;
    sent: string;
    sms_id: string;
    source: string;
    status: string;
    to_name: string;
}

@Component({
    selector: 'messages-panel',
    templateUrl: './messages-panel-component.html',
    styleUrls: ['./messages-panel-component.scss']
})
export class MessagesPanelComponent implements OnInit, AfterViewInit, OnDestroy {
    data: any[] = [];
    loading = false;
    msgTypes: any[] = ['MOB', 'SMS', 'email'];
    unreadMsg: number;
    subscriptions: Subscription[] = [];
    records: number;
    selectMode = false;
    dates: any[] = [];

    private get useMqtt(): boolean {
        return this.session.domainParamsModel.getDomainParamByParamId(87).value === '1';
    }

    @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

    constructor(
        private cd: ChangeDetectorRef,
        private router: Router,
        private messageService: MessageService,
        private mqttService: MqttService,
        private session: SessionModel
    ) { }

    ngOnInit(): void {
        this.getMessageDates();
        this.getNumberOfUnreadMsg();
    }

    ngAfterViewInit(): void {
        this.subscriptions.push(this.messageService.msgStatusUpdated.subscribe(() => this.getNumberOfUnreadMsg()));

        if (this.useMqtt) {
            this.subscriptions.push(this.mqttService.observe(`/${this.session.user.domainKey}/user/${this.session.user.id}/message`)
                .subscribe(() => {
                    this.getNumberOfUnreadMsg();
                    this.getMessageDates();
                }));
        }
    }

    getMessageDates(): void {
        this.loading = true;
        this.dates = [];

        this.messageService.getMessagePanelDates(this.msgTypes).subscribe(data => {
            if (data) {
                this.data = data.rows;
                this.records = data.records;
                for (const row of this.data) {
                    this.dates.push(row.date);
                }
            } else {
                this.data = [];
                this.records = 0;
                this.dates = [];
            }
        }).add(() => this.loading = false);
    }

    getNumberOfUnreadMsg(): void {
        this.messageService.getNumberOfUnreadMsg()
            .subscribe(data => this.unreadMsg = data)
            .add(() => this.cd.detectChanges());
    }

    updateMsgType(evt: any, type: string): void {
        const index = this.msgTypes.indexOf(type);

        if (evt.checked && index === -1) {
            this.msgTypes.push(type);
        } else {
            this.msgTypes.splice(index, 1);
        }

        this.getMessageDates();
    }

    updateReadStatus(evt: any, msg: Message, date: any): void {
        if (!this.selectMode) {
            date.summary = evt.checked ? date.summary - 1 : date.summary + 1;
            msg.read = evt.checked ? '1' : '0';

            const msgId = msg.email_id ? msg.email_id : msg.sms_id;

            this.messageService.updateMsgReadStatus(msgId as any, evt.checked, msg.channel).subscribe(() => {
                this.messageService.msgStatusUpdated.next();
            });
        }
    }

    markSelectedMsg(): void {
        this.selectMode = false;
        this.messageService.markMsgAsRead(this.dates, this.msgTypes).subscribe(() => {
            this.messageService.msgStatusUpdated.next();
            this.getMessageDates();
        });
    }

    toggleSelect(evt: any, date: any): void {
        const index = this.dates.indexOf(date);
        if (!evt.checked && index > -1) {
            this.dates.splice(index, 1);
        }
        else if (evt.checked && index === -1) {
            this.dates.push(date);
        }
    }

    scrollToView(panelWrapper: HTMLElement): void {
        panelWrapper.scrollIntoView({ behavior: 'smooth' });
    }

    navigateToMessageEvent(msg: Message): void {
        switch (msg.source) {
            // General messages (calendar events have ref_id)
            case '354':
                if (msg.ref_id) {
                    this.router.navigateByUrl(this.session.getRoute('calendar'), {
                        state: {
                            action: {
                                type: 'open',
                                targetId: msg.ref_id
                            }
                        }
                    });
                    this.menuTrigger.closeMenu();
                }
                break;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
