import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import {
  SessionModel,
  DomainParamsModel,
  AppStateModel,
  ModuleAccessService
} from '@fliq/service-library';
import { DomainParam, Functionality } from '@fliq/datamodel-library';
import { TreeviewModel } from '../treeview/treeview-model';

@Component({
  selector: 'app-nav',
  templateUrl: './navigation-component.html',
  styleUrls: ['./navigation-component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {

  activeModulePath: string;
  private defaultModuleParam: DomainParam;
  private activeModuleChangeSubscription: Subscription;
  treeviewReady = false;

  get modules(): Functionality[] {
    return this.session.modules?.filter(module => module.param_type === 'module') || [];
  }

  constructor(
    private session: SessionModel,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private appState: AppStateModel,
    private domainParams: DomainParamsModel,
    private modAccessService: ModuleAccessService,
    private treeviewModel: TreeviewModel
  ) { }

  ngOnInit(): void {
    this.defaultModuleParam = this.domainParams.getDomainParamByParamId(261);

    this.activeModuleChangeSubscription = this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd)
      ).subscribe((e: NavigationEnd) => {
        if (e.url.includes('/main/')) {
          const modulePath = e.url.split('/')[2];
          this.activeModulePath = modulePath;

          // Update users active module into database when nav event occurs
          this.modAccessService.updateActiveModule(this.activeModulePath).subscribe();
        }
      });

    this.treeviewModel.treeviewInitialized
      .pipe(first(init => init))
      .subscribe(() => {
        this.treeviewReady = true;
      });

    this.appState.selectedNodeChange
      .pipe(first())
      .subscribe(() => {
        let defaultModule: Functionality;
        const navigationExtras: NavigationExtras = {
          state: {
            nodeType: this.appState.selectedNodeType,
            nodeId: this.appState.selectedNodeId,
            node: this.appState.selectedNode
          }
        };
        if (this.appState.selectedModule || this.defaultModuleParam) {
          // If module passed on route event, browser was refreshed, navigate to it
          if (this.appState.selectedModule) {
            defaultModule = this.modules.find(module => module.route === this.appState.selectedModule);
          } else if (this.defaultModuleParam) {
            defaultModule = this.modules.find(module => module.route === this.defaultModuleParam.value);
          }
        }
        if (defaultModule) {
          this.router.navigateByUrl('/main/' + defaultModule.route, navigationExtras);
          this.activeModulePath = defaultModule.route;
        } else if (this.modules.some(m => m.route === 'main-menu')) {
          this.router.navigateByUrl('/main/main-menu');
          this.activeModulePath = 'main-menu';
        } else {
          this.router.navigateByUrl('/main/' + this.modules[0].route, navigationExtras);
          this.activeModulePath = this.modules[0].route;
        }
      });

    this.matIconRegistry.addSvgIcon(
      'trailer',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/trailer.svg')
    );
  }

  navigateTo(page: Functionality): void {
    this.activeModulePath = page.route;
    const navigationExtras: NavigationExtras = {
      state: {
        nodeType: this.appState.selectedNodeType,
        nodeId: this.appState.selectedNodeId,
        node: this.appState.selectedNode
      }
    };
    this.router.navigateByUrl('/main/' + page.route, navigationExtras);
  }

  ngOnDestroy(): void {
    this.activeModuleChangeSubscription.unsubscribe();
  }

}
