import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule, enableProdMode, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeFi from '@angular/common/locales/fi';
import localeFiExtra from '@angular/common/locales/extra/fi';

registerLocaleData(localeFi, localeFiExtra);

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

/* External libraries */
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';

/* Feature Modules */
import { BaseComponentModule } from './components/base/base-component.module';
import { AppMqttModule } from './modules/app-mqtt-module';
import { DatePickerModule } from './modules/date-picker-module';
import { CustomDateTimeModule } from './modules/custom-date-time-adapter-module';

/*Components*/
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog-component';

import { AppConfigService } from '@fliq/service-library';
import { AddTagDialogComponent } from './components/add-tag-dialog/add-tag-dialog-component';
import { AddProjectDialogComponent } from './components/add-project-dialog/add-project-dialog-component';
import { LanguagePipe } from './pipes/language-pipe';
import { PdfService } from './components/job-table/pdf-service';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { HttpCustomInterceptor } from './interceptor/http-custom-interceptor';

enableProdMode();

export function initConfig(config: AppConfigService): any {
  return () => config.load();
}

export function baseUrl(config: AppConfigService): string {
  return config.getConfig('base_url');
}

export function baseUrlGo(config: AppConfigService): string {
  return config.getConfig('base_url_go');
}

export function useGo(config: AppConfigService): boolean {
  return JSON.parse(config.getConfig('use_go'));
}

export function msalClient(config: AppConfigService): string {
  return config.getConfig('msal_client');
}

export function msalRedirectUri(config: AppConfigService): string {
  return config.getConfig('msal_redirect_uri');
}

export function msalAuthority(config: AppConfigService): string {
  return config.getConfig('msal_authority');
}

export function useMsal(config: AppConfigService): boolean {
  return JSON.parse(config.getConfig('use_msal'));
}

export function useTelemetry(config: AppConfigService): boolean {
  return JSON.parse(config.getConfig('use_telemetry'));
}

export function telemetryReplayRate(config: AppConfigService): number {
  return JSON.parse(config.getConfig('telemetry_replay_rate'));
}

export function telemetryPrivacyLevel(config: AppConfigService): string {
  return config.getConfig('telemetry_privacy_level');
}

@NgModule({
  declarations: [
    AppComponent,
    ConfirmDialogComponent,
    AddTagDialogComponent,
    AddProjectDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    BaseComponentModule,
    AppMqttModule,
    DatePickerModule,
    CustomDateTimeModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSnackBarModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DragToSelectModule.forRoot({
      selectedClass: 'drag-and-select-item',
      shortcuts: {
        disableSelection: 'alt+meta,d'
      }
    })
  ],
  bootstrap: [AppComponent],
  providers: [
    AppConfigService,
    SplashScreen,
    StatusBar,
    ErrorHandler,
    { provide: APP_INITIALIZER, useFactory: initConfig, deps: [AppConfigService], multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: 'BASE_URL', useFactory: baseUrl, deps: [AppConfigService], multi: false
    },
    {
      provide: 'BASE_URL_GO', useFactory: baseUrlGo, deps: [AppConfigService], multi: false
    },
    {
      provide: 'USE_GO', useFactory: useGo, deps: [AppConfigService], multi: false
    },
    {
      provide: 'MSAL_CLIENT', useFactory: msalClient, deps: [AppConfigService], multi: false
    },
    {
      provide: 'MSAL_REDIRECT_URI', useFactory: msalRedirectUri, deps: [AppConfigService], multi: false
    },
    {
      provide: 'MSAL_AUTHORITY', useFactory: msalAuthority, deps: [AppConfigService], multi: false
    },
    {
      provide: 'USE_MSAL', useFactory: useMsal, deps: [AppConfigService], multi: false
    },
    {
      provide: 'USE_TELEMETRY', useFactory: useTelemetry, deps: [AppConfigService], multi: false
    },
    {
      provide: 'TELEMETRY_REPLAY_RATE', useFactory: telemetryReplayRate, deps: [AppConfigService], multi: false
    },
    {
      provide: 'TELEMETRY_PRIVACY_LEVEL', useFactory: telemetryPrivacyLevel, deps: [AppConfigService], multi: false
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true, hasBackdrop: true } },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
    LanguagePipe,
    PdfService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCustomInterceptor,
      multi: true
    }
  ]
})
export class AppModule {
}
