import { NgModule } from '@angular/core';
import { DateFormatPipe } from '../pipes/date-pipe';
import { LanguagePipe } from '../pipes/language-pipe';

@NgModule({
    imports: [],
    declarations: [LanguagePipe, DateFormatPipe],
    exports: [LanguagePipe, DateFormatPipe]
})
export class PipeModule { }
