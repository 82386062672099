import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';

import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { TableColumnOptions } from '@fliq/datamodel-library';
import { DropDownService, FilterService } from '@fliq/service-library';

import { LanguagePipe } from 'src/app/pipes/language-pipe';

export interface TableFilters {
    groupOp: string;
    rules: {
        field: string,
        op: string,
        data: string,
        text?: string,
        conditional?: boolean,
        condGroupOp?: string,
        condFieldName?: string,
        condOp?: string,
        condData?: any
    }[];
}

@Component({
    selector: 'table-filter',
    templateUrl: './table-filter-component.html',
    styleUrls: ['./table-filter-component.scss']
})
export class TableFilterComponent implements OnInit, OnDestroy {

    @Input() filterableCols: TableColumnOptions[] = [];
    @Output() filtersChanged = new EventEmitter<any>();
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    filterForm: FormGroup;

    filterChangeSubscription: Subscription;
    filterTerms: Subject<any> = new Subject<any>();
    filters: TableFilters = {
        groupOp: 'AND',
        rules: []
    };

    filterList: { colName: string, colDef: string, op: string, value: string, text?: string }[] = [];
    textInput: any = {};
    selections: any = {};

    operators: any[] = [];
    filteredOperators = [];
    filteredColumns = [];
    subscriptions: Subscription[] = [];
    loading = true;
    colDdData = {};
    colDdFilteredData = {};

    constructor(
        private fb: FormBuilder,
        private ddService: DropDownService,
        private filterService: FilterService,
        private langPipe: LanguagePipe
    ) { }

    ngOnInit(): void {
        this.initForm();
        this.getOperators();
        this.filterChangeSubscription = this.filterTerms.pipe(
            debounceTime(1000),
            distinctUntilChanged()
        ).subscribe(
            () => {
                this.filters.rules = [];
                // go through filter rows and add them to the rule list
                const filters = this.getFilterFormArray();
                filters.controls.forEach(rec => {
                    const fieldName = rec.get('field').value;
                    const operator = rec.get('operator').value;
                    const value = rec.get('value').value;
                    const valueType = rec.get('valueType').value;
                    if (operator && ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value))) {
                        this.filters.rules.push(this.formatFilterField(fieldName, operator, value, valueType));
                    }
                });
                this.setFilterList(this.filters.rules);     // set data for the chips
                this.filtersChanged.next(this.filters);
            }
        );
    }

    updateFilter(groupIndex: number, data?: any): void {
        const groups = this.getFilterFormArray();
        // emit event only when filter row has all necessary data
        const isFilterValid = groups.controls[groupIndex].valid;
        if (isFilterValid) {
            this.filterTerms.next(data);
        }
    }

    setFilterList(rules: { field: string, op: string, data: string, text?: string, type?: string }[]): void {
        this.filterList = rules.map(rule => {
            const col = this.filterableCols.find(fc => fc.def === rule.field);
            const colName = col.langTag ? this.langPipe.transform(col.langTag) : col.name;

            const operator = this.operators.find(rec => rec.strparam === rule.op);
            const opName = operator ? operator.name : '';
            return { colName, colDef: col.def, op: opName, value: rule.data, text: rule.text, type: rule.type };
        });
    }

    getFilters(): TableFilters {
        const filter: TableFilters = {
            groupOp: 'AND',
            rules: []
        };
        filter.groupOp = this.filters.groupOp;
        filter.rules = this.filters.rules.map(rule => {
            return { field: rule.field, op: rule.op, data: rule.data, text: rule.text };
        });
        return filter;
    }

    getFilterFormArray(): FormArray {
        return this.filterForm.get('filterFields') as FormArray;
    }

    getGroupFieldName(index: number): string {
        const formArray = this.getFilterFormArray();
        return formArray.controls[index].get('field').value;
    }

    setFilterRules(rules: any[]): void {
        this.filters.rules = rules;
    }

    resetFilters(): void {
        this.setFilterRules([]);
        this.filters.groupOp = 'AND';
        this.filtersChanged.next(this.filters);
        const formArray = this.getFilterFormArray();
        formArray.controls.forEach(field => field.get('value').reset());
        // To make search work with the same value after filter reset is done
        this.filterTerms.next();
    }

    removeFilterListValue(filter: { colName: string, colDef: string, op: string, value: string, type?: string }): void {
        const groups = this.getFilterFormArray();
        const operator = this.operators.find(rec => rec.name === filter.op);
        const opName = operator?.strparam ?? (filter.type === 'multiSelect' ? 'in' : 'cn');
        let filterVal: any = filter.value.replace(/'/g, '');
        if (filter.type.includes('date')) {
            filterVal = new Date(filterVal);
        } else if (filter.type === 'multiSelect') {
            if (filterVal) {
                filterVal = filterVal.split(',');
            } else {
                filterVal = [];
            }
        }

        const index = groups.controls.findIndex(rec =>
            rec.get('field').value === filter.colDef && rec.get('operator').value === opName &&
            (rec.get('value').value === filterVal ||
                (filter.type.includes('date') && new Date(rec.get('value').value).toDateString() === filterVal.toDateString()) ||
                (filter.type === 'multiSelect' && filterVal.every((val, idx) => val === rec.get('value').value[idx])))
        );
        if (index > -1) {
            groups.controls[index].get('value').reset();
        }
        this.filterTerms.next(filter);
    }

    getOperators(): void {
        this.ddService.getDropDownList('operator').subscribe(data => {
            this.operators = data;
            if (this.operators) {
                this.filterableCols.forEach((_, index) => {
                    this.filteredOperators.push(new ReplaySubject<any[]>(1));
                    this.filteredOperators[index].next(this.operators.slice());
                });
            } else {
                this.filterableCols.forEach((_, index) => {
                    this.filteredOperators.push(new ReplaySubject<any[]>(1));
                    this.filteredOperators[index].next([]);
                });
            }
        }).add(() => this.loading = false);
    }

    initForm(): void {
        this.filterForm = this.fb.group({
            filterFields: this.fb.array([])
        });
        // get data for field dropdown
        this.filterableCols.forEach((col, index) => {
            this.filteredColumns.push(new ReplaySubject<any[]>(1));
            this.filteredColumns[index].next(this.filterableCols);
            if (col.filter.data) {
                this.colDdData[col.def] = col.filter.data;
            }
            if (col.filter.filteredData) {
                this.colDdFilteredData[col.def] = new ReplaySubject<any[]>(1);
                this.colDdFilteredData[col.def].next(col.filter.filteredData);
            } else if (col.filter.dataSub) {
                this.subscriptions.push(col.filter.dataSub.subscribe(data => {
                    this.colDdData[col.def] = data;
                    this.colDdFilteredData[col.def] = new ReplaySubject<any[]>(1);
                    this.colDdFilteredData[col.def].next(data);

                    const groups = this.getFilterFormArray();
                    const group = groups.controls.filter(rec => rec.get('field').value === col.def);
                    group.forEach(rec => this.setDataForFilterDd(rec as FormGroup, col.def));
                }));
            }
            const filter = { field: col.def, type: col.filter.type, op: col.filter.defaultOp };
            this.addFilter(filter);
        });
    }

    addFilter(data?: any): void {
        // checking if data is available and then only access the property inside the data,
        // otherwise it gives error when adding new search field
        const op = data?.op ? data.op : data?.type === 'multiSelect' ? 'in' : 'cn';
        const group = this.fb.group({
            field: [data?.field ?? '', Validators.required],
            fieldSearch: '',
            operator: [op, Validators.required],
            operatorSearch: '',
            value: data?.value ?? '',
            valueType: data?.type ?? 'input'
        });
        const groups = this.getFilterFormArray();
        groups.push(group);

        const groupIndex = groups.length - 1;
        this.filteredColumns.push(new ReplaySubject<any[]>(1));
        this.filteredColumns[groupIndex].next(this.filterableCols);
        this.filteredOperators.push(new ReplaySubject<any[]>(1));
        this.filteredOperators[groupIndex].next(this.operators);

        if (data?.field) {
            this.setDataForFilterDd(group, data.field);
        }

        this.subscriptions.push(group.controls.fieldSearch.valueChanges.subscribe((value) => {
            this.filteredColumns[groupIndex].next(this.filterService.filterArray(this.filterableCols, value, 'name'));
        }));
        this.subscriptions.push(group.controls.field.valueChanges.subscribe((value) => {
            this.setDataForFilterDd(group, value);
        }));
        this.subscriptions.push(group.controls.operatorSearch.valueChanges.subscribe((value) => {
            this.filteredOperators[groupIndex].next(this.filterService.filterArray(this.operators, value, 'name'));
        }));
    }

    setDataForFilterDd(group: FormGroup, colDef: string): void {
        const col = this.filterableCols.find(rec => rec.def === colDef);
        if (col) {
            const filterType = col.filter.type;
            group.controls.valueType.setValue(filterType);
            if (filterType.toLowerCase().includes('select')) {
                group.addControl(col.def + 'Search', this.fb.control(''));
                if (this.colDdData[colDef]) {
                    this.subscriptions.push(group.controls[colDef + 'Search'].valueChanges.subscribe((searchValue) => {
                        this.colDdFilteredData[colDef].next(this.filterService.filterArray(this.colDdData[colDef], searchValue, 'name'));
                    }));
                }
            }
        }
    }

    setFilterValue(field: string, op: string, value: any, type: string, update = true): void {
        let filters = this.getFilterFormArray();
        if (filters.controls.length === 0) {
            this.initForm();
            filters = this.getFilterFormArray();
        }
        const filter = filters.controls.find(rec => rec.get('field').value === field);
        if (filter) {
            filter.get('operator').setValue(op);
            filter.get('value').setValue(value);
            filter.get('valueType').setValue(type);

            if (update) {
                this.filterTerms.next(filter);
            }
        }
    }

    removeFilter(index: number): void {
        const filters = this.getFilterFormArray();
        const filterGroup = filters.controls[index];
        const filterText = filterGroup.get('value').value;
        if (index > (this.filterableCols.length - 1) && !filterText) {
            filters.removeAt(index);
        }
        filterGroup.get('value').reset();
        this.filterTerms.next(filters);
    }

    formatFilterField(fieldName: string, op: string, value: any, valueType: string): { field: string, op: string, data: string, text?: string, type?: string } {
        let chipText: string;
        if (valueType.includes('date')) {
            const date = new Date(value);
            value = date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-' +
                (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
        } else if (valueType.toLowerCase().includes('select')) {
            let valAsSqlInString: string;

            if (Array.isArray(value)) {
                chipText = value.join(', ');
                valAsSqlInString = '\'' + value.join('\',\'') + '\'';
            } else {
                valAsSqlInString = '\'' + value + '\'';
                const option = this.colDdData[fieldName].find(row => row.id === value);
                chipText = option.name;
            }

            if (valAsSqlInString === '\'\'') {
                valAsSqlInString = '';
            }
            value = valAsSqlInString;
        }
        return { field: fieldName, op, data: value, text: chipText ?? value, type: valueType };
    }

    updateFilterValues(newFilters: { field: string, op: string, value: any }[]): void {
        let filters = this.getFilterFormArray();
        if (filters.controls.length === 0) {
            this.initForm();
            filters = this.getFilterFormArray();
        }
        const newValues = [];
        newFilters.forEach(newFilter => {
            const filter = filters.controls.find(rec => rec.get('field').value === newFilter.field && rec.get('operator').value === newFilter.op);
            if (filter) {
                filter.get('value').setValue(newFilter.value);
            } else {
                this.addFilter({ field: newFilter.field, op: newFilter.op, value: newFilter.value });
            }
            newValues.push(newFilter.value);
        });
        this.filterTerms.next(newValues);
    }

    closeFilterPanel(): void {
        this.trigger.closeMenu();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.filterChangeSubscription.unsubscribe();
    }
}
