<div fxLayout="column" fxFill>
    <mat-toolbar class="main-toolbar">
        <div class="logo-container">
            <img class="logo-img" [src]="logoUrl" (error)="onLogoError()">
        </div>
        <!-- Breadcrumb -->
        <treenode-menu 
            *ngIf="showBreadcrumbNav"
            [nodes]="nodeButtons"
            (nodeSelected)="changeNode($event)"
        >
        </treenode-menu>
        <span class="spacer"></span>
        <messages-panel></messages-panel>
        <span #tooltip="matTooltip" matTooltip="{{session.user.firstName}} {{session.user.lastName}}"></span>
        <button mat-icon-button (mouseenter)="tooltip.show()" (mouseleave)="tooltip.hide()" (click)="goToUserSetting()"
            e2e-id="settings_button">
            <mat-icon>person</mat-icon>
        </button>
        <button mat-icon-button (click)="goToHelp()" e2e-id="help_button">
            <mat-icon>help_outline</mat-icon>
        </button>
        <button mat-icon-button (click)="logout()" e2e-id="logout_button">
            <mat-icon>power_settings_new</mat-icon>
        </button>
    </mat-toolbar>
    <mat-progress-bar *ngIf="loadingTreeview" mode="indeterminate"></mat-progress-bar>
    <mat-sidenav-container fxFlex>
        <mat-sidenav mode="side" [opened]="true" [disableClose]="true" class="main-sidenav">
            <div fxLayout="column" fxFill>
                <div fxFlex class="container">
                    <app-nav></app-nav>
                </div>
                <button mat-button class="org-tree-toggle-button" (click)="toggleTreeview()">
                    <mat-icon [color]="orgTreeIconColor" fontSet="material-icons-outlined">lan</mat-icon>
                    <p>{{165 | langTag}}</p>
                </button>
            </div>
        </mat-sidenav>
        <mat-sidenav-content>
            <app-tree [hidden]="!showOrgTree"></app-tree>
            <div fxFlex style="position: relative;">
                <ion-router-outlet></ion-router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>