import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';

export const MY_NATIVE_FORMATS: any = {
    fullPickerInput: { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' },
    datePickerInput: { year: 'numeric', month: '2-digit', day: '2-digit' },
    timePickerInput: { hour: '2-digit', minute: '2-digit' },
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
};

@NgModule({
    imports: [OwlDateTimeModule, OwlNativeDateTimeModule],
    providers: [
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS },
        { provide: OWL_DATE_TIME_LOCALE, useValue: 'fi' }
    ]
})
export class CustomDateTimeModule {
}
