import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'access-request-dialog',
    templateUrl: './access-request-dialog-component.html',
    styleUrls: ['./access-request-dialog-component.scss']
})
export class AccessRequestDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
}
