<mat-spinner *ngIf="loading"></mat-spinner>
<form *ngIf="qualificationForm" [formGroup]="qualificationForm" (ngSubmit)="submitForm(qualificationForm.value)">
    <h2 mat-dialog-title>{{type | titlecase}}</h2>
    <mat-dialog-content>
        <div fxLayout="column">
            <mat-checkbox formControlName="select" (change)="changeForm($event)">{{name}}</mat-checkbox>
            <mat-form-field [owlDateTimeTrigger]="attainDatepicker">
                <input matInput placeholder="{{857 | langTag}}" [owlDateTime]="attainDatepicker"
                    formControlName="attainDate">
                <owl-date-time #attainDatepicker [pickerType]="'calendar'"></owl-date-time>
                <span matSuffix class="material-icons datepicker-suffix-icon">today</span>
            </mat-form-field>
            <mat-form-field>
                <mat-select placeholder="{{829 | langTag}}" formControlName="grade">
                    <mat-option *ngFor="let grade of grades" [value]="grade">{{grade}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field [owlDateTimeTrigger]="renewalDatepicker">
                <input matInput placeholder="{{858 | langTag}}" [owlDateTime]="renewalDatepicker"
                    formControlName="renewalDate">
                <owl-date-time #renewalDatepicker [pickerType]="'calendar'"></owl-date-time>
                <span matSuffix class="material-icons datepicker-suffix-icon">today</span>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <!-- Cancel -->
        <button mat-button type="button" mat-dialog-close>{{275 | langTag}}</button>
        <button mat-raised-button type="submit" color="accent">{{279 | langTag}}</button>
    </mat-dialog-actions>
</form>