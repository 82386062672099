import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./components/login/login-component.module').then(m => m.LoginComponentModule) },
  { path: 'auth', loadChildren: () => import('./components/ionic-login-component/ionic-login-component.module').then(m => m.IonicLoginComponentModule) },
  { path: 'main', loadChildren: () => import('./components/base/base-component.module').then(m => m.BaseComponentModule) },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
