import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable, ReplaySubject, Subscription, zip } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import {
    TimezoneService,
    ModuleParamsModel,
    SessionModel,
    FilterService,
    TypeService,
    OrganisationService,
    UserService,
    AuthService,
    EmploymentContractService
} from '@fliq/service-library';
import { LanguagePipe } from 'src/app/pipes/language-pipe';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog-component';

@Component({
    templateUrl: './update-user-dialog-component.html'
})
export class UpdateUserDialogComponent implements OnInit {

    user: any;
    userId: any;
    type = '';
    header = '';
    userForm: FormGroup;

    roles: any[] = [];
    organisations: any[] = [];
    skills: any[] = [];
    languages: any[] = [];
    timezones: any[] = [];
    supervisors: any[] = [];
    filteredOrgs: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    filteredRoles: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    filteredSkills: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    filteredLanguages: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    filteredTimezones: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    filteredSupervisors: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    subscriptions: Subscription[] = [];
    loading: boolean;
    showSkillCategory = false;
    showTimezone = false;
    adminModuleId = 21;
    qualifications: any[] = [];
    contracts: any[] = [];
    addFromUserDialog = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<UpdateUserDialogComponent>,
        private snackBar: MatSnackBar,
        private timezoneService: TimezoneService,
        private typeService: TypeService,
        private orgService: OrganisationService,
        private userService: UserService,
        private moduleParams: ModuleParamsModel,
        private session: SessionModel,
        private filterService: FilterService,
        private langPipe: LanguagePipe,
        private dialog: MatDialog,
        private authService: AuthService,
        private empContractService: EmploymentContractService
    ) {
        this.user = data.user;
        this.userId = data.user ? data.user.id : -1;
        this.type = data.type;
        this.header = data.type === 'add' ? langPipe.transform(13) : langPipe.transform(297);
        this.addFromUserDialog = this.type === 'add';
    }

    emailOrEmpty(control: AbstractControl): ValidationErrors | null {
        return control.value === '' ? null : Validators.email(control);
    }

    newQualification(data: any): void {
        this.qualifications.push(data);
    }

    newContract(data: any): void {
        this.contracts.push(data);
    }

    ngOnInit(): void {
        this.moduleParams.getModuleParams(this.adminModuleId)
            .pipe(switchMap(() => zip(
                this.getRoles(),
                this.getOrganisations(),
                this.getSkillCategories(),
                this.getLanguages(),
                this.getTimezones(),
                this.getSupervisors())))
            .subscribe(
                () => {
                    this.showSkillCategory = this.moduleParams.isActiveModuleParam(this.adminModuleId, 'skill_category');
                    this.showTimezone = this.moduleParams.isActiveModuleParam(this.adminModuleId, 'timezone');

                    this.dialogRef.updateSize('60%');
                    this.initForm();
                },
                () => {
                    this.snackBar.open(`${this.langPipe.transform(570)}`, 'Ok', { duration: 5000 });
                    this.dialogRef.close();
                }
            );
    }

    initForm(): void {
        if (this.user && this.user.email === 'null') {
            this.user.email = '';
        }
        this.userForm = this.fb.group({
            user: [this.user ? this.user.username : '', Validators.required],
            fname: [this.user ? this.user.first_name : '', Validators.required],
            lname: [this.user ? this.user.last_name : '', Validators.required],
            initials: [this.user ? this.user.initials : '', Validators.required],
            org: this.user ? parseInt(this.user.organisation_id) : this.session.user.organisationId,
            role: this.user ? this.user.role_role : 'user',
            skill: this.user ? parseInt(this.user.rateskillcategory) : '',
            language: this.user ? this.user.lang : 'fi',
            email: [this.user ? this.user.email : '', this.emailOrEmpty],
            mobile: this.user ? this.user.mobile : '',
            timezone: this.user && this.user.timezone ? parseInt(this.user.timezone) : -1,
            orgSearch: '',
            roleSearch: '',
            skillSearch: '',
            languageSearch: '',
            timezoneSearch: '',
            payroll: this.user ? this.user.payroll_number : '',
            banned: this.user && this.user.banned !== '0' ? this.user.banned : '',
            reason: this.user ? this.user.ban_reason : '',
            deleted: this.user && this.user.deleted !== '0' ? this.user.deleted : '',
            ownOrgOnly: this.user && this.user.access_own_org_only !== '0' ? this.user.access_own_org_only : '',
            supervisor: this.user ? parseInt(this.user.supervisor) : '',
            supervisorSearch: '',
            nfcId: this.user ? this.user.nfc_id : ''
        });
        this.subscriptions.push(this.userForm.controls.orgSearch.valueChanges.subscribe((value) => {
            this.filteredOrgs.next(this.filterService.filterArray(this.organisations, value, 'name'));
        }));
        this.subscriptions.push(this.userForm.controls.roleSearch.valueChanges.subscribe((value) => {
            this.filteredRoles.next(this.filterService.filterArray(this.roles, value, 'name'));
        }));
        this.subscriptions.push(this.userForm.controls.skillSearch.valueChanges.subscribe((value) => {
            this.filteredSkills.next(this.filterService.filterArray(this.skills, value, 'name'));
        }));
        this.subscriptions.push(this.userForm.controls.languageSearch.valueChanges.subscribe((value) => {
            this.filteredLanguages.next(this.filterService.filterArray(this.languages, value, 'name'));
        }));
        this.subscriptions.push(this.userForm.controls.timezoneSearch.valueChanges.subscribe((value) => {
            this.filteredTimezones.next(this.filterService.filterArray(this.timezones, value, 'zone_desc'));
        }));
        this.subscriptions.push(this.userForm.controls.supervisorSearch.valueChanges.subscribe((value) => {
            this.filteredSupervisors.next(this.filterService.filterArray(this.supervisors, value, 'name'));
        }));
        if (this.type === 'edit') { this.userForm.controls.user.disable(); }
    }

    getRoles(): Observable<any> {
        return this.typeService.getEnums('role')
            .pipe(tap(data => {
                this.roles = data;
                if (this.roles) {
                    this.filteredRoles.next(this.roles.slice());
                } else {
                    this.filteredRoles.next([]);
                }
            }));
    }

    getOrganisations(): Observable<any> {
        return this.orgService.getEnums('usr_orgtype_names')
            .pipe(tap(data => {
                this.organisations = data;
                if (this.organisations) {
                    this.filteredOrgs.next(this.organisations.slice());
                } else {
                    this.filteredOrgs.next([]);
                }
            }));
    }

    getSkillCategories(): Observable<any> {
        return this.typeService.getEnums('skillcategory')
            .pipe(tap(data => {
                this.skills = data;
                if (this.skills) {
                    this.filteredSkills.next(this.skills.slice());
                } else {
                    this.filteredSkills.next([]);
                }
            }));
    }

    getLanguages(): Observable<any> {
        return this.typeService.getEnums('langcode')
            .pipe(tap(data => {
                this.languages = data;
                if (this.languages) {
                    this.filteredLanguages.next(this.languages.slice());
                } else {
                    this.filteredLanguages.next([]);
                }
            }));
    }

    getTimezones(): Observable<any> {
        return this.timezoneService.getTimezones()
            .pipe(tap(data => {
                this.timezones = data;
                if (this.timezones) {
                    this.filteredTimezones.next(this.timezones.slice());
                } else {
                    this.filteredTimezones.next([]);
                }
            }));
    }

    getSupervisors(): Observable<any> {
        return this.userService.getEnums('supervisors')
            .pipe(tap(data => {
                this.supervisors = data;
                if (this.supervisors) {
                    this.filteredSupervisors.next(this.supervisors.slice());
                } else {
                    this.filteredSupervisors.next([]);
                }
            }));
    }

    changePasswordLinkClicked() {
        if (this.userId) {
            const dialogRef: MatDialogRef<ChangePasswordDialogComponent> = this.dialog.open(ChangePasswordDialogComponent, {
                width: '40%',
                disableClose: true
            });
            dialogRef.afterClosed().subscribe(res => {
                if (!res) { return; }
                this.changePassword(res, this.userId);
            });
        }
    }

    changePassword(data: any, userId: number): void {
        this.authService
            .changePasswordByAdmin(userId, data.password, data.confirm)
            .subscribe(
                (res) => {
                    if (!res.errors) {
                        this.snackBar.open(`${this.langPipe.transform(4160)}`, 'Ok', { duration: 5000 });
                    }
                    else {
                        this.snackBar.open(`${this.langPipe.transform(862)}`, 'Ok', { duration: 5000 });
                    }
                },
                () => {
                    this.snackBar.open(`${this.langPipe.transform(863)}`, 'Ok', { duration: 5000 });
                }
            );
    }

    submitForm(data: any): void {
        if (this.userForm.invalid) { return; }
        this.dialogRef.close({ details: data, qualifications: this.qualifications, contracts: this.contracts });
    }

    cancel(): void {
        if (this.contracts.length > 0) {
            this.contracts.forEach(contract => {
                this.empContractService.deleteEmploymentContract(-1, contract.id).subscribe();
            });
        }
    }
}
