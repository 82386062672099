<form *ngIf="dynamicForm" [formGroup]="dynamicForm" (ngSubmit)="submitForm()">
    <div fxLayout="column">
        <ng-container *ngFor="let field of fields">
            <ng-container [ngSwitch]="field.fieldType">
                <mat-form-field *ngSwitchCase="'input'">
                    <mat-label>{{field.langTagId | langTag}}</mat-label>
                    <input matInput [formControlName]="field.name">
                </mat-form-field>
                <mat-form-field *ngSwitchCase="'select'">
                    <mat-label>{{field.langTagId | langTag}}</mat-label>
                    <mat-select [formControlName]="field.name" [attr.name]="field.name">
                        <mat-option>
                            <ngx-mat-select-search formControlName="{{field.name}}Search"
                                placeholderLabel="{{152 | langTag}}" noEntriesFoundLabel="{{578 | langTag}}">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let value of filteredSelectMap.get(field.name) | async" [value]="value.id">
                            {{value.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-checkbox *ngSwitchCase="'checkbox'" [formControlName]="field.name">
                    {{field.langTagId | langTag}}
                </mat-checkbox>
            </ng-container>
        </ng-container>
    </div>
</form>