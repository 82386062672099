<mat-spinner *ngIf="loading"></mat-spinner>
<form *ngIf="userForm" [formGroup]="userForm" (ngSubmit)="submitForm(userForm.value)">
    <h2 mat-dialog-title>{{header}} {{user?.first_name}} {{user?.last_name}}</h2>
    <h3><a *ngIf="type==='edit'" [routerLink]="" (click)="changePasswordLinkClicked()">{{4004 | langTag}}</a></h3>
    <mat-dialog-content>
        <mat-tab-group style="height: 60vh">
            <mat-tab label="{{241 | langTag}}">
                <div fxLayout="column">
                    <mat-form-field>
                        <input matInput placeholder="{{844 | langTag}}" formControlName="user" e2e-id="user_name">
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="{{845 | langTag}}" formControlName="fname" e2e-id="user_fname">
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="{{846 | langTag}}" formControlName="lname" e2e-id="user_lname">
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="{{847 | langTag}}" formControlName="initials" e2e-id="user_initials">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-select placeholder="{{341 | langTag}}" formControlName="org" e2e-id="user_org">
                            <mat-option>
                                <ngx-mat-select-search formControlName="orgSearch" placeholderLabel="{{152 | langTag}}"
                                    noEntriesFoundLabel="{{578 | langTag}}" e2e-id="user_org_search">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let org of filteredOrgs | async; let i=index" [value]="org.id" [attr.e2e-id]="'user_org_'+i">{{org.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-select placeholder="{{848 | langTag}}" formControlName="role" e2e-id="user_role">
                            <mat-option>
                                <ngx-mat-select-search formControlName="roleSearch" placeholderLabel="{{152 | langTag}}"
                                    noEntriesFoundLabel="{{578 | langTag}}" e2e-id="user_role_search">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let role of filteredRoles | async; let i=index" [value]="role.type_name" [attr.e2e-id]="'user_role_'+i">{{role.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-select placeholder="{{2010 | langTag}}" formControlName="supervisor">
                            <mat-option>
                                <ngx-mat-select-search formControlName="supervisorSearch" placeholderLabel="{{152 | langTag}}"
                                    noEntriesFoundLabel="{{578 | langTag}}">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let supervisor of filteredSupervisors | async" [value]="supervisor.id">{{supervisor.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="showSkillCategory">
                        <mat-select placeholder="{{817 | langTag}}" formControlName="skill" e2e-id="user_skill">
                            <mat-option>
                                <ngx-mat-select-search formControlName="skillSearch"
                                    placeholderLabel="{{152 | langTag}}" noEntriesFoundLabel="{{578 | langTag}}" e2e-id="user_skill_search">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let skill of filteredSkills | async; let i=index" [value]="skill.id" [attr.e2e-id]="'user_skill_'+i">{{skill.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-select placeholder="{{393 | langTag}}" formControlName="language" e2e-id="user_language">
                            <mat-option>
                                <ngx-mat-select-search formControlName="languageSearch"
                                    placeholderLabel="{{152 | langTag}}" noEntriesFoundLabel="{{578 | langTag}}" e2e-id="user_language">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let lang of filteredLanguages | async; let i=index" [value]="lang.strparam" [attr.e2e-id]="'user_language_'+i">{{lang.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="{{849 | langTag}}" formControlName="email" e2e-id="user_email">
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="{{208 | langTag}}" formControlName="mobile" e2e-id="user_mobile">
                    </mat-form-field>
                    <mat-form-field *ngIf="showTimezone">
                        <mat-select placeholder="{{775 | langTag}}" formControlName="timezone" e2e-id="user_tz">
                            <mat-option>
                                <ngx-mat-select-search formControlName="timezoneSearch"
                                    placeholderLabel="{{152 | langTag}}" noEntriesFoundLabel="{{578 | langTag}}" e2e-id="user_tz_search">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let tz of filteredTimezones | async; let i=index" [value]="tz.id" [attr.e2e-id]="'user_tz_'+i">{{tz.zone_desc}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="{{850 | langTag}}" formControlName="payroll" e2e-id="user_payroll">
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="NFC" formControlName="nfcId" e2e-id="user_nfc_id">
                    </mat-form-field>

                    <mat-checkbox formControlName="banned" e2e-id="user_banned">{{851 | langTag}}</mat-checkbox>
                    <mat-form-field>
                        <input matInput placeholder="{{609 | langTag}}" formControlName="reason" e2e-id="user_banned_reason">
                    </mat-form-field>

                    <mat-checkbox formControlName="deleted" e2e-id="user_deleted">{{789 | langTag}}</mat-checkbox>
                    <mat-checkbox formControlName="ownOrgOnly" e2e-id="user_ownOrg">{{852 | langTag}}</mat-checkbox>
                </div>
            </mat-tab>
            <mat-tab label="{{828 | langTag}}">
                <qualification-list [userId]="userId" (newQualification)="newQualification($event)"></qualification-list>
            </mat-tab>
            <mat-tab label="{{853 | langTag}}">
                <emp-contract-list [userId]="userId" [addFromUserDialog]="addFromUserDialog"
                    (newContract)="newContract($event)"></emp-contract-list>
            </mat-tab>
            <mat-tab label="{{631 | langTag}}" *ngIf="data.type==='edit'">
                <update-user-team [userId]="userId" [user]="user"></update-user-team>
            </mat-tab>
        </mat-tab-group>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button type="button" mat-dialog-close e2e-id="user_cancel" (click)="cancel()">{{275 | langTag}}</button>
        <button mat-raised-button type="submit" color="accent" e2e-id="user_save">{{279 | langTag}}</button>
    </mat-dialog-actions>
</form>