import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './change-password-dialog-component.html'
})
export class ChangePasswordDialogComponent implements OnInit {

    passwordForm: FormGroup;
    loading: boolean;

    constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ChangePasswordDialogComponent>) {
    }

    ngOnInit(): void {
        this.initForm();
        this.loading = false;
    }

    initForm(): void {
        this.passwordForm = this.fb.group({
            password: '',
            confirm: ''
        });
    }

    submitForm(data: any): void {
        if (this.passwordForm.invalid) { return; }
        this.dialogRef.close(data);
    }

}
