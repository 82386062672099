<mat-spinner *ngIf="loading"></mat-spinner>
<form *ngIf="empForm" [formGroup]="empForm" (ngSubmit)="submitForm(empForm.value)">
    <h2 mat-dialog-title>{{type | titlecase}}</h2>
    <mat-dialog-content>
        <div fxLayout="column">
            <mat-form-field [owlDateTimeTrigger]="empStartDatepicker">
                <mat-label>{{334 | langTag}}</mat-label>
                <input matInput [owlDateTime]="empStartDatepicker" formControlName="empStartDate">
                <owl-date-time #empStartDatepicker [pickerType]="'calendar'"></owl-date-time>
                <mat-icon matSuffix class="datepicker-suffix-icon">today</mat-icon>
                <mat-error *ngIf="empForm.get('empStartDate').hasError('required')">{{2048 | langTag}}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{861 | langTag}}</mat-label>
                <mat-select formControlName="empType">
                    <mat-option>
                        <ngx-mat-select-search formControlName="empTypeSearch" placeholderLabel="{{152 | langTag}}"
                            noEntriesFoundLabel="{{578 | langTag}}">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let type of filteredTypes | async" [value]="type.id">{{type.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="empForm.get('empType').hasError('required')">{{2048 | langTag}}</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="showSalaryModelField">
                <mat-label>{{2202 | langTag}}</mat-label>
                <mat-select formControlName="salaryModel">
                    <mat-option>
                        <ngx-mat-select-search formControlName="salaryModelSearch" placeholderLabel="{{152 | langTag}}"
                            noEntriesFoundLabel="{{578 | langTag}}">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let type of filteredSalaryModels | async" [value]="type.id">{{type.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field [owlDateTimeTrigger]="currentStartDatepicker">
                <mat-label>{{854 | langTag}}</mat-label>
                <input matInput [owlDateTime]="currentStartDatepicker" formControlName="currentStart">
                <owl-date-time #currentStartDatepicker [pickerType]="'calendar'"></owl-date-time>
                <mat-icon matSuffix class="datepicker-suffix-icon">today</mat-icon>
                <mat-error *ngIf="empForm.get('currentStart').hasError('required')">{{2048 | langTag}}</mat-error>
            </mat-form-field>
            <mat-form-field [owlDateTimeTrigger]="currentEndDatepicker">
                <mat-label>{{855 | langTag}}</mat-label>
                <input matInput [owlDateTime]="currentEndDatepicker" formControlName="currentEnd">
                <owl-date-time #currentEndDatepicker [pickerType]="'calendar'"></owl-date-time>
                <mat-icon matSuffix class="datepicker-suffix-icon">today</mat-icon>
                <mat-error *ngIf="empForm.get('currentEnd').hasError('required')">{{2048 | langTag}}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{856 | langTag}}</mat-label>
                <input matInput formControlName="dailyWork">
                <mat-error *ngIf="empForm.get('dailyWork').hasError('required')">{{2048 | langTag}}</mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <!-- Cancel -->
        <button mat-button type="button" mat-dialog-close>{{275 | langTag}}</button>
        <button mat-raised-button type="submit" color="accent">{{279 | langTag}}</button>
    </mat-dialog-actions>
</form>