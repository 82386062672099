import { NgModule, Injectable } from '@angular/core';
import { MatNativeDateModule, NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: any): string {
        if (displayFormat === 'input') {
            let day: number|string = date.getDate();
            let month: number|string = date.getMonth() + 1;
            const year: number = date.getFullYear();
            let hours: number|string = date.getHours();
            let minutes: number|string = date.getMinutes();
            let seconds: number|string = date.getSeconds();

            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;
            hours = hours < 10 ? '0' + hours : hours;
            minutes = minutes < 10 ? '0' + minutes : minutes;
            seconds = seconds < 10 ? '0' + seconds : seconds;

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        } else {
            return date.toDateString();
        }
    }

    getFirstDayOfWeek(): number {
        return 1;
    }
}

const APP_DATE_FORMATS: any = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' }
    }
};

@NgModule({
    declarations: [],
    imports: [],
    exports: [MatDatepickerModule, MatNativeDateModule ],
    providers: [
        {
            provide: DateAdapter, useClass: AppDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
        }
    ]
})

export class DatePickerModule {

}
