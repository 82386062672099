import { NgModule } from '@angular/core';

import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { UpdateUserDialogComponent } from './update-user-dialog-component';
import { UserEmploymentContractModule } from '../user-employment-contract/user-employment-contract.module';
import { UserQualificationsModule } from '../user-qualifications/user-qualifications.module';
import { SharedModule } from 'src/app/modules/shared-module';
import { UpdateUserTeamComponent } from '../update-user-team/update-user-team-component';

@NgModule({
    declarations: [
        UpdateUserDialogComponent,
        UpdateUserTeamComponent

    ],
    imports: [
        MatDialogModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        MatCheckboxModule,
        MatListModule,
        NgxMatSelectSearchModule,
        SharedModule,
        UserEmploymentContractModule,
        UserQualificationsModule
    ],
    exports: [
        UpdateUserDialogComponent
    ]
})
export class UpdateUserDialogComponentModule { }
