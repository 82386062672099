<mat-nav-list class="main-nav-menu" *ngIf="treeviewReady">
    <mat-list-item 
        *ngFor="let module of modules"
        (click)="navigateTo(module)"
        [attr.name]="module.route"
        [ngClass]="{ 'selected': activeModulePath === module.route }"
        [attr.e2e-id]="'menu_'+module.route"
    >
        <mat-icon *ngIf="module.icon === 'trailer'; else normalIcon" matLine fontSet="material-icons-outlined" [svgIcon]="module.icon"></mat-icon>
        <ng-template #normalIcon>
            <mat-icon matLine fontSet="material-icons-outlined">{{module.icon}}</mat-icon>
        </ng-template>
        <span matLine>{{module.lang_tag_id | langTag}}</span>
    </mat-list-item>
</mat-nav-list>
