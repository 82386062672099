import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';

import { AppStateModel } from '@fliq/service-library';
import { TelemetryService } from './services/telemetry.service';

@Component({
  templateUrl: 'app.component.html',
  selector: 'app-root'
})
export class AppComponent {
  rootPage: any;

  constructor(
    platform: Platform,
    appState: AppStateModel,
    private router: Router,
    private telemetryService: TelemetryService,
    @Inject('USE_TELEMETRY') private USE_TELEMETRY: boolean
  ) {
    platform.ready().then(() => {
      // The platform is ready and plugins are available.
      // Here you can do any higher level native things you might need.
      // Get router URL and pass it forward so we know which route to navigate when browser refreshed
      if (this.USE_TELEMETRY) {
        this.telemetryService.initDatadog();
      }
      this.rootPage = 'login';
      this.router.navigateByUrl('/login');
      appState.setLoginPage(this.rootPage);
    });
  }
}
