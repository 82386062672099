import { Inject, Injectable } from '@angular/core';
import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum';

@Injectable({ providedIn: 'root' })
export class TelemetryService {
    constructor(
        @Inject('BASE_URL') private BASE_URL: string,
        @Inject('TELEMETRY_REPLAY_RATE') private TELEMETRY_REPLAY_RATE: number,
        @Inject('TELEMETRY_PRIVACY_LEVEL') private TELEMETRY_PRIVACY_LEVEL: DefaultPrivacyLevel
    ) { }

    // Init Datadog telemetry
    initDatadog(): void {
        const regEx = new RegExp('^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)', 'g');
        const env = regEx.exec(this.BASE_URL)?.[1];
        if (!env) { return; }

        datadogRum.init({
            applicationId: '44adcec8-078a-488f-bc16-b72bc7b0af02',
            clientToken: 'pub1dbe9da94146c783cb36c75a19121d09',
            site: 'datadoghq.eu',
            service: 'fliq',
            env,
            sampleRate: 100,
            sessionReplaySampleRate: this.TELEMETRY_REPLAY_RATE,
            trackInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: this.TELEMETRY_PRIVACY_LEVEL
        });

        datadogRum.startSessionReplayRecording();
    }
}
