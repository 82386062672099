import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable } from 'rxjs';

import { AppStateModel, AssetService, DateService, EmploymentContractService, OrganisationService, QualificationService, UserService } from '@fliq/service-library';
import { UpdateAssetDialogComponent } from '../../admin/admin-assets/update-asset-dialog/update-asset-dialog-component';
import { UpdateOrganisationDialogComponent } from '../../admin/admin-organisation/update-organisation-dialog/update-organisation-dialog-component';
import { UpdateUserDialogComponent } from '../../admin/admin-users/update-user-dialog/update-user-dialog-component';
import { LanguagePipe } from 'src/app/pipes/language-pipe';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog-component';
import { TreeviewModel } from '../treeview-model';

@Component({
    selector: 'app-treeview-actions',
    templateUrl: './treeview-actions.component.html',
    styleUrls: ['./treeview-actions.component.scss']
})
export class TreeviewActionsComponent {

    constructor(
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private appState: AppStateModel,
        private langPipe: LanguagePipe,
        private assetService: AssetService,
        private organisationService: OrganisationService,
        private userService: UserService,
        private dateService: DateService,
        private qualificationService: QualificationService,
        private empContractService: EmploymentContractService,
        private treeviewModel: TreeviewModel
    ) { }

    onAddClick(type: 'asset' | 'organisation' | 'user'): void {
        let dialogToOpen: any;

        switch (type) {
            case 'asset':
                dialogToOpen = UpdateAssetDialogComponent;
                break;
            case 'organisation':
                dialogToOpen = UpdateOrganisationDialogComponent;
                break;
            case 'user':
                dialogToOpen = UpdateUserDialogComponent;
                break;
        }

        const dialog = this.dialog.open(dialogToOpen, {
            data: {
                type: 'add'
            }
        });

        dialog.afterClosed().subscribe(data => {
            if (data) {
                switch (type) {
                    case 'asset':
                        this.updateAsset(data, 'add');
                        break;
                    case 'organisation':
                        this.updateOrganisation(data, 'add');
                        break;
                    case 'user':
                        this.updateUser(data, 'add');
                        break;
                }
            }
        });
    }

    onEditClick(): void {
        const nodeId = this.appState.selectedNode.id;
        const nodeType = this.appState.selectedNode.type;
        let dialogToOpen: any;
        let getObs: Observable<any>;

        switch (nodeType) {
            case 'eq':
                dialogToOpen = UpdateAssetDialogComponent;
                getObs = this.assetService.getAsset(nodeId);
                break;
            case 'org':
                dialogToOpen = UpdateOrganisationDialogComponent;
                getObs = this.organisationService.getOrganisation(nodeId);
                break;
            case 'user':
            case 'user_supervisor':
            case 'user_admin':
            case 'exsupplier':
            case 'excustomer':
                dialogToOpen = UpdateUserDialogComponent;
                getObs = this.userService.getUser(nodeId);
                break;
        }

        if (!getObs) {
            return;
        }

        getObs.subscribe(editData => {
            let dialogParams: any = { type: 'edit' };

            switch (nodeType) {
                case 'eq':
                    dialogParams = { ...dialogParams, asset: editData[0] };
                    break;
                case 'org':
                    dialogParams = { ...dialogParams, org: editData[0] };
                    break;
                case 'user':
                case 'user_supervisor':
                case 'user_admin':
                case 'exsupplier':
                case 'excustomer':
                    dialogParams = { ...dialogParams, user: editData };
                    break;
            }
            const dialog = this.dialog.open(dialogToOpen, {
                data: {
                    ...dialogParams
                }
            });

            dialog.afterClosed().subscribe(data => {
                if (data) {
                    switch (nodeType) {
                        case 'eq':
                            this.updateAsset(data, 'edit', editData[0].id);
                            break;
                        case 'org':
                            this.updateOrganisation(data, 'edit', editData[0].id);
                            break;
                        case 'user':
                        case 'user_supervisor':
                        case 'user_admin':
                        case 'exsupplier':
                        case 'excustomer':
                            this.updateUser(data, 'edit', editData.id);
                            break;
                    }
                }
            });
        });
    }

    onRemoveClick(): void {
        const type = this.appState.selectedNode.type;

        if ((type === 'org' && this.appState.selectedNode.li_attr.level === 0) || type === 'user_org' || this.appState.selectedNode.li_attr.sub_type === 'customer_root') {
            this.snackBar.open(`${this.langPipe.transform(207)}`, 'Ok', { duration: 3000 });
            return;
        }

        let readableType = '';

        switch (type) {
            case 'eq':
                readableType = this.langPipe.transform(66);
                break;
            case 'org':
                readableType = this.langPipe.transform(165);
                break;
            case 'user':
            case 'user_supervisor':
            case 'user_admin':
            case 'exsupplier':
            case 'excustomer':
                readableType = this.langPipe.transform(399);
                break;
        }

        const dialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
                header: this.langPipe.transform(522),
                text: `${this.langPipe.transform(277)} ${readableType.toLowerCase()} ${this.appState.selectedNode.name}?`,
                cancelText: this.langPipe.transform(275),
                confirmText: this.langPipe.transform(277)
            }
        });

        dialog.afterClosed().subscribe(remove => {
            if (!remove) {
                return;
            }

            const nodeId = this.appState.selectedNode.id;
            switch (type) {
                case 'eq':
                    this.assetService.deleteAsset(nodeId).subscribe(() => this.afterDelete());
                    break;
                case 'org':
                    this.organisationService.deleteOrganisation(nodeId).subscribe(() => this.afterDelete());
                    break;
                case 'user':
                case 'user_supervisor':
                case 'user_admin':
                case 'exsupplier':
                case 'excustomer':
                    this.userService.deleteUser(nodeId).subscribe(() => this.afterDelete());
                    break;
            }

        });
    }

    private updateAsset(data: any, type: 'add' | 'edit', assetId?: number): void {
        this.assetService
            .updateAsset(
                data.name,
                data.geofence,
                data.org,
                data.invOrg,
                data.costcentre,
                data.eqtype,
                data.date !== '' ? this.dateService.dateToString(new Date(data.date)) : data.date,
                data.deleted,
                data.showOnMap,
                true,
                data.tag,
                data.shape,
                data.title,
                data.faIcon,
                type,
                assetId,
                data.brand,
                data.model,
                data.year,
                data.reg,
                data.manufacture,
                data.weight,
                data.height,
                data.width,
                data.location,
                data.radiophone,
                data.valid !== '' ? this.dateService.dateToString(new Date(data.valid)) : data.valid,
                data.team,
                data.ownerOrg,
                data.engineNum,
                data.engineType,
                data.gear,
                data.shaft,
                data.beam,
                data.warranty !== '' ? this.dateService.dateToString(new Date(data.warranty)) : data.warranty, data.price, data.tireSize, data.tireBrand,
                data.tireInstallDate !== '' ? this.dateService.dateToString(new Date(data.tireInstallDate)) : data.tireInstallDate,
                data.capacity,
                data.parentEq,
                data.order,
                data.serviceOrg,
                data.depreciationTime,
                data.seller,
                data.fuelType,
                data.insuranceType
            ).subscribe(
                () => { },
                () => {
                    this.snackBar.open(`${this.langPipe.transform(2096)}`, 'Ok', { duration: 5000 });
                }
            );
    }

    private updateOrganisation(data: any, type: 'add' | 'edit', orgId?: number): void {
        this.organisationService.updateOrganisation(
            data.name,
            data.parentOrg,
            data.active,
            data.location,
            data.showInSupplierLists,
            data.respPerson,
            type,
            orgId,
            96,
            data.daily_plan_assets,
            data.daily_plan_staff,
            data.respSalaryPerson,
            data.color,
            data.orgNr
        ).subscribe(
            () => { },
            () => {
                this.snackBar.open(`${this.langPipe.transform(2096)}`, 'Ok', { duration: 5000 });
            }
        );
    }

    private updateUser(userData: any, type: 'add' | 'edit', userId?: number): void {
        const data: any = userData.details;
        const qualifications: any[] = userData.qualifications;
        const contracts: any[] = userData.contracts;

        this.userService
            .updateUser(
                data.user,
                data.fname,
                data.lname,
                data.initials,
                data.org,
                data.role,
                data.skill,
                data.language,
                data.email,
                data.mobile,
                data.timezone,
                data.banned,
                data.reason,
                data.deleted,
                data.ownOrgOnly,
                type,
                userId,
                data.payroll,
                data.supervisor,
                data.nfcId
            ).subscribe(
                resp => {
                    if (type === 'add') {
                        // update qualifications and contracts table with new user id
                        if (qualifications.length > 0) {
                            qualifications.forEach(q => {
                                this.qualificationService.updateQualification(
                                    q.id,
                                    resp.user_id,
                                    q.qualification,
                                    q.data.grade,
                                    'edit',
                                    q.data.attainDate,
                                    q.data.renewalDate
                                ).subscribe();
                            });
                        }
                        if (contracts.length > 0) {
                            contracts.forEach(c => {
                                this.empContractService.updateEmploymentContract(
                                    c.id,
                                    resp.user_id,
                                    c.data.empType,
                                    'edit',
                                    c.data.empStartDate,
                                    c.data.currentStart,
                                    c.data.currentEnd,
                                    c.data.dailyWork
                                ).subscribe();
                            });
                        }
                    }
                },
                () => {
                    this.snackBar.open(`${this.langPipe.transform(2096)}`, 'Ok', { duration: 5000 });
                }
            );
    }

    private afterDelete(): void {
        this.treeviewModel.selectNodeByLiAttr(this.appState.selectedNode.li_attr.parent, this.appState.selectedNode.li_attr.parent_type);
    }

}
