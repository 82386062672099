import { Injectable } from '@angular/core';

import { TreeNode, TreeModel } from '@circlon/angular-tree-component';

import { BehaviorSubject, Observable } from 'rxjs';

import { ActiveNode } from '@fliq/datamodel-library';
import { AppStateModel, SessionModel, TreeviewService } from '@fliq/service-library';

export interface FliqTreeNode {
    children: FliqTreeNode[];
    li_attr: FliqTreeNodeLiAttr;
    state: { opened: boolean, selected: boolean };
    text: string;
}

export interface FliqTreeNodeLiAttr {
    class: 'eq' | 'org' | 'user' | 'user_org';
    color?: string;
    did: string;
    dkey: string;
    has_children?: boolean;
    icon?: string;
    id: number;
    level: number;
    name: string;
    node_id: string;
    node_type_id: string;
    organisation_id: string;
    parent: string;
    parent_type: 'eq' | 'org' | 'user_org';
    registration_number: string;
    status: number;
    type: string;
}

@Injectable({
    providedIn: 'root'
})
export class TreeviewModel {

    treeNodes: Array<any>; // set in treeview-service getTreeview method, which is triggered in treeview-component ngOnInit
    treeModel: TreeModel; // gets angular-tree-component TreeModel instance from treeview-component on ngOnInit
    treeviewInitialized = new BehaviorSubject<boolean>(false);
    selectedTreeviewType: any;

    constructor(
        private appState: AppStateModel,
        private treeviewService: TreeviewService,
        private session: SessionModel
    ) { }

    public getTreeNodes(treeviewType?: any): Observable<any> {
        return this.treeviewService.getTreeview(treeviewType);
    }

    public selectNode(node: TreeNode): void {
        if (!node) { return; }

        node.focus();

        const nodeData: any = node.data.li_attr;
        const orgId: number = nodeData.type.includes('org') ? nodeData.node_id : nodeData.organisation_id;
        const activeNode: ActiveNode = new ActiveNode(
            {
                id: nodeData.node_id,
                domainId: nodeData.did,
                name: nodeData.name,
                organisation_id: orgId
            },
            nodeData.type,
            nodeData);

        let nodePathName = '';

        for (let i = 0; i < node.path.length; i++) {
            const nodeId: string = node.path[i];
            const treeNode: TreeNode = this.treeModel.getNodeById(nodeId);

            nodePathName += `${treeNode.data.name}`;

            if (i + 1 < node.path.length) {
                nodePathName += ' | ';
            }
        }

        this.appState.selectedNodePathChange.next(nodePathName);
        this.appState.setActiveNode(activeNode);
        this.treeviewService.setSelectedNode(
            this.session.user.treeId, this.appState.selectedNode.id, this.appState.selectedNode.type)
            .subscribe();
    }

    public selectNodeByLiAttr(id: any, type: string): void {
        let foundNode: TreeNode;
        if (type === 'user') {
            foundNode = this.treeModel.getNodeBy((node) =>
                (node.data.li_attr.node_id === id && (node.data.li_attr.type as string).includes(type)));
        } else {
            foundNode = this.treeModel.getNodeBy((node) =>
                (node.data.li_attr.node_id === id && node.data.li_attr.type === type));
        }
        if (foundNode) {
            foundNode.ensureVisible();
            this.selectNode(foundNode);
        }
    }

    public getRootOrganisation(): TreeNode {
        return this.treeModel.getFirstRoot();
    }

    selectNodeByNodeId(id: any): any {
        const foundNode: TreeNode = this.treeModel.getNodeBy((node) => node.data.li_attr.node_id === id);
        if (foundNode) {
            foundNode.ensureVisible();
            this.selectNode(foundNode);

            return foundNode;
        } else {
            console.log('No node found');
            return null;
        }
    }

    public getNodesActiveNodeData(nodeId: number, nodeType: string): ActiveNode {
        if (!nodeId || !nodeType) { return null; }
        const foundNode: TreeNode = this.treeModel.getNodeBy(node => node.data.li_attr.node_id === nodeId && node.data.li_attr.type === nodeType);
        if (!foundNode) { return null; }
        const nodeData = foundNode.data.li_attr;
        const orgId: number = nodeData.type.includes('org') ? nodeData.node_id : nodeData.organisation_id;

        return new ActiveNode(
            {
                id: nodeData.node_id,
                domainId: nodeData.did,
                name: nodeData.name,
                organisation_id: orgId
            },
            nodeData.type,
            nodeData
        );
    }

    teardown(): void {
        this.treeModel = null;
        this.treeNodes = null;
    }

}
