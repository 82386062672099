import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';

import { NoDataFoundComponent } from './no-data-found-component';
import { PipeModule } from 'src/app/modules/pipe-module';

@NgModule({
    declarations: [
        NoDataFoundComponent
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        PipeModule,
        MatIconModule
    ],
    exports: [
        NoDataFoundComponent
    ]
})
export class NoDataFoundComponentModule { }
