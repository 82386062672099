import { Pipe, PipeTransform } from '@angular/core';

import { LanguageService } from '@fliq/service-library';

@Pipe({ name: 'langTag' })
export class LanguagePipe implements PipeTransform {

  constructor(private langService: LanguageService) { }

  transform(value: number): string {
    const langTag: any = this.langService.langTags.find((tag) => tag.langTagId == value);
    return langTag ? langTag.text : `<No translation for id ${value}>`;
  }
}
