<mat-spinner *ngIf="loading"></mat-spinner>
<form *ngIf="orgForm" [formGroup]="orgForm" (ngSubmit)="submitForm()">
    <h2 mat-dialog-title>{{type | titlecase}}</h2>
    <mat-dialog-content>
        <div fxLayout="column">
            <mat-form-field>
                <input matInput placeholder="{{166 | langTag}}" formControlName="name" e2e-id="org_name" autocomplete="off">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Organisation Nr" formControlName="orgNr" e2e-id="org_nr" autocomplete="off">
            </mat-form-field>
            <mat-form-field>
                <mat-select placeholder="{{2119 | langTag}}" formControlName="parentOrg" e2e-id="org_parent">
                    <mat-option>
                        <ngx-mat-select-search formControlName="parentOrgSearch" placeholderLabel="{{152 | langTag}}"
                            noEntriesFoundLabel="{{578 | langTag}}">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let org of filteredOrgs | async; let i=index" [value]="org.id" [attr.e2e-id]="'org_parent_'+i">{{org.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-select placeholder="{{608 | langTag}}" formControlName="location" e2e-id="org_loc">
                    <mat-option>
                        <ngx-mat-select-search formControlName="locationSearch" placeholderLabel="{{152 | langTag}}"
                            noEntriesFoundLabel="{{578 | langTag}}">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let location of filteredLocations | async" [value]="location.id">
                        {{location.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-select placeholder="{{2120 | langTag}}" formControlName="respPerson" e2e-id="org_respPerson">
                    <mat-option>
                        <ngx-mat-select-search formControlName="respPersonSearch" placeholderLabel="{{152 | langTag}}"
                            noEntriesFoundLabel="{{578 | langTag}}">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let person of filteredRespPersons | async; let i=index" [value]="person.id" [attr.e2e-id]="'org_respPerson_'+i">
                        {{person.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-select placeholder="{{2141 | langTag}}" formControlName="respSalaryPerson" e2e-id="org_respSalary">
                    <mat-option>
                        <ngx-mat-select-search formControlName="respSalaryPersonSearch"
                            placeholderLabel="{{152 | langTag}}" noEntriesFoundLabel="{{578 | langTag}}">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let person of filteredRespSalaryPersons | async; let i=index" [value]="person.id" [attr.e2e-id]="'org_respSalary_'+i">
                        {{person.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-checkbox class="checkbox-wrapper" formControlName="active" e2e-id="org_active">{{2080 | langTag}}</mat-checkbox>
            <mat-checkbox class="checkbox-wrapper" formControlName="showInSupplierLists" e2e-id="org_supplierLists">{{2121 | langTag}}</mat-checkbox>
            <div class="checkbox-wrapper">
                <app-dynamic-form [fields]="extraFields" [data]="org" (formUpdated)="onExtraFormUpdated($event)">
                </app-dynamic-form>
            </div>
            <mat-form-field>
                <input matInput placeholder="{{488 | langTag}}" type="color" formControlName="color" e2e-id="org_iconColor">
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <!-- Cancel -->
        <button mat-button type="button" mat-dialog-close e2e-id="org_cancel">{{275 | langTag}}</button>
        <button mat-raised-button type="submit" color="accent" e2e-id="org_submit">{{279 | langTag}}</button>
    </mat-dialog-actions>
</form>