<form [formGroup]="tagForm" (ngSubmit)="submitForm()">
    <h2 mat-dialog-title>{{header | titlecase}}</h2>
    <mat-dialog-content>
        <mat-form-field>
            <!-- Name -->
            <input matInput placeholder="Tag" formControlName="tagName">
        </mat-form-field>
        <mat-form-field>
            <!-- MAC address -->
            <input matInput placeholder="Mac Address" formControlName="tagMac">
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <!-- Cancel -->
        <button mat-button class="cancelbtn" (click)="cancel()" type="button">Cancel</button>
        <!-- Save -->
        <button mat-raised-button type="submit" color="accent">Submit</button>
    </mat-dialog-actions>
</form>