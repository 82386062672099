import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { SharedModule } from 'src/app/modules/shared-module';
import { DynamicFormComponentModule } from 'src/app/shared/dynamic-form/dynamic-form-component.module';
import { UpdateOrganisationDialogComponent } from './update-organisation-dialog-component';

@NgModule({
    declarations: [
        UpdateOrganisationDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatSnackBarModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        NgxMatSelectSearchModule,
        SharedModule,
        DynamicFormComponentModule
    ],
    exports: [
        UpdateOrganisationDialogComponent
    ]
})
export class UpdateOrganisationDialogComponentModule { }
